import React, { useEffect, useState } from "react";
import LeaveBalanceRegularizationHeader from "../../MenuEchoPulse/LeaveBalanceRegularizationHeader";
import Typography from "../../../components/Typography/Typography";
import Icons from "../../../components/Icons/Icon";
import SearchBarSmall from "../../../components/SearchBar/SearchBarSmall";
import ProfileImg from "../../../images/Profile.png";
import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import { getUsersAsync } from "../../../services/reducers/appReducer";
import { useAppDispatch } from "../../../hooks";
import {
  getAllLeavesForEmployeeAsync,
  getLeavesHistoryAsync,
  saveTimeOffRegulationsAsync,
} from "../../../services/reducers/leaveReducer";
import { set } from "date-fns";
import { toast } from "react-toastify";
import getInitialsInName from "../../../helper/GetNameInitials";
import EchopulseLoader from "../../loader/EchopulseLoader";
import AllSvg from "../../../components/AllSvg/AllSvg";
import Pagination from "../../admin/projects/Pagination";
import { getDayAndMonth } from "../../../utils/common";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import UserImage from "../../../components/UserImage/UserImage";

const LeaveBalanceRegularization = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchString, setSearchString] = useState("");
  const [userList, setUserList] = useState<any>(null);
  const [curUser, setCurUser] = useState<any>(null);
  const [curUserJoiningStatus, setCurUserJoiningStatus] = useState<string>("");
  const [leavesList, setLeavesList] = useState<any>(null);
  const [leavesHistory, setLeavesHistory] = useState<any>(null);
  const [addedDays, setAddedDays] = useState<{ [key: string]: number }>({});
  const [updatedValues, setUpdatedValues] = useState<
    { leaveId: string; value: number }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSecLoading, setIsSecLoading] = useState<boolean>(true);
  const [leaveHisCode, setLeaveHisCode] = useState("");
  const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(true);
  const [filterss, setFilterss] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (leaveId: string, value: string) => {
    const numericValue = parseFloat(value) || 0; // Ensure numeric input
    setAddedDays((prev) => ({ ...prev, [leaveId]: numericValue }));

    setUpdatedValues((prevValues) => {
      const leaveMap = new Map(prevValues.map((item) => [item.leaveId, item]));

      if (numericValue === 0) {
        leaveMap.delete(leaveId); // Remove if value is 0
      } else {
        leaveMap.set(leaveId, { leaveId, value: numericValue }); // Add or update
      }

      return Array.from(leaveMap.values()); // Convert map back to array
    });
  };

  const getUserList = () => {
    setIsLoading(true);
    setAddedDays({});
    setUpdatedValues([]);
    setLeavesList([]);

    const request = {
      page: currentPage,
      pageSize: pageSize,
      searchString: searchString,
      orderBy: "Name",
      isOrderByDesc: false,
      filters: {
        statusIds: [1]
      },
    };
    dispatch(getUsersAsync(request)).then((res) => {
      if (res.payload) {
        setIsLoading(false);
        setIsSecLoading(true);
        const activeUsers = res?.payload?.users?.filter(
          (user: any) => user.status === "Active"
        );
        setUserList(activeUsers);
        setTotalRecord(res?.payload?.allRecordsCount);  
        if (activeUsers?.length > 0) {
          setCurUser(activeUsers[0]);
          const joiningDate = activeUsers[0]?.joiningDate
            ? new Date(activeUsers[0].joiningDate)
            : null;
          const today = new Date();

          today.setHours(0, 0, 0, 0);

          let status;
          if (joiningDate) {
            if (joiningDate < today) {
              status = "Past";
            } else if (joiningDate > today) {
              status = "Future";
            } else {
              status = "Today";
            }
          } else {
            status = "N/A";
          }
          setCurUserJoiningStatus(status);
          dispatch(
            getAllLeavesForEmployeeAsync(activeUsers[0].identityId)
          ).then((res) => {
            setIsSecLoading(false);
            setLeavesList(res?.payload);
          });
        }
      }
    });
  };

  const debouncedSearch = useDebounce(getUserList, 1000);

  const getUserLeave = () => {
    setIsSecLoading(true);
    if (!curUser) return;
    setAddedDays({});
    setUpdatedValues([]);
    setLeavesList([]);
    dispatch(getAllLeavesForEmployeeAsync(curUser.identityId)).then((res) => {
      setIsSecLoading(false);
      setLeavesList(res?.payload);
    });
  };

  const getLeaveHistory = () => {
    setIsLoadingHistory(true);
    if (!curUser) return;
    const request = {
      Page: 1,
      PageSize: 500,
      IdentityId: curUser.identityId,
      LeaveCode: leaveHisCode
    };
    dispatch(getLeavesHistoryAsync(request)).then((res) => {
      if (res.payload) {
        setIsLoadingHistory(false);
        setLeavesHistory(res.payload.records);
      }
    });
  };

  useEffect(() => {
    debouncedSearch();
  }, [searchString, currentPage, pageSize]);

  useEffect(() => {
    getUserLeave();
  }, [curUser]);

  useEffect(() => {
    getLeaveHistory();
  }, [curUser, leaveHisCode]);

  const handleSaveBtn = () => {
    if (updatedValues.length === 0) return;
    if (curUser) {
      const requestBody = {
        identityId: curUser.identityId,
        regulations: updatedValues,
      };
      setIsSaving(true);
      dispatch(saveTimeOffRegulationsAsync(requestBody)).then((res) => {
        if (res?.payload) {
          toast.success("Leave balance updated successfully");
          getUserLeave();
          getLeaveHistory();
          setIsSaving(false);
        } else {
          
          toast.error("Failed to update leave balance");
        }
      });
    }
  };

  const handleClearBtn = () => {
    setAddedDays({});
    setUpdatedValues([]);
  };

  const getMonthName = (monthNumber: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1];
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="bg-white">
      <LeaveBalanceRegularizationHeader />

      <div className="h-[calc(100vh-5rem)] bg-white overflow-auto flex border-t border-gray-100">
        <div
          className="w-[18rem] border-r border-gray-100 flex flex-col flex-grow h-full overflow-auto pt-5"
          id="employee-area"
        >
          <div className="ps-7 pe-4 pb-5">
            <Typography
              variant="p"
              size="sm"
              className="text-gray-700 font-bold"
            >
              All Employees
            </Typography>
            <div className="w-full [&>div>input]:ps-9 [&>div>.icon-after]:top-[22px] mt-4">
              <SearchBarSmall
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder="Search by employee name"
                icon={
                  <Icons
                    name="search"
                    variant="stroke"
                    size={20}
                    className="[&>svg>path]:stroke-gray-700"
                  />
                }
              />
            </div>
          </div>
          {isLoading ? (
            <div className="[&>div>.loader-container]:h-[calc(100vh-5rem)] [&>div>.loader-container]:relative border-t border-gray-100">
              <EchopulseLoader />
            </div>
          ) : (
            <div className="flex-grow overflow-auto ps-6 pe-4">
              {userList && userList.length > 0 ? (
                userList?.map((user: any) => (
                  <div
                    className={`flex items-center  ${curUser.id === user.id
                      ? "bg-white text-gray-300 font-medium cursor-auto"
                      : "bg-white text-gray-700 cursor-pointer"
                      } px-1.5 py-2 mb-3 gap-2`}
                    onClick={() => { setCurUser(user); setLeaveHisCode(""); }}
                  >
                    <div className="flex-1 flex items-center gap-1.5">
                      {user && user.img ? (
                        // <img
                        //   className="w-8 h-8 rounded-full object-cover"
                        //   src={user.img ?? ProfileImg}
                        //   alt={user.img ?? ProfileImg}
                        // />
                        <UserImage
                          imgKey={user.img}
                          className="w-8 h-8 rounded-full object-cover"
                        />
                      ) : (
                        <img
                          src={getInitialsInName(user?.name ?? "N A", 24)}
                          alt={user?.name}
                          className="w-8 h-8 rounded-full object-cover"
                        />
                      )}

                      <div className="flex-1">
                        <Typography
                          variant="p"
                          size="lg"
                          className="font-normal line-clamp-1"
                        >
                          {user.name}
                        </Typography>
                        <Typography
                          variant="p"
                          size="xs"
                          className="font-normal line-clamp-1"
                        >
                          {user.title}
                        </Typography>
                      </div>
                    </div>
                    {curUser.id === user.id && (
                      <div>
                        <Icons
                          name="right"
                          variant="arrow"
                          className="[&>svg>path]:fill-gray-300"
                          size={14}
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="h-full w-full flex justify-center flex-col text-center pb-20">
                  <div>
                    <AllSvg name="emP" />
                  </div>
                  <Typography
                    className="text-gray-700 font-normal mt-2.5"
                    size="lg"
                    variant="p"
                  >
                    No members found
                  </Typography>
                </div>
              )}
            </div>
          )}
          <div className="bg-white py-4 ps-7 pe-4 rounded-b-md">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={pageSize}
              totalItems={totalRecord}
              onPageChange={handlePageChange}
              setFilterss={setFilterss}
              filterss={filterss}
              pageTitle="Employees"
            />
          </div>
        </div>
        <div
          className="w-[calc(100%-18rem)] h-full overflow-auto flex flex-col flex-grow"
          id="employee-details-area"
        >
          {userList && userList.length > 0 && (
            <div
              className="py-5 px-8 border-b border-gray-100"
              id="employee-overview"
            >
              <div className="flex items-center gap-3.5">
                {curUser && curUser.img ? (
                  // <img
                  //   className="w-12 h-12 rounded-full object-cover"
                  //   src={curUser.img ?? ProfileImg}
                  //   alt={curUser.img ?? ProfileImg}
                  // />
                  <UserImage
                    imgKey={curUser.img}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                ) : (
                  <img
                    src={getInitialsInName(curUser?.name ?? "N A", 24)}
                    alt={curUser?.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                )}
                <div>
                  <Typography
                    variant="h3"
                    className="text-gray-300 font-bold line-clamp-1"
                  >
                    {curUser?.name}
                  </Typography>
                  <Typography
                    variant="p"
                    size="lg"
                    className="font-normal line-clamp-1 text-gray-900"
                  >
                    {curUser?.timeZoneDisplayName}
                  </Typography>
                </div>
              </div>
              <div className="flex items-center mt-4 gap-4">
                <div className="flex flex-1 justify-start items-center gap-10">
                  <div className="flex items-center justify-start gap-1.5 text-400 font-medium text-gray-300">
                    <Icons
                      name="team"
                      variant="stroke"
                      className="[&>svg>path]:stroke-gray-700"
                      size={20}
                    />
                    {curUser?.title}
                  </div>
                  <div className="flex items-center justify-start gap-1.5 text-400 font-medium text-gray-300">
                    <Icons
                      name="call"
                      variant="stroke"
                      className="[&>svg>path]:stroke-gray-700"
                      size={20}
                    />
                    {curUser?.phoneNumber}
                  </div>
                  <div className="flex items-center justify-start gap-1.5 text-400 font-medium text-gray-300">
                    <Icons
                      name="email"
                      variant="stroke"
                      className="[&>svg>path]:stroke-gray-700"
                      size={20}
                    />
                    {curUser?.companyEmail}
                  </div>
                </div>
                <div className="flex items-center justify-start gap-1.5 text-400 font-medium text-gray-300">
                  <Icons
                    name="calendar"
                    variant="stroke"
                    className="[&>svg>path]:fill-gray-700"
                    size={20}
                  />
                  <div>
                    <span className="text-gray-700 me-1">
                      {curUserJoiningStatus === "Future"
                        ? "Joining on"
                        : "Joined on"}
                    </span>
                    <span>
                      {" "}
                      {curUser?.joiningDate
                        ? new Date(curUser?.joiningDate).toDateString()
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {leavesList && leavesList.length > 0 ? (
            <div className="flex flex-col flex-grow h-full w-full overflow-auto">
              <div className="flex-grow w-full flex overflow-auto">
                <div
                  className="overflow-auto w-[calc(100%-22.5rem)]"
                  id="employee-data"
                >
                  <table className="w-full">
                    <thead className="bg-gray-200 sticky left-0 top-0 z-10">
                      <tr>
                        <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left w-2/5 ps-8 whitespace-nowrap">
                          Leave Type
                        </th>
                        <th className="px-5 py-4 text-gray-700 text-200 font-bold text-center whitespace-nowrap">
                          Current Balance
                        </th>
                        <th className="px-5 py-4 text-gray-700 text-200 font-bold text-center whitespace-nowrap">
                          Add Day(s)
                        </th>
                        <th className="px-5 py-4 text-gray-700 text-200 font-bold text-center whitespace-nowrap">
                          Adjusted Balance
                        </th>
                        <th className="w-6">&nbsp;</th>
                      </tr>
                    </thead>
                    {/* {isSecLoading ? (
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <div className="relative h-[calc(100vh-340px)] w-full [&>div>.loader-container]:h-full [&>div>.loader-container]:relative [&>div]:h-full [&>div]:w-full">
                              <EchopulseLoader />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : ( */}
                    <tbody>
                      {leavesList?.map((leave: any) => {
                        const addedDaysValue =
                          addedDays[leave.leaves.leaveId] || 0;
                        const adjustedBalance =
                          leave.maximumLeave + addedDaysValue;

                        return isSecLoading ? (
                          <EchopulseLoader />
                        ) : (
                          <tr
                            key={leave.leaves.leaveId}
                            className={leaveHisCode === leave.leaves.leaveCode ? "bg-gray-200" : "bg-white"}
                          // bg-gray-200 bg-white
                          >
                            <td className="px-5 py-2.5 text-gray-300 text-400 font-normal text-left border-b border-gray-400 ps-8">
                              <div
                                className={`cursor-pointer min-w-[8rem] line-clamp-1 ${addedDaysValue !== 0
                                  ? "font-bold"
                                  : "font-medium"
                                  }`}
                                onClick={() => setLeaveHisCode(leave.leaves.leaveCode)}
                              >
                                {leave.leaves.title}
                              </div>
                            </td>
                            <td className="px-5 py-2.5 text-gray-300 text-400 font-normal text-center border-b border-gray-400">
                              <div
                                className={`${addedDaysValue !== 0
                                  ? "font-bold"
                                  : "font-medium"
                                  }`}
                              >
                                {leave.maximumLeave} Day(s)
                              </div>
                            </td>
                            <td className="px-5 py-2.5 text-gray-300 text-400 font-normal text-center border-b border-gray-400">
                              <div className="">
                                <TextInput
                                  type="number"
                                  placeholder=""
                                  size="sm"
                                  step="0.1"
                                  value={
                                    addedDays[leave.leaves.leaveId] ==
                                      null ||
                                      addedDays[leave.leaves.leaveId] === 0
                                      ? ""
                                      : addedDays[
                                        leave.leaves.leaveId
                                      ]?.toString()
                                  }
                                  // onChange={(e) =>
                                  //   handleInputChange(
                                  //     leave.leaves.leaveId,
                                  //     e.target.value
                                  //   )
                                  // }
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                                    if (regex.test(inputValue)) {
                                      handleInputChange(leave.leaves.leaveId, inputValue);
                                    }
                                  }}
                                  className="!w-[4.5rem] !font-bold"
                                />
                              </div>
                            </td>
                            <td className="px-5 py-2.5 text-gray-300 text-400 font-normal text-center border-b border-gray-400">
                              <div
                                className={`${addedDaysValue !== 0
                                  ? "font-bold"
                                  : "font-medium"
                                  }`}
                              >
                                {addedDaysValue !== 0
                                  ? (adjustedBalance).toFixed(2) + " Day(s)"
                                  : "-"}
                              </div>
                            </td>
                            {leaveHisCode === leave.leaves.leaveCode && (
                              <td className="px-2 py-2.5 text-gray-300 text-400 font-normal text-end border-b border-gray-400">
                                <div>
                                  <Icons
                                    name="right"
                                    variant="arrow"
                                    className="[&>svg>path]:fill-gray-900"
                                    size={16}
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                    {/* )} */}
                  </table>
                </div>
                <div
                  className="border-l border-gray-100 px-6 overflow-auto w-[22.5rem]"
                  id="employee-leave-history"
                >
                  <div className="flex justify-between items-center">
                    <Typography
                      variant="h6"
                      className="font-bold text-gray-300 mb-3.5 mt-4"
                    >
                      Leave History
                    </Typography>
                    <button className={`text-400 font-bold text-primary-100 ${!leaveHisCode ? "opacity-70 cursor-auto" : ""}`} onClick={() => setLeaveHisCode("")} disabled={!leaveHisCode}> Clear Filters </button>
                  </div>
                  <div className="">
                    <ul>
                      {isLoadingHistory ? (
                        <div className="[&>div>.loader-container]:h-[calc(100vh-402px)] [&>div>.loader-container]:relative border-t border-gray-100">
                          <EchopulseLoader />
                        </div>
                      ) :
                        leavesHistory &&
                          leavesHistory?.length > 0 ?
                          leavesHistory.map((t: any) => (
                            <>
                              <li className="pb-4 relative border-dashed border-l border-gray-1300 after:absolute after:h-4 after:w-4 after:rounded-full after:bg-gray-1400 after:-left-2 after:-top-1">
                                <div className="text-100 font-normal text-gray-1400 ms-4" key={`${t.year}-${t.month}`}>
                                  {getMonthName(t?.month)} {t?.year}
                                </div>
                                {t.leavesHistory &&
                                  t.leavesHistory.map((item: any) => (
                                    <div>
                                      {item?.overAllStatus !== "Credited" && (
                                        <div className="border border-gray-1200 rounded ms-4 mt-2 flex flex-col">
                                          <div className="flex justify-between items-start gap-2 pt-2.5 px-4">
                                            <div className="text-left">
                                              <div className="text-200 font-bold text-gray-300">
                                                {item?.leave?.title}
                                              </div>
                                              {item?.employeeActionTaken && (
                                                <div className="text-100 font-normal text-gray-700 mt-1">
                                                  Applied: {getDayAndMonth(item?.employeeActionTaken).day}{" "}
                                                  {getDayAndMonth(item?.employeeActionTaken).month}

                                                </div>
                                              )}
                                            </div>
                                            <div className="text-right">
                                              <div className="text-200 font-bold text-gray-300">
                                                {getDayAndMonth(item?.startDate).month}{" "}
                                                {getDayAndMonth(item?.startDate).day} -{" "}
                                                {getDayAndMonth(item?.endDate).month}{" "}
                                                {getDayAndMonth(item?.endDate).day}
                                              </div>
                                              <div className="text-100 font-normal text-gray-700 mt-1">
                                                {item?.requestFor}{" "} day(s)
                                              </div>
                                            </div>
                                          </div>
                                          <div className="bg-gray-200 py-1.5 px-4 mt-3 flex items-center gap-2.5">
                                            <div className="flex-1 text-100 font-normal text-gray-700 line-clamp-1 flex flex-col gap-1">
                                              {item?.overAllStatus !== "Pending" && (
                                                <>
                                                  {/* Handle Cancelled status */}
                                                  {item?.overAllStatus === "Cancelled" && (
                                                    <div>
                                                      Cancelled: {getDayAndMonth(item?.adminActionTaken).day}{" "}
                                                      {getDayAndMonth(item?.adminActionTaken).month}, {item?.adminName}
                                                    </div>
                                                  )}

                                                  {/* Handle Approved or Rejected status */}
                                                  {(item?.overAllStatus === "Approved" || item?.overAllStatus === "Rejected") && (
                                                    <>
                                                      {item?.isRMApproved !== null && (
                                                        <div>
                                                          {item?.isRMApproved === null
                                                            ? "Pending"
                                                            : item?.isRMApproved
                                                              ? "Approved"
                                                              : "Denied"}:: {getDayAndMonth(item?.rmActionTaken).day}{" "}
                                                          {getDayAndMonth(item?.rmActionTaken).month}, {item?.rmName}
                                                        </div>
                                                      )}
                                                      {item?.isAdminApproved !== null && (
                                                        <div>
                                                          {item?.isAdminApproved === null
                                                            ? "Pending"
                                                            : item?.isAdminApproved
                                                              ? "Approved"
                                                              : "Denied"}: {getDayAndMonth(item?.adminActionTaken).day}{" "}
                                                          {getDayAndMonth(item?.adminActionTaken).month}, {item?.adminName}
                                                        </div>
                                                      )}
                                                    </>
                                                  )}

                                                  {/* Handle other statuses (e.g., RM Approved) */}
                                                  {item?.overAllStatus !== "Cancelled" && item?.overAllStatus !== "Approved" && item?.overAllStatus !== "Rejected" && (
                                                    <>
                                                      <div>
                                                        {item?.isRMApproved === null
                                                          ? "Pending"
                                                          : item?.isRMApproved
                                                            ? "Approved"
                                                            : "Denied"}: {getDayAndMonth(item?.rmActionTaken).day}{" "}
                                                        {getDayAndMonth(item?.rmActionTaken).month}, {item?.rmName}
                                                      </div>
                                                      <div>
                                                        {item?.isAdminApproved === null
                                                          ? "Pending"
                                                          : item?.isAdminApproved
                                                            ? "Approved"
                                                            : "Denied"}: {getDayAndMonth(item?.adminActionTaken).day}{" "}
                                                        {getDayAndMonth(item?.adminActionTaken).month}, {item?.adminName}
                                                      </div>
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>

                                            <div
                                              className={`px-3 py-1 text-200 font-medium rounded-xl ${item?.overAllStatus === "Cancelled"
                                                ? "text-secondary-300 bg-pastel-600"
                                                : item?.overAllStatus === "Pending"
                                                  ? "text-gray-700 bg-gray-100"
                                                  : item?.overAllStatus === "Approved"
                                                    ? "text-green-100 bg-green-100 bg-opacity-10"
                                                    : item?.overAllStatus === "Rejected"
                                                      ? "text-primary-100 bg-primary-100 bg-opacity-10"
                                                      : ""
                                                }`}
                                            >
                                              {item?.overAllStatus === "Rejected"
                                                ? "Denied" : item?.overAllStatus}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {item?.overAllStatus === "Credited" && (
                                        <div className="border border-gray-1200 rounded ms-4 mt-2 flex flex-col">
                                          <div className="flex justify-between items-start gap-2 pt-2.5 px-4">
                                            <div className="text-left">
                                              <div className="text-200 font-bold text-gray-300">
                                                {item?.leave?.title}
                                              </div>
                                              {item?.employeeActionTaken && (
                                                <div className="text-100 font-normal text-gray-700 mt-1">
                                                  {item?.adminNote}

                                                </div>
                                              )}
                                            </div>
                                            <div className="text-right">

                                              <div className="text-200 font-bold text-yellow-400">
                                                {item?.leaveAdded > 0 ? `+ ${item.leaveAdded} day(s)` : `${item.leaveAdded} day(s)`}
                                              </div>
                                              <div className="text-100 font-normal text-gray-700 mt-1">
                                                {item?.leaveBalance}{" "} Balance
                                              </div>
                                            </div>
                                          </div>
                                          <div className="bg-gray-200 py-1.5 px-4 mt-3 flex items-center gap-2.5">
                                            <div className="flex-1 text-100 font-normal text-gray-700 line-clamp-1 flex flex-col gap-1">
                                              <div>
                                                Processed: {getDayAndMonth(item?.employeeActionTaken).day}{" "}
                                                {getDayAndMonth(item?.employeeActionTaken).month}, {item?.adminName}
                                              </div>
                                            </div>

                                            <div
                                              className={`px-3 py-1 text-200 font-medium rounded-xl text-green-100 bg-green-100 bg-opacity-10`}
                                            >
                                              {item?.overAllStatus}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </li>
                            </>
                          )) : (
                            <div className="h-[calc(100vh-402px)] w-full flex justify-center flex-col text-center">
                              <div>
                                <AllSvg name="emP" />
                              </div>
                              <Typography
                                className="text-gray-700 font-normal mt-2.5"
                                size="lg"
                                variant="p"
                              >

                                Selected employee's leave history
                                <br /> will be listed here.
                              </Typography>
                            </div>

                          )}
                    </ul >
                  </div>

                </div>
              </div>
              <div
                className="px-8 py-4 flex justify-start items-center gap-5 border-t border-gray-100"
                id="button-area"
              >
                <Button
                  label="Save"
                  variant="primary"
                  size="large"
                  onClick={() => handleSaveBtn()}
                  isDisabled={updatedValues.length === 0 || curUser === null || isSaving}
                />

                <Button
                  label="Cancel"
                  onClick={() => handleClearBtn()}
                  variant="outline"
                  size="large"
                  isDisabled={updatedValues.length === 0 && curUser === null}
                />
              </div>
            </div>
          ) : (
            <div className="h-full w-full flex justify-center flex-col text-center pb-20">
              <div>
                <AllSvg name="emP" />
              </div>
              <Typography
                className="text-gray-700 font-normal mt-2.5"
                size="lg"
                variant="p"
              >
                Select employee to view details
              </Typography>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default LeaveBalanceRegularization;
