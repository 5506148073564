import React from "react";

export interface AllSvgProps {
  name: "lock" | "LogoL" | "LogoM" | "menu" | "cross" | "emP";

  className?: string;
}
const AllSvg: React.FC<AllSvgProps> = ({ name, className }) => {
  let allSVG = null;

  switch (name) {
    case "lock":
      allSVG = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
            stroke="#232323"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z"
            stroke="#232323"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
            stroke="#232323"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;
    case "LogoL":
      allSVG = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="39"
          height="40"
          viewBox="0 0 39 40"
          fill="none"
        >
          <path
            d="M24.9219 9.96317C20.825 7.39074 15.7754 7.39074 11.8691 9.58207C14.0605 7.20019 17.1093 5.86633 20.2534 5.67578C21.7778 5.96161 23.3022 6.53326 24.7313 7.39074C28.3518 9.67734 30.4478 13.4884 30.7336 17.5852C29.7809 14.5364 27.8754 11.7734 25.0171 9.96317H24.9219Z"
            fill="#1FA2F4"
          />
          <path
            d="M32.4506 32.5412C28.8302 35.7805 24.0664 37.3049 19.3979 36.9238C15.7774 37.2097 12.0617 36.3522 8.72707 34.2561C3.5822 31.1121 0.628669 25.6814 0.152293 19.9648C-0.419359 26.7294 2.72473 33.5892 8.72707 37.3049C16.7302 42.3545 27.0199 40.0679 32.4506 32.4459V32.5412Z"
            fill="url(#paint0_linear_4690_36087)"
          />
          <path
            d="M30.0673 2.72112C26.7327 0.625059 23.0169 -0.232418 19.3965 0.0534077C22.1595 0.243958 24.8272 1.10144 27.3043 2.72112C33.3067 6.43685 36.4508 13.2967 35.8791 20.0612C36.1649 23.777 35.4027 27.6832 33.3067 31.2084C33.0209 31.6848 32.735 32.1612 32.4492 32.5423C33.8783 31.3037 35.1169 29.7793 36.0697 28.0643C41.2145 19.299 38.5468 7.96126 30.0673 2.72112Z"
            fill="url(#paint1_linear_4690_36087)"
          />
          <path
            d="M35.8748 20.0625C35.6842 22.8255 34.8267 25.5885 33.3023 28.0656C30.1583 33.3058 24.9181 36.4499 19.2969 36.9262C23.9654 37.3073 28.7291 35.7829 32.3496 32.5436C32.6354 32.0672 32.9212 31.6861 33.2071 31.2097C35.2078 27.7798 36.0653 23.8735 35.7795 20.0625H35.8748Z"
            fill="#A8C7FD"
          />
          <path
            d="M19.3945 0.148737C12.8205 -0.327639 6.34176 2.81645 2.62602 9.00934C0.625244 12.4392 -0.232233 16.3455 0.0535924 20.1565C0.244143 17.3936 1.10162 14.6306 2.62602 12.1534C3.67405 10.4385 4.91263 8.91406 6.24649 7.67548C9.39057 3.19755 14.2496 0.625114 19.2992 0.244013L19.3945 0.148737Z"
            fill="url(#paint2_linear_4690_36087)"
          />
          <path
            d="M27.4 2.72044C24.9229 1.19604 22.1599 0.33856 19.4922 0.0527344C14.4426 0.433835 9.58354 3.10154 6.43945 7.48421C12.156 2.43462 20.6355 1.48186 27.4 5.76925C32.5449 8.91333 35.4984 14.344 35.9748 20.0605C36.5464 13.296 33.4024 6.43618 27.4 2.72044Z"
            fill="#FEB6D7"
          />
          <path
            d="M19.394 36.9239C16.6311 36.7333 13.9634 35.8759 11.4862 34.2562C3.0067 29.016 0.338988 17.6783 5.48385 8.91296C5.76968 8.43658 6.0555 7.9602 6.34133 7.5791C4.9122 8.81768 3.67362 10.3421 2.72087 12.057C1.19647 14.6295 0.434263 17.2972 0.148438 20.0602C0.624814 25.7767 3.57835 31.1121 8.72321 34.3515C12.0578 36.4475 15.7736 37.305 19.394 37.0192V36.9239Z"
            fill="url(#paint3_linear_4690_36087)"
          />
          <path
            d="M33.3964 28.1588C30.2523 33.3989 25.0121 36.543 19.3909 37.0194C16.6279 36.8288 13.9602 35.9713 11.483 34.3517C3.00355 29.0162 0.335843 17.6785 5.48071 8.91316C5.76653 8.43678 6.05236 7.9604 6.33819 7.5793C12.0547 2.52971 20.5342 1.57696 27.2987 5.86435C32.4436 9.00843 35.3971 14.4391 35.8735 20.1556C35.683 22.9186 34.8255 25.6816 33.3011 28.1588H33.3964ZM27.2987 7.5793C25.1074 6.15017 22.6303 5.57852 20.1531 5.76907C15.1988 4.9116 10.0539 7.1982 7.29094 11.8667C6.24291 13.6769 5.67126 15.6777 5.48071 17.6785C3.76575 23.4903 5.95708 29.969 11.1972 33.3036C17.3901 37.2099 25.5838 35.1139 29.2995 28.7304C29.5854 28.254 29.8712 27.6824 30.0617 27.206C30.5381 26.6344 31.0145 25.9674 31.3956 25.3005C35.016 19.2029 33.1105 11.295 27.2035 7.67458L27.2987 7.5793Z"
            fill="url(#paint4_linear_4690_36087)"
          />
          <path
            d="M20.1568 5.67347C15.2025 4.81599 10.0576 7.1026 7.29461 11.7711C6.24658 13.5813 5.67493 15.5821 5.48438 17.5829C5.7702 16.5349 6.24658 15.4868 6.81823 14.5341C8.05681 12.3427 9.86704 10.7231 11.8678 9.57976C14.0591 7.19787 17.108 5.86402 20.252 5.67347H20.1568Z"
            fill="#73A3FD"
          />
          <path
            d="M27.3019 7.57917C25.1106 6.15004 22.6334 5.57839 20.1562 5.76894C21.6807 6.05477 23.2051 6.62642 24.6342 7.4839C28.2546 9.7705 30.3507 13.5815 30.6365 17.6784C31.5893 20.7272 31.3987 24.1571 30.0649 27.2059C30.5413 26.6342 31.0176 25.9673 31.3987 25.3004C35.0192 19.2028 33.1137 11.2949 27.2066 7.67445L27.3019 7.57917Z"
            fill="url(#paint5_linear_4690_36087)"
          />
          <path
            d="M30.6365 17.583C30.8271 20.1554 30.2554 22.9184 28.8263 25.3003C26.8255 28.6349 23.5862 30.731 20.1562 31.3979C23.872 31.5885 27.5877 30.0641 30.1602 27.1105C31.494 24.0617 31.5893 20.6318 30.7318 17.583H30.6365Z"
            fill="#F85454"
          />
          <path
            d="M30.0629 27.1105C27.4905 30.0641 23.7747 31.5885 20.059 31.3979C17.2007 31.8743 14.1519 31.3979 11.3889 29.683C7.19682 27.0153 5.00549 22.3468 5.38659 17.583C3.67164 23.3948 5.86297 29.8735 11.1031 33.2082C17.296 37.1144 25.4897 35.0184 29.2054 28.6349C29.4912 28.1586 29.7771 27.5869 29.9676 27.1105H30.0629Z"
            fill="#FB6DDE"
          />
          <path
            d="M20.1583 31.3995C18.1576 31.3042 16.1568 30.7326 14.3465 29.5893C8.43947 25.8736 6.53397 17.9657 10.1544 11.9634C10.6308 11.1059 11.2025 10.439 11.8694 9.67676C9.8686 10.8201 8.05837 12.4397 6.81979 14.6311C6.24814 15.5838 5.77177 16.6319 5.48594 17.6799C5.10484 22.4436 7.29617 27.1121 11.4883 29.7798C14.156 31.4948 17.2048 31.9712 20.1583 31.4948V31.3995Z"
            fill="#5A39CB"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4690_36087"
              x1="-0.705185"
              y1="25.205"
              x2="27.9727"
              y2="38.4482"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.5" stopColor="#1772F3" />
              <stop offset="1" stopColor="#20B4F3" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4690_36087"
              x1="32.6397"
              y1="31.5895"
              x2="27.7807"
              y2="0.24396"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A237D1" />
              <stop offset="1" stopColor="#8F37CF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_4690_36087"
              x1="0.0535923"
              y1="10.0574"
              x2="19.3945"
              y2="10.0574"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FA6F48" />
              <stop offset="1" stopColor="#FDA62F" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_4690_36087"
              x1="15.2019"
              y1="36.4475"
              x2="1.95866"
              y2="13.4862"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F8A5F8" />
              <stop offset="1" stopColor="#FBBBF7" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_4690_36087"
              x1="4.14685"
              y1="22.1564"
              x2="34.3491"
              y2="17.6785"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCC4FE" />
              <stop offset="1" stopColor="#FEE5FD" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_4690_36087"
              x1="29.7791"
              y1="18.1547"
              x2="17.9649"
              y2="5.10202"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBD7F7" />
              <stop offset="1" stopColor="#FCDAF6" />
            </linearGradient>
          </defs>
        </svg>
      );
      break;
    case "LogoM":
      allSVG = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.9526 5.97729C12.4945 4.43386 9.46482 4.43386 7.12109 5.74864C8.43587 4.31953 10.2651 3.51924 12.1515 3.40491C13.0662 3.5764 13.9808 3.91938 14.8382 4.43386C17.0105 5.8058 18.2681 8.09236 18.4396 10.5504C17.8679 8.72117 16.7247 7.06341 15.0097 5.97729H14.9526Z"
            fill="#1FA2F4"
          />
          <path
            d="M19.4692 19.5248C17.297 21.4684 14.4388 22.383 11.6377 22.1543C9.46551 22.3258 7.23611 21.8114 5.23536 20.5537C2.1485 18.6673 0.376414 15.409 0.0905937 11.9791C-0.252391 16.0378 1.63402 20.1536 5.23536 22.383C10.0371 25.4127 16.2109 24.0408 19.4692 19.4676V19.5248Z"
            fill="url(#paint0_linear_25975_23869)"
          />
          <path
            d="M18.0411 1.63288C16.0403 0.375273 13.8109 -0.139204 11.6387 0.0322882C13.2964 0.146616 14.897 0.661093 16.3833 1.63288C19.9846 3.86228 21.871 7.9781 21.5281 12.0367C21.6996 14.2662 21.2422 16.6099 19.9846 18.725C19.8131 19.0108 19.6416 19.2966 19.4702 19.5252C20.3276 18.7821 21.0707 17.8675 21.6424 16.8385C24.7292 11.5794 23.1287 4.77691 18.0411 1.63288Z"
            fill="url(#paint1_linear_25975_23869)"
          />
          <path
            d="M21.5266 12.0371C21.4123 13.6949 20.8978 15.3526 19.9832 16.8389C18.0968 19.9829 14.9528 21.8693 11.5801 22.1552C14.3811 22.3838 17.2393 21.4692 19.4116 19.5256C19.5831 19.2398 19.7545 19.0111 19.926 18.7253C21.1265 16.6674 21.641 14.3237 21.4695 12.0371H21.5266Z"
            fill="#A8C7FD"
          />
          <path
            d="M11.6365 0.0890954C7.69214 -0.196725 3.80499 1.68969 1.57559 5.40536C0.375139 7.46326 -0.139337 9.80699 0.0321548 12.0936C0.146483 10.4358 0.66096 8.77804 1.57559 7.29177C2.20439 6.26282 2.94752 5.34819 3.74782 4.60506C5.63424 1.91835 8.54961 0.374916 11.5793 0.14626L11.6365 0.0890954Z"
            fill="url(#paint2_linear_25975_23869)"
          />
          <path
            d="M16.4374 1.63245C14.9512 0.717829 13.2934 0.203353 11.6928 0.0318604C8.66311 0.260517 5.74774 1.86111 3.86133 4.49066C7.29117 1.46096 12.3788 0.889322 16.4374 3.46171C19.5243 5.34812 21.2964 8.60647 21.5822 12.0363C21.9252 7.97767 20.0388 3.86185 16.4374 1.63245Z"
            fill="#FEB6D7"
          />
          <path
            d="M11.637 22.1546C9.97923 22.0403 8.37864 21.5258 6.89237 20.554C1.80477 17.41 0.204172 10.6075 3.29103 5.34839C3.46253 5.06257 3.63402 4.77675 3.80551 4.5481C2.94805 5.29123 2.20492 6.20586 1.63327 7.23481C0.718649 8.77824 0.261336 10.3788 0.0898438 12.0366C0.375664 15.4664 2.14775 18.6676 5.23461 20.6112C7.23536 21.8688 9.46476 22.3833 11.637 22.2118V22.1546Z"
            fill="url(#paint3_linear_25975_23869)"
          />
          <path
            d="M20.0402 16.8956C18.1538 20.0396 15.0098 21.926 11.6371 22.2118C9.97933 22.0975 8.37873 21.583 6.89247 20.6113C1.80486 17.4101 0.204268 10.6075 3.29113 5.34844C3.46262 5.06262 3.63411 4.7768 3.80561 4.54814C7.23545 1.51845 12.3231 0.946805 16.3817 3.51919C19.4686 5.4056 21.2407 8.66396 21.5265 12.0938C21.4121 13.7516 20.8977 15.4093 19.983 16.8956H20.0402ZM16.3817 4.54814C15.0669 3.69068 13.5807 3.3477 12.0944 3.46202C9.12187 2.94755 6.03501 4.31949 4.37725 7.12053C3.74844 8.20665 3.40546 9.40709 3.29113 10.6075C2.26218 14.0945 3.57695 17.9817 6.72098 19.9825C10.4366 22.3262 15.3528 21.0686 17.5822 17.2386C17.7536 16.9528 17.9251 16.6098 18.0395 16.3239C18.3253 15.981 18.6111 15.5808 18.8398 15.1807C21.012 11.5222 19.8687 6.77754 16.3245 4.60531L16.3817 4.54814Z"
            fill="url(#paint4_linear_25975_23869)"
          />
          <path
            d="M12.0943 3.40464C9.12175 2.89016 6.03489 4.2621 4.37713 7.06314C3.74833 8.14926 3.40534 9.34971 3.29102 10.5502C3.46251 9.92135 3.74833 9.29254 4.09131 8.7209C4.83445 7.40613 5.92056 6.43434 7.12101 5.74837C8.43578 4.31927 10.265 3.51897 12.1515 3.40464H12.0943Z"
            fill="#73A3FD"
          />
          <path
            d="M16.3811 4.54787C15.0663 3.69041 13.58 3.34743 12.0938 3.46176C13.0084 3.63325 13.923 3.97623 14.7805 4.49071C16.9527 5.86265 18.2103 8.14921 18.3818 10.6073C18.9534 12.4365 18.8391 14.4944 18.0388 16.3237C18.3246 15.9807 18.6105 15.5805 18.8391 15.1804C21.0113 11.5219 19.8681 6.77727 16.3239 4.60504L16.3811 4.54787Z"
            fill="url(#paint5_linear_25975_23869)"
          />
          <path
            d="M18.3818 10.55C18.4961 12.0935 18.1531 13.7512 17.2957 15.1803C16.0952 17.1811 14.1517 18.4387 12.0938 18.8388C14.3232 18.9532 16.5525 18.0385 18.096 16.2665C18.8963 14.4372 18.9534 12.3793 18.439 10.55H18.3818Z"
            fill="#F85454"
          />
          <path
            d="M18.0367 16.2665C16.4932 18.0385 14.2638 18.9532 12.0344 18.8388C10.3195 19.1247 8.49027 18.8388 6.83251 17.8099C4.31729 16.2093 3.00251 13.4083 3.23117 10.55C2.20222 14.0371 3.51699 17.9242 6.66102 19.925C10.3767 22.2687 15.2928 21.0111 17.5222 17.1811C17.6937 16.8953 17.8652 16.5523 17.9795 16.2665H18.0367Z"
            fill="#FB6DDE"
          />
          <path
            d="M12.0944 18.8393C10.894 18.7822 9.69355 18.4392 8.60743 17.7532C5.06326 15.5238 3.91998 10.7792 6.09221 7.17785C6.37803 6.66337 6.72102 6.26322 7.12117 5.80591C5.92072 6.49188 4.8346 7.46367 4.09147 8.77844C3.74849 9.35008 3.46266 9.97889 3.29117 10.6077C3.06252 13.4659 4.37729 16.2669 6.89251 17.8675C8.49311 18.8965 10.3224 19.1823 12.0944 18.8965V18.8393Z"
            fill="#5A39CB"
          />
          <defs>
            <linearGradient
              id="paint0_linear_25975_23869"
              x1="-0.423883"
              y1="15.1232"
              x2="16.7825"
              y2="23.069"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.5" stopColor="#1772F3" />
              <stop offset="1" stopColor="#20B4F3" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_25975_23869"
              x1="19.5845"
              y1="18.9536"
              x2="16.6691"
              y2="0.146617"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A237D1" />
              <stop offset="1" stopColor="#8F37CF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_25975_23869"
              x1="0.0321548"
              y1="6.03416"
              x2="11.6365"
              y2="6.03416"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FA6F48" />
              <stop offset="1" stopColor="#FDA62F" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_25975_23869"
              x1="9.12177"
              y1="21.8688"
              x2="1.17596"
              y2="8.09227"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F8A5F8" />
              <stop offset="1" stopColor="#FBBBF7" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_25975_23869"
              x1="2.49083"
              y1="13.2942"
              x2="20.6119"
              y2="10.6075"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCC4FE" />
              <stop offset="1" stopColor="#FEE5FD" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_25975_23869"
              x1="17.8673"
              y1="10.8931"
              x2="10.779"
              y2="3.06161"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBD7F7" />
              <stop offset="1" stopColor="#FCDAF6" />
            </linearGradient>
          </defs>
        </svg>
      );
      break;
    case "menu":
      allSVG = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3.66664 3.2528C3.91332 3.08797 4.20333 3 4.5 3C4.89783 3 5.27936 3.15804 5.56066 3.43934C5.84196 3.72064 6 4.10217 6 4.5C6 4.79667 5.91203 5.08668 5.7472 5.33336C5.58238 5.58003 5.34811 5.77229 5.07403 5.88582C4.79994 5.99935 4.49834 6.02905 4.20736 5.97118C3.91639 5.9133 3.64912 5.77044 3.43934 5.56066C3.22956 5.35088 3.0867 5.08361 3.02882 4.79263C2.97094 4.50166 3.00065 4.20006 3.11418 3.92597C3.22771 3.65189 3.41997 3.41762 3.66664 3.2528Z"
            fill="white"
          />
          <path
            d="M11.6666 3.2528C11.9133 3.08797 12.2033 3 12.5 3C12.8978 3 13.2794 3.15804 13.5607 3.43934C13.842 3.72064 14 4.10217 14 4.5C14 4.79667 13.912 5.08668 13.7472 5.33336C13.5824 5.58003 13.3481 5.77229 13.074 5.88582C12.7999 5.99935 12.4983 6.02905 12.2074 5.97118C11.9164 5.9133 11.6491 5.77044 11.4393 5.56066C11.2296 5.35088 11.0867 5.08361 11.0288 4.79263C10.9709 4.50166 11.0006 4.20006 11.1142 3.92597C11.2277 3.65189 11.42 3.41762 11.6666 3.2528Z"
            fill="white"
          />
          <path
            d="M19.6666 11.2528C19.9133 11.088 20.2033 11 20.5 11C20.8978 11 21.2794 11.158 21.5607 11.4393C21.842 11.7206 22 12.1022 22 12.5C22 12.7967 21.912 13.0867 21.7472 13.3334C21.5824 13.58 21.3481 13.7723 21.074 13.8858C20.7999 13.9993 20.4983 14.0291 20.2074 13.9712C19.9164 13.9133 19.6491 13.7704 19.4393 13.5607C19.2296 13.3509 19.0867 13.0836 19.0288 12.7926C18.9709 12.5017 19.0006 12.2001 19.1142 11.926C19.2277 11.6519 19.42 11.4176 19.6666 11.2528Z"
            fill="white"
          />
          <path
            d="M19.6666 3.2528C19.9133 3.08797 20.2033 3 20.5 3C20.8978 3 21.2794 3.15804 21.5607 3.43934C21.842 3.72064 22 4.10217 22 4.5C22 4.79667 21.912 5.08668 21.7472 5.33336C21.5824 5.58003 21.3481 5.77229 21.074 5.88582C20.7999 5.99935 20.4983 6.02905 20.2074 5.97118C19.9164 5.9133 19.6491 5.77044 19.4393 5.56066C19.2296 5.35088 19.0867 5.08361 19.0288 4.79263C18.9709 4.50166 19.0006 4.20006 19.1142 3.92597C19.2277 3.65189 19.42 3.41762 19.6666 3.2528Z"
            fill="white"
          />
          <path
            d="M3.66664 11.2528C3.91332 11.088 4.20333 11 4.5 11C4.89783 11 5.27936 11.158 5.56066 11.4393C5.84196 11.7206 6 12.1022 6 12.5C6 12.7967 5.91203 13.0867 5.7472 13.3334C5.58238 13.58 5.34811 13.7723 5.07403 13.8858C4.79994 13.9993 4.49834 14.0291 4.20736 13.9712C3.91639 13.9133 3.64912 13.7704 3.43934 13.5607C3.22956 13.3509 3.0867 13.0836 3.02882 12.7926C2.97094 12.5017 3.00065 12.2001 3.11418 11.926C3.22771 11.6519 3.41997 11.4176 3.66664 11.2528Z"
            fill="white"
          />
          <path
            d="M10 12L12.5 14L15 12"
            stroke="#F15845"
            stroke-linecap="round"
          />
          <path
            d="M3.66664 19.2528C3.91332 19.088 4.20333 19 4.5 19C4.89783 19 5.27936 19.158 5.56066 19.4393C5.84196 19.7206 6 20.1022 6 20.5C6 20.7967 5.91203 21.0867 5.7472 21.3334C5.58238 21.58 5.34811 21.7723 5.07403 21.8858C4.79994 21.9993 4.49834 22.0291 4.20736 21.9712C3.91639 21.9133 3.64912 21.7704 3.43934 21.5607C3.22956 21.3509 3.0867 21.0836 3.02882 20.7926C2.97094 20.5017 3.00065 20.2001 3.11418 19.926C3.22771 19.6519 3.41997 19.4176 3.66664 19.2528Z"
            fill="white"
          />
          <path
            d="M11.6666 19.2528C11.9133 19.088 12.2033 19 12.5 19C12.8978 19 13.2794 19.158 13.5607 19.4393C13.842 19.7206 14 20.1022 14 20.5C14 20.7967 13.912 21.0867 13.7472 21.3334C13.5824 21.58 13.3481 21.7723 13.074 21.8858C12.7999 21.9993 12.4983 22.0291 12.2074 21.9712C11.9164 21.9133 11.6491 21.7704 11.4393 21.5607C11.2296 21.3509 11.0867 21.0836 11.0288 20.7926C10.9709 20.5017 11.0006 20.2001 11.1142 19.926C11.2277 19.6519 11.42 19.4176 11.6666 19.2528Z"
            fill="white"
          />
          <path
            d="M19.6666 19.2528C19.9133 19.088 20.2033 19 20.5 19C20.8978 19 21.2794 19.158 21.5607 19.4393C21.842 19.7206 22 20.1022 22 20.5C22 20.7967 21.912 21.0867 21.7472 21.3334C21.5824 21.58 21.3481 21.7723 21.074 21.8858C20.7999 21.9993 20.4983 22.0291 20.2074 21.9712C19.9164 21.9133 19.6491 21.7704 19.4393 21.5607C19.2296 21.3509 19.0867 21.0836 19.0288 20.7926C18.9709 20.5017 19.0006 20.2001 19.1142 19.926C19.2277 19.6519 19.42 19.4176 19.6666 19.2528Z"
            fill="white"
          />
        </svg>
      );
      break;
    case "cross":
      allSVG = (
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="24"
        //   height="24"
        //   viewBox="0 0 24 24"
        //   fill="none"
        // >
        //   <path
        //     d="M3.24506 2.78266C3.52075 2.59845 3.84487 2.50012 4.17644 2.50012C4.62105 2.50012 5.04746 2.67675 5.36185 2.99115C5.67625 3.30555 5.85287 3.73197 5.85287 4.17659C5.85287 4.50817 5.75455 4.8323 5.57034 5.10799C5.38613 5.38369 5.12431 5.59856 4.81798 5.72545C4.51165 5.85234 4.17458 5.88554 3.84938 5.82085C3.52418 5.75617 3.22547 5.5965 2.99102 5.36204C2.75656 5.12758 2.5969 4.82886 2.53221 4.50366C2.46753 4.17845 2.50073 3.84137 2.62761 3.53504C2.7545 3.2287 2.96937 2.96687 3.24506 2.78266Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M7.46741 8.27686C7.6512 8.15405 7.86728 8.0885 8.08833 8.0885C8.38474 8.0885 8.66901 8.20625 8.87861 8.41585C9.0882 8.62545 9.20595 8.90973 9.20595 9.20615C9.20595 9.4272 9.1404 9.64328 9.0176 9.82708C8.89479 10.0109 8.72024 10.1541 8.51602 10.2387C8.3118 10.3233 8.08709 10.3454 7.87029 10.3023C7.65349 10.2592 7.45435 10.1527 7.29805 9.99644C7.14175 9.84014 7.0353 9.64099 6.99218 9.42419C6.94905 9.20739 6.97119 8.98267 7.05578 8.77844C7.14037 8.57422 7.28362 8.39967 7.46741 8.27686Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M18.8935 2.78266C19.1692 2.59845 19.4933 2.50012 19.8249 2.50012C20.2695 2.50012 20.6959 2.67675 21.0103 2.99115C21.3247 3.30555 21.5013 3.73197 21.5013 4.17659C21.5013 4.50817 21.403 4.8323 21.2188 5.10799C21.0346 5.38369 20.7727 5.59856 20.4664 5.72545C20.1601 5.85234 19.823 5.88554 19.4978 5.82085C19.1726 5.75617 18.8739 5.5965 18.6395 5.36204C18.405 5.12758 18.2453 4.82886 18.1807 4.50366C18.116 4.17845 18.1492 3.84137 18.276 3.53504C18.4029 3.2287 18.6178 2.96687 18.8935 2.78266Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M7.46741 14.9827C7.6512 14.8599 7.86728 14.7943 8.08833 14.7943C8.38474 14.7943 8.66901 14.9121 8.87861 15.1217C9.0882 15.3313 9.20595 15.6155 9.20595 15.912C9.20595 16.133 9.1404 16.3491 9.0176 16.5329C8.89479 16.7167 8.72024 16.8599 8.51602 16.9445C8.3118 17.0291 8.08709 17.0513 7.87029 17.0081C7.65349 16.965 7.45435 16.8586 7.29805 16.7023C7.14175 16.5459 7.0353 16.3468 6.99218 16.13C6.94905 15.9132 6.97119 15.6885 7.05578 15.4843C7.14037 15.28 7.28362 15.1055 7.46741 14.9827Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M11.0673 10.6063C11.343 10.4221 11.6671 10.3237 11.9987 10.3237C12.4433 10.3237 12.8697 10.5004 13.1841 10.8148C13.4985 11.1292 13.6751 11.5556 13.6751 12.0002C13.6751 12.3318 13.5768 12.6559 13.3926 12.9316C13.2084 13.2073 12.9466 13.4222 12.6402 13.5491C12.3339 13.6759 11.9968 13.7091 11.6716 13.6445C11.3464 13.5798 11.0477 13.4201 10.8133 13.1856C10.5788 12.9512 10.4192 12.6525 10.3545 12.3273C10.2898 12.0021 10.323 11.665 10.4499 11.3586C10.5768 11.0523 10.7916 10.7905 11.0673 10.6063Z"
        //     fill="#F15845"
        //   />
        //   <path
        //     d="M15.2897 8.27686C15.4735 8.15405 15.6895 8.0885 15.9106 8.0885C16.207 8.0885 16.4913 8.20625 16.7009 8.41585C16.9105 8.62545 17.0282 8.90973 17.0282 9.20615C17.0282 9.4272 16.9627 9.64328 16.8399 9.82708C16.7171 10.0109 16.5425 10.1541 16.3383 10.2387C16.1341 10.3233 15.9094 10.3454 15.6926 10.3023C15.4758 10.2592 15.2766 10.1527 15.1203 9.99644C14.964 9.84014 14.8576 9.64099 14.8144 9.42419C14.7713 9.20739 14.7935 8.98267 14.878 8.77844C14.9626 8.57422 15.1059 8.39967 15.2897 8.27686Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M3.24506 18.4298C3.52075 18.2455 3.84487 18.1472 4.17644 18.1472C4.62105 18.1472 5.04746 18.3238 5.36185 18.6382C5.67625 18.9526 5.85287 19.3791 5.85287 19.8237C5.85287 20.1553 5.75455 20.4794 5.57034 20.7551C5.38613 21.0308 5.12431 21.2457 4.81798 21.3725C4.51165 21.4994 4.17458 21.5326 3.84938 21.4679C3.52418 21.4033 3.22547 21.2436 2.99102 21.0091C2.75656 20.7747 2.5969 20.476 2.53221 20.1508C2.46753 19.8255 2.50073 19.4885 2.62761 19.1821C2.7545 18.8758 2.96937 18.614 3.24506 18.4298Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M15.2897 14.9827C15.4735 14.8599 15.6895 14.7943 15.9106 14.7943C16.207 14.7943 16.4913 14.9121 16.7009 15.1217C16.9105 15.3313 17.0282 15.6155 17.0282 15.912C17.0282 16.133 16.9627 16.3491 16.8399 16.5329C16.7171 16.7167 16.5425 16.8599 16.3383 16.9445C16.1341 17.0291 15.9094 17.0513 15.6926 17.0081C15.4758 16.965 15.2766 16.8586 15.1203 16.7023C14.964 16.5459 14.8576 16.3468 14.8144 16.13C14.7713 15.9132 14.7935 15.6885 14.878 15.4843C14.9626 15.28 15.1059 15.1055 15.2897 14.9827Z"
        //     fill="white"
        //   />
        //   <path
        //     d="M18.8935 18.4296C19.1692 18.2454 19.4933 18.1471 19.8249 18.1471C20.2695 18.1471 20.6959 18.3237 21.0103 18.6381C21.3247 18.9525 21.5013 19.3789 21.5013 19.8236C21.5013 20.1551 21.403 20.4793 21.2188 20.755C21.0346 21.0307 20.7727 21.2455 20.4664 21.3724C20.1601 21.4993 19.823 21.5325 19.4978 21.4678C19.1726 21.4031 18.8739 21.2435 18.6395 21.009C18.405 20.7746 18.2453 20.4758 18.1807 20.1506C18.116 19.8254 18.1492 19.4883 18.276 19.182C18.4029 18.8757 18.6178 18.6138 18.8935 18.4296Z"
        //     fill="white"
        //   />
        // </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3.66664 3.2528C3.91332 3.08797 4.20333 3 4.5 3C4.89783 3 5.27936 3.15804 5.56066 3.43934C5.84196 3.72064 6 4.10217 6 4.5C6 4.79667 5.91203 5.08668 5.7472 5.33336C5.58238 5.58003 5.34811 5.77229 5.07403 5.88582C4.79994 5.99935 4.49834 6.02905 4.20736 5.97118C3.91639 5.9133 3.64912 5.77044 3.43934 5.56066C3.22956 5.35088 3.0867 5.08361 3.02882 4.79263C2.97094 4.50166 3.00065 4.20006 3.11418 3.92597C3.22771 3.65189 3.41997 3.41762 3.66664 3.2528Z"
            fill="white"
          />
          <path
            d="M11.6666 3.2528C11.9133 3.08797 12.2033 3 12.5 3C12.8978 3 13.2794 3.15804 13.5607 3.43934C13.842 3.72064 14 4.10217 14 4.5C14 4.79667 13.912 5.08668 13.7472 5.33336C13.5824 5.58003 13.3481 5.77229 13.074 5.88582C12.7999 5.99935 12.4983 6.02905 12.2074 5.97118C11.9164 5.9133 11.6491 5.77044 11.4393 5.56066C11.2296 5.35088 11.0867 5.08361 11.0288 4.79263C10.9709 4.50166 11.0006 4.20006 11.1142 3.92597C11.2277 3.65189 11.42 3.41762 11.6666 3.2528Z"
            fill="white"
          />
          <path
            d="M19.6666 11.2528C19.9133 11.088 20.2033 11 20.5 11C20.8978 11 21.2794 11.158 21.5607 11.4393C21.842 11.7206 22 12.1022 22 12.5C22 12.7967 21.912 13.0867 21.7472 13.3334C21.5824 13.58 21.3481 13.7723 21.074 13.8858C20.7999 13.9993 20.4983 14.0291 20.2074 13.9712C19.9164 13.9133 19.6491 13.7704 19.4393 13.5607C19.2296 13.3509 19.0867 13.0836 19.0288 12.7926C18.9709 12.5017 19.0006 12.2001 19.1142 11.926C19.2277 11.6519 19.42 11.4176 19.6666 11.2528Z"
            fill="white"
          />
          <path
            d="M19.6666 3.2528C19.9133 3.08797 20.2033 3 20.5 3C20.8978 3 21.2794 3.15804 21.5607 3.43934C21.842 3.72064 22 4.10217 22 4.5C22 4.79667 21.912 5.08668 21.7472 5.33336C21.5824 5.58003 21.3481 5.77229 21.074 5.88582C20.7999 5.99935 20.4983 6.02905 20.2074 5.97118C19.9164 5.9133 19.6491 5.77044 19.4393 5.56066C19.2296 5.35088 19.0867 5.08361 19.0288 4.79263C18.9709 4.50166 19.0006 4.20006 19.1142 3.92597C19.2277 3.65189 19.42 3.41762 19.6666 3.2528Z"
            fill="white"
          />
          <path
            d="M3.66664 11.2528C3.91332 11.088 4.20333 11 4.5 11C4.89783 11 5.27936 11.158 5.56066 11.4393C5.84196 11.7206 6 12.1022 6 12.5C6 12.7967 5.91203 13.0867 5.7472 13.3334C5.58238 13.58 5.34811 13.7723 5.07403 13.8858C4.79994 13.9993 4.49834 14.0291 4.20736 13.9712C3.91639 13.9133 3.64912 13.7704 3.43934 13.5607C3.22956 13.3509 3.0867 13.0836 3.02882 12.7926C2.97094 12.5017 3.00065 12.2001 3.11418 11.926C3.22771 11.6519 3.41997 11.4176 3.66664 11.2528Z"
            fill="white"
          />
          <path
            d="M10 14L12.5 12L15 14"
            stroke="#F15845"
            stroke-linecap="round"
          />
          <path
            d="M3.66664 19.2528C3.91332 19.088 4.20333 19 4.5 19C4.89783 19 5.27936 19.158 5.56066 19.4393C5.84196 19.7206 6 20.1022 6 20.5C6 20.7967 5.91203 21.0867 5.7472 21.3334C5.58238 21.58 5.34811 21.7723 5.07403 21.8858C4.79994 21.9993 4.49834 22.0291 4.20736 21.9712C3.91639 21.9133 3.64912 21.7704 3.43934 21.5607C3.22956 21.3509 3.0867 21.0836 3.02882 20.7926C2.97094 20.5017 3.00065 20.2001 3.11418 19.926C3.22771 19.6519 3.41997 19.4176 3.66664 19.2528Z"
            fill="white"
          />
          <path
            d="M11.6666 19.2528C11.9133 19.088 12.2033 19 12.5 19C12.8978 19 13.2794 19.158 13.5607 19.4393C13.842 19.7206 14 20.1022 14 20.5C14 20.7967 13.912 21.0867 13.7472 21.3334C13.5824 21.58 13.3481 21.7723 13.074 21.8858C12.7999 21.9993 12.4983 22.0291 12.2074 21.9712C11.9164 21.9133 11.6491 21.7704 11.4393 21.5607C11.2296 21.3509 11.0867 21.0836 11.0288 20.7926C10.9709 20.5017 11.0006 20.2001 11.1142 19.926C11.2277 19.6519 11.42 19.4176 11.6666 19.2528Z"
            fill="white"
          />
          <path
            d="M19.6666 19.2528C19.9133 19.088 20.2033 19 20.5 19C20.8978 19 21.2794 19.158 21.5607 19.4393C21.842 19.7206 22 20.1022 22 20.5C22 20.7967 21.912 21.0867 21.7472 21.3334C21.5824 21.58 21.3481 21.7723 21.074 21.8858C20.7999 21.9993 20.4983 22.0291 20.2074 21.9712C19.9164 21.9133 19.6491 21.7704 19.4393 21.5607C19.2296 21.3509 19.0867 21.0836 19.0288 20.7926C18.9709 20.5017 19.0006 20.2001 19.1142 19.926C19.2277 19.6519 19.42 19.4176 19.6666 19.2528Z"
            fill="white"
          />
        </svg>
      );
      break;
    case "emP":
      allSVG = (
        <svg
          width="6.313rem"
          height="6.313rem"
          viewBox="0 0 101 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50.5" cy="50.5" r="50.5" fill="#F4F4F4" />
          <mask
            id="mask0_11742_14527"
            // style="mask-type:alpha"
            style={{
              maskType: "alpha"
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="101"
            height="101"
          >
            <circle cx="50.5" cy="50.5" r="50.5" fill="#F4F4F4" />
          </mask>
          <g mask="url(#mask0_11742_14527)">
            <rect
              x="69.488"
              y="37.9761"
              width="35.9512"
              height="49.288"
              rx="6"
              fill="white"
            />
            <rect
              x="74.7068"
              y="68.4061"
              width="26.0936"
              height="3.42873"
              rx="1.71437"
              fill="#EFEFEF"
            />
            <rect
              x="74.7068"
              y="74.835"
              width="20.2951"
              height="3.42873"
              rx="1.71437"
              fill="#EFEFEF"
            />
            <rect
              x="74.7068"
              y="41.4047"
              width="26.0936"
              height="24.0011"
              rx="6"
              fill="#F8F8F8"
            />
            <path
              d="M88.7162 53.2297C90.7054 53.2297 92.318 51.6172 92.318 49.628C92.318 47.6388 90.7054 46.0262 88.7162 46.0262C86.727 46.0262 85.1145 47.6388 85.1145 49.628C85.1145 51.6172 86.727 53.2297 88.7162 53.2297Z"
              fill="white"
              stroke="#D9D9D9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M94.9038 60.4332C94.9038 57.6454 92.1305 55.3907 88.7161 55.3907C85.3016 55.3907 82.5283 57.6454 82.5283 60.4332"
              fill="white"
            />
            <path
              d="M94.9038 60.4332C94.9038 57.6454 92.1305 55.3907 88.7161 55.3907C85.3016 55.3907 82.5283 57.6454 82.5283 60.4332"
              stroke="#D9D9D9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="-4.04004"
              y="37.9761"
              width="35.9512"
              height="49.288"
              rx="6"
              fill="white"
            />
            <rect
              x="1.17871"
              y="68.4061"
              width="26.0936"
              height="3.42873"
              rx="1.71437"
              fill="#EFEFEF"
            />
            <rect
              x="1.17871"
              y="74.835"
              width="20.2951"
              height="3.42873"
              rx="1.71437"
              fill="#EFEFEF"
            />
            <rect
              x="1.17871"
              y="41.4047"
              width="26.0936"
              height="24.0011"
              rx="6"
              fill="#F8F8F8"
            />
            <path
              d="M13.1938 53.2297C15.1829 53.2297 16.7955 51.6172 16.7955 49.628C16.7955 47.6388 15.1829 46.0262 13.1938 46.0262C11.2046 46.0262 9.59204 47.6388 9.59204 49.628C9.59204 51.6172 11.2046 53.2297 13.1938 53.2297Z"
              fill="white"
              stroke="#D9D9D9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.3814 60.4332C19.3814 57.6454 16.6081 55.3907 13.1936 55.3907C9.77919 55.3907 7.00586 57.6454 7.00586 60.4332"
              fill="white"
            />
            <path
              d="M19.3814 60.4332C19.3814 57.6454 16.6081 55.3907 13.1936 55.3907C9.77919 55.3907 7.00586 57.6454 7.00586 60.4332"
              stroke="#D9D9D9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g filter="url(#filter0_d_11742_14527)">
              <rect
                x="29.896"
                y="25.0481"
                width="40.948"
                height="75.952"
                rx="6"
                fill="white"
              />
              <rect
                x="30.396"
                y="25.5481"
                width="39.948"
                height="74.952"
                rx="5.5"
                stroke="#EFEFEF"
              />
            </g>
            <rect
              x="35.8401"
              y="71.9399"
              width="29.7203"
              height="5.28362"
              rx="2.64181"
              fill="#EFEFEF"
            />
            <rect
              x="35.8401"
              y="81.8468"
              width="23.1158"
              height="5.28362"
              rx="2.64181"
              fill="#EFEFEF"
            />
            <rect
              x="35.8401"
              y="30.3315"
              width="29.7203"
              height="36.9853"
              rx="6"
              fill="#F8F8F8"
            />
            <path
              d="M51.41 49.59C54.2367 49.59 56.5282 47.2985 56.5282 44.4718C56.5282 41.645 54.2367 39.3535 51.41 39.3535C48.5833 39.3535 46.2917 41.645 46.2917 44.4718C46.2917 47.2985 48.5833 49.59 51.41 49.59Z"
              fill="white"
              stroke="#D9D9D9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M60.2032 59.8266C60.2032 55.865 56.2622 52.661 51.4101 52.661C46.558 52.661 42.6169 55.865 42.6169 59.8266"
              fill="white"
            />
            <path
              d="M60.2032 59.8266C60.2032 55.865 56.2622 52.661 51.4101 52.661C46.558 52.661 42.6169 55.865 42.6169 59.8266"
              stroke="#D9D9D9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_11742_14527"
              x="21.896"
              y="19.0481"
              width="56.948"
              height="91.952"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_11742_14527"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_11742_14527"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
      break;
    default:
      allSVG = null;
  }

  return (
    <span
      className={`inline-block ${
        className ? className : ""
      }`}
    >
      {allSVG}
    </span>
  );
};

export default AllSvg;
