import React from "react";
import Pagination from "../projects/Pagination";
import Typography from "../../../components/Typography/Typography";
import EchopulseLoader from "../../loader/EchopulseLoader";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import { useNavigate } from "react-router-dom";
import ScreenshotsEmpty from "../../Screenshots/ScreenshotsEmpty";

type Props = {
  currentPage: number;
  pageSize: number;
  teamCount: any;
  filterss: boolean;
  setFilterss: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  screenShotLists: any;
  loading: boolean;
  startDaily: string;
};

const TeamScreenshots: React.FC<Props> = ({
  currentPage,
  pageSize,
  teamCount,
  filterss,
  setFilterss,
  setCurrentPage,
  screenShotLists,
  loading,
  startDaily,
}) => {
  const navigate = useNavigate();

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleView = (identityId: string) => {
    navigate(`/teams/${identityId}/${startDaily}/screenshots`);
  };

  return (
    <div className="border border-gray-100 shadow-300 bg-white rounded-md relative h-[calc(100vh-11.55rem)]" id="adminTeamScreenshoot">
      {loading ? (
        <div className="h-[calc(100%-6.625rem)]">
          <EchopulseLoader />
        </div>
      ) : screenShotLists?.length > 0 ? (
        <>
          <div className="flex justify-center items-start h-[calc(100%-6.625rem)] overflow-auto mt-8">
            <div className="flex justify-center h-full">
              <div className="">
                <div className="w-[48.5rem] pb-10 sticky left-0 top-0 z-10 bg-white">
                  <Typography
                    size="lg"
                    variant="p"
                    className="font-normal text-black text-center"
                  >
                    Select a Team Member to View Screenshots
                  </Typography>
                </div>
                <div className="w-[48.5rem] h-[calc(100%-6.5rem)]">
                  <div className="flex flex-wrap justify-center gap-4">
                    {screenShotLists
                      ?.slice(0, 8)
                      .map((t: any, index: number) => (
                        <div
                          className={`text-center inline-block w-[25%] ${
                            t?.capturedScreenShotCount === 0
                              ? ""
                              : "cursor-pointer"
                          }`}
                        >
                          <div>
                            <div className="flex justify-center items-center w-full">
                              <div
                                className={`w-[5.125rem] h-[5.125rem] relative ${
                                  t?.capturedScreenShotCount === 0
                                    ? "opacity-50"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (t?.capturedScreenShotCount > 0) {
                                    handleView(t?.identityId);
                                  }
                                }}
                              >
                                {t?.profilePic ? (
                                  <UserImage
                                    imgKey={t.profilePic}
                                    className="w-[5.125rem] h-[5.125rem] object-cover rounded-full"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t?.name ?? "N A",
                                      24
                                    )}
                                    alt={t?.name}
                                    className="w-[5.125rem] h-[5.125rem] object-cover rounded-full"
                                  />
                                )}
                                {t?.capturedScreenShotCount !== 0 && (
                                  <span className="absolute right-1.5 bottom-0 h-5 w-5 bg-primary-100 rounded-full text-white text-[0.5rem] font-normal flex justify-center items-center">
                                    {t?.capturedScreenShotCount}
                                  </span>
                                )}
                              </div>
                            </div>
                            <Typography
                              size="lg"
                              variant="p"
                              className={`font-bold text-gray-300 mt-5 ${
                                t?.capturedScreenShotCount === 0
                                  ? "opacity-60"
                                  : ""
                              }`}
                            >
                              {t?.name}
                            </Typography>
                            <Typography
                              size="sm"
                              variant="p"
                              className={`font-normal text-gray-300 ${
                                t?.capturedScreenShotCount === 0
                                  ? "opacity-50"
                                  : ""
                              }`}
                              >
                              {t?.designation}
                            </Typography>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-10 flex justify-center items-center min-h-[6.625rem]">
            {" "}
            <Pagination
              currentPage={currentPage}
              itemsPerPage={pageSize}
              totalItems={teamCount}
              onPageChange={handlePageChange}
              setFilterss={setFilterss}
              filterss={filterss}
              pageTitle={"Employees"}
            />{" "}
          </div>
        </>
      ) : (
        <ScreenshotsEmpty type="Team" />
      )}
    </div>
  );
};

export default TeamScreenshots;
