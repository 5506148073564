import React, { useEffect, useState } from "react";
import OrganizationTimeOffFilter from "./OrganizationTimeOffFilter";
import Pagination from "../../admin/projects/Pagination";
import SearchBarMedium from "../../../components/SearchBar/SearchBarMedium";
import Icons from "../../../components/Icons/Icon";
import ProfileImg from "../../../images/Profile.png";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getTeamsAsync } from "../../../services/reducers/appReducer";
import {
  getLeaveStatisticsAsync,
  getOrganisationsOffsAsync,
} from "../../../services/reducers/leaveReducer";
import EchopulseLoader from "../../loader/EchopulseLoader";
import OrganizationTimeOffEmpty from "./OrganizationTimeOffEmpty";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import { formatDate, formatsDate } from "../../../utils/common";
import AdminTimeOffDetailsModal from "./AdminTimeOffDetailsModal";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

type TeamOption = {
  value: string;
  label: string;
};

type Props = {
  activeTab: string;
  type: string;
  getSettingList: any;
  userProfile: any;
};
const OrganizationTimeOffUpdate: React.FC<Props> = ({
  activeTab,
  type,
  getSettingList,
  userProfile,
}) => {
  const [timeOffDetails, setTimeOffDetails] = useState<boolean>(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { teamsList } = useAppSelector((state: any) => state.app);
  const [optionsTeam, setOptionsTeam] = useState<TeamOption[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [leavebalance, setLeavebalance] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState({
    label: "All",
    value: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    label: "All",
    value: "",
  });
  const [orgDetail, setOrgDetail] = useState<any>([]);
  const [timeOffApprove, setTimeOffApprove] = useState<boolean>(false);
  const [timeoffId, setTimeoffId] = useState("");
  const [timeOffCancel, setTimeOffCancel] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filterss, setFilterss] = useState(false);
  const [count, setCount] = useState<any>([]);
  const [searchString, setSearchString] = useState("");
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState<string[]>([]);
  const [startDaily, setStartDaily] = useState(null);
  const [endDaily, setEndDaily] = useState(null);
  const [filterState, setFilterState] = useState<boolean | null>(null);
  const [selectedManagerResponse, setSelectedManagerResponse] = useState<
    string[]
  >([]);
  const [selectedAdminResponse, setSelectedAdminResponse] = useState<string[]>(
    []
  );
  const [selectedFinalResponse, setSelectedFinalResponse] = useState<string[]>(
    []
  );

  useEffect(() => {
    const teamsReqBody = {};
    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item: any) => {
            return { value: item.key, label: item.value };
          });

          const allOption = {
            value: "all",
            label: "All",
          };
          const optionsWithAll = [allOption, ...teams];
          setOptionsTeam(optionsWithAll);
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item: any) => {
          return { value: item.key, label: item.value };
        });
        const allOption = {
          value: "all",
          label: "All",
        };
        const optionsWithAll = [allOption, ...teams];
        setOptionsTeam(optionsWithAll);
      }
    }
  }, []);

  useEffect(() => {
    if (activeTab === "tab2") {
      dispatch(getLeaveStatisticsAsync()).then((res) => {
        if (res.payload) {
          setLeavebalance(res.payload);
        }
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "tab2") {
      orgnisationList();
    }
  }, [
    selectedValue.value,
    searchString,
    selectedTeam,
    currentPage,
    pageSize,
    selectedFinalResponse,
    selectedLeaveTypes,
    startDaily,
    endDaily,
    filterState,
    selectedManagerResponse,
    selectedAdminResponse,
  ]);

  const leaveCodeMap: Record<string, string> = leavebalance.reduce(
    (acc: Record<string, string>, leaveType: any) => {
      acc[leaveType.leaves.title] = leaveType.leaves.leaveCode;
      return acc;
    },
    {}
  );

  const orgnisationList = () => {
    setLoading(true);
    const leaveCodes = selectedLeaveTypes.map((title) => leaveCodeMap[title]);

    const request: any = {
      LeaveCode: leaveCodes.join(","),
      Team: selectedTeam,
      ManagerStandOnLeave: selectedManagerResponse,
      AdminStandOnLeave: selectedAdminResponse,
      FinalStandOnLeave: selectedFinalResponse,
      MemberName: searchString,
      IsEmployeeActive: filterState,
      Page: currentPage,
      PageSize: pageSize,
      StartDate: startDaily && formatDate(startDaily),
      EndDate: endDaily && formatDate(endDaily),
    };
    // console.log(request, "request")
    dispatch(getOrganisationsOffsAsync(request)).then((res) => {
      setCount(res.payload.item1);
      setOrgDetail(res.payload.item2);
      setLoading(false);
      // isFetching.current = false;
    });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedValue.value, selectedTeam, searchString]);

  const handleView = (approve: boolean, cancel: boolean, id: string) => {
    setTimeoffId(id);
    setTimeOffCancel(cancel);
    setTimeOffApprove(approve);
    setTimeOffDetails(true);
  };

  const toggleFilter = () => {
    setIsFilterExpanded(!isFilterExpanded);
  };

  const navigate = useNavigate();
  const handleBtn = () => {
    navigate(`/timeoff/regular`);
  };

  // console.log(getSettingList, "getSettingList")
  return (
    <div className="ps-10 pe-5 h-full">
      <div className="border border-gray-100 shadow-300 bg-white rounded-md relative h-[calc(100vh-15rem)]">
        <OrganizationTimeOffFilter
          isExpanded={isFilterExpanded}
          toggleFilter={toggleFilter}
          leavebalance={leavebalance}
          selectedLeaveTypes={selectedLeaveTypes}
          setSelectedLeaveTypes={setSelectedLeaveTypes}
          optionsTeam={optionsTeam}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          startDaily={startDaily}
          setStartDaily={setStartDaily}
          endDaily={endDaily}
          setEndDaily={setEndDaily}
          filterState={filterState}
          setFilterState={setFilterState}
          selectedManagerResponse={selectedManagerResponse}
          setSelectedManagerResponse={setSelectedManagerResponse}
          selectedAdminResponse={selectedAdminResponse}
          setSelectedAdminResponse={setSelectedAdminResponse}
          selectedFinalResponse={selectedFinalResponse}
          setSelectedFinalResponse={setSelectedFinalResponse}
        />
        <div
          className={`flex flex-col flex-grow overflow-auto ease-in duration-300 h-full ${isFilterExpanded
              ? "w-[calc(100%-12.125rem)] ms-[12.125rem]"
              : "w-[calc(100%-4rem)] ms-16"
            }`}
        >
          {loading ? (
            <div className="[&>div>.loader-container]:h-[calc(100vh-24.35rem)] [&>div>.loader-container]:relative">
              <EchopulseLoader />
            </div>
          ) : (
            <>
              <div className="p-5 w-full bg-white rounded-tr-md">
                <div className="flex justify-between items-center">
                  <div>
                    {/* <Pagination
                currentPage={currentPage}
                itemsPerPage={pageSize}
                totalItems={count}
                onPageChange={handlePageChange}
                setFilterss={setFilterss}
                filterss={filterss}
                pageTitle="Employee"
              /> */}
                  </div>
                  <div className="flex justify-end items-center gap-4">
                    <button className="flex justify-start items-center gap-1.5 text-400 font-medium text-secondary-100" onClick={() => handleBtn()}>
                      <Icons
                        name="assign-content"
                        variant="stroke"
                        size={20}
                        className="text-secondary-100"
                      />
                      Balances and Regularization
                    </button>
                    <div className="w-[18.438rem] [&>div>input]:ps-9 [&>div>.icon-after]:top-[22px]">
                      <SearchBarMedium
                        value={searchString ? searchString : ""}
                        onChange={(e) =>
                          setSearchString && setSearchString(e.target.value)
                        }
                        placeholder="Search by employee name"
                        icon={
                          <Icons
                            name="search"
                            variant="stroke"
                            size={20}
                            className="[&>svg>path]:stroke-gray-700"
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="overflow-auto flex-grow bg-white">
                {orgDetail && orgDetail.length > 0 ? (
                  <table className="min-w-full bg-white">
                    <thead className="bg-gray-200 sticky left-0 top-0 z-10 w-full">
                      <tr>
                        <th className="px-5 py-4 text-200 uppercase text-gray-700 font-bold whitespace-nowrap text-start ps-8">
                          EMPLOYEE NAME
                        </th>
                        <th className="px-5 py-4 text-200 uppercase text-gray-700 font-bold whitespace-nowrap text-start">
                          LEAVE PERIOD
                        </th>
                        <th className="px-5 py-4 text-200 uppercase text-gray-700 font-bold whitespace-nowrap text-start">
                          LEAVE TYPE
                        </th>
                        <th className="px-5 py-4 text-200 uppercase text-gray-700 font-bold whitespace-nowrap text-start">
                          LEAVE STATUS
                        </th>
                        <th className="px-5 py-4 text-200 uppercase text-gray-700 font-bold whitespace-nowrap text-start">
                          MANAGER RESPONSE
                        </th>
                        <th className="px-5 py-4 text-200 uppercase text-gray-700 font-bold whitespace-nowrap text-start">
                          ADMINISTRATOR RESPONSE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orgDetail &&
                        orgDetail.map((t: any) => (
                          <tr
                            className="hover:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              if (
                                getSettingList[0]?.timeOffApproval === 2 ||
                                getSettingList[0]?.timeOffApproval === 0
                              ) {
                                handleView(
                                  t?.isAdminApproved,
                                  t?.isLeaveCancelled,
                                  t?.timeOffId
                                );
                              } else {
                                handleView(
                                  t?.isRMApproved,
                                  t?.isLeaveCancelled,
                                  t?.timeOffId
                                );
                              }
                            }}
                          >
                            <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap ps-8">
                              <div className="flex justify-start items-center gap-2.5">
                                {t?.memberProfile?.profilePic ? (
                                  <UserImage
                                    imgKey={t?.memberProfile?.profilePic}
                                    className="w-8 h-8 rounded-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t?.memberProfile?.name ?? "N A",
                                      24
                                    )}
                                    alt={t?.memberProfile?.name}
                                    className="w-8 h-8 rounded-full object-cover"
                                  />
                                )}
                                <div className="max-w-[17rem]">
                                  <div className="truncate"> {t?.memberProfile?.name}</div>
                                  <div className="text-200 text-gray-700 truncate">
                                    {t?.memberProfile?.designation} |{" "}
                                    {t?.memberProfile?.team}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                              {formatsDate(t?.startAt)} -{" "}
                              {formatsDate(t?.endAt)}
                            </td>
                            <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                              {t?.leave?.title}
                            </td>
                            {t?.overAllStatus === "Cancelled" ? (
                              <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                                <div className="flex justify-center items-center text-200 text-secondary-300 bg-pastel-600 capitalize rounded-full w-[6.563rem] h-6">
                                  <span className="me-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                                        fill="#ECB22E"
                                      />
                                    </svg>
                                  </span>
                                  Cancelled
                                </div>
                              </td>
                            ) : t?.overAllStatus === "Approved" ? (
                              <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                                <div className="flex justify-center items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize rounded-full w-[6.563rem] h-6">
                                  <span className="me-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16Z"
                                        fill="#2EB67D"
                                      />
                                      <path
                                        d="M4.60025 8.00033L6.86425 10.2643L11.4003 5.73633"
                                        fill="#2EB67D"
                                      />
                                      <path
                                        d="M4.60025 8.00033L6.86425 10.2643L11.4003 5.73633"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  Approved
                                </div>
                              </td>
                            ) : t?.overAllStatus === "Pending" ? (
                              <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                                <div className="flex justify-center items-center text-200 text-gray-700 bg-gray-100 capitalize rounded-full w-[6.563rem] h-6">
                                  <span className="me-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M15.9949 13.8478C15.9742 13.6153 15.8932 13.3734 15.7516 13.1288L9.14024 1.67739C8.99942 1.43272 8.83087 1.24142 8.63955 1.107C8.44183 0.96902 8.22063 0.896484 8.00016 0.896484C7.77969 0.896484 7.55849 0.969029 7.36078 1.107C7.16946 1.24071 7.0009 1.43274 6.86009 1.67739L0.247964 13.1288C0.107143 13.3735 0.0253453 13.6153 0.00473477 13.8478C-0.0158903 14.0875 0.0310496 14.3158 0.142001 14.5071C0.25224 14.6985 0.425778 14.8535 0.644118 14.9552C0.855358 15.0541 1.10571 15.1038 1.38805 15.1038H14.611C14.8934 15.1038 15.1437 15.0541 15.3549 14.9552C15.5733 14.8535 15.7468 14.6984 15.8571 14.5071C15.9687 14.3158 16.0162 14.0875 15.9949 13.8478ZM8.00009 13.0406C7.55558 13.0406 7.19499 12.68 7.19499 12.2355C7.19499 11.791 7.55558 11.4311 8.00009 11.4311C8.4446 11.4311 8.80519 11.791 8.80519 12.2355C8.80519 12.68 8.4446 13.0406 8.00009 13.0406ZM8.96735 5.87298L8.70917 10.136C8.69423 10.392 8.47091 10.6019 8.21415 10.6019H7.78457C7.52781 10.6019 7.30521 10.3921 7.28956 10.136L7.03138 5.87298C7.01573 5.61695 7.21274 5.40713 7.46948 5.40713H8.52706C8.78524 5.40784 8.98299 5.61693 8.96735 5.87298Z"
                                        fill="#888888"
                                      />
                                    </svg>
                                  </span>
                                  {t?.overAllStatus}
                                </div>
                              </td>
                            ) : (
                              t?.overAllStatus === "Rejected" && (
                                <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                                  <div className="flex justify-center items-center text-200 capitalize font-normal text-primary-100 rounded-full bg-primary-100 bg-opacity-10 w-[6.563rem] h-6">
                                    <span className="me-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="16"
                                        viewBox="0 0 14 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                                          fill="#F15845"
                                        />
                                      </svg>
                                    </span>
                                    Denied
                                  </div>
                                </td>
                              )
                            )}
                            <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                              {t?.rmStatus}
                            </td>
                            <td className="px-5 py-4 text-300 text-gray-300 font-normal text-start whitespace-nowrap">
                              {t?.adminStatus}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <OrganizationTimeOffEmpty />
                )}
              </div>
            </>
          )}
          <div className="bg-white py-3 ps-8 pe-4 rounded-b-md">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={pageSize}
              totalItems={count}
              onPageChange={handlePageChange}
              setFilterss={setFilterss}
              filterss={filterss}
              pageTitle="Employee"
            />
          </div>
        </div>
        {timeOffDetails && (
          <AdminTimeOffDetailsModal
            onClose={() => setTimeOffDetails(false)}
            timeoffId={timeoffId}
            timeOffApprove={timeOffApprove}
            setTimeOffDetails={setTimeOffDetails}
            orgnisationList={orgnisationList}
            timeOffCancel={timeOffCancel}
            type={type}
            userProfile={userProfile}
            getSettingList={getSettingList}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationTimeOffUpdate;
