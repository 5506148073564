import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/Modal";
import { ModalFooter } from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import RadioButton from "../../../components/Radio button/RadioButton";
import MultiSelectDropdown from "../../../components/MultiSelectWithCheckbox/MultiSelect";
import { SearchMember } from "./SearchMember";
import Icons from "../../../components/Icons/Icon";
import { Dropdown } from "../../../components/DropDown/DropDown";
import Textarea from "../../../components/TextArea/Textarea";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { getUsersAsync } from "../../../services/reducers/appReducer";
import { useAppDispatch } from "../../../hooks";
import {
  checkUniquenessProjectAsync,
  createProjectAsync,
  keySuggesstionAsync,
} from "../../../services/reducers/adminReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import styled from "styled-components";
import { useDebounce } from "../../../utils/hooks/useDebounce";

export const CustomRightToolTip = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
    z-index: 9;
    cursor: default;
    width: 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 15rem;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    color: #232323;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    z-index: 1;
    top: -0.313rem;
    left: 104%;
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type Values = {
  key: string;
  name: string;
  externalId: string;
  description: string;
  privacy: string;
  taskManagement: string;
  teamMembers: { memberId: string }[];
  projectType: number;
  platform: number;
};
const ProjectSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  key: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

type Props = {
  onClose: () => void;
  userList: any;
  projectList: () => void;
  setAddProject: React.Dispatch<React.SetStateAction<boolean>>;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
};

const AddProjectModal: React.FC<Props> = ({
  onClose,
  userList,
  setAddProject,
  projectList,
  searchString,
  setSearchString,
}) => {
  const dispatch = useAppDispatch();
  const [selectedMember, setSelectedMember] = useState<any>([]);
  const [isUniqueTitle, setIsUniqueTitle] = useState(false);
  const [isUniqueKey, setIsUniqueKey] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [suggestedKey, setSuggestedKey] = useState<string | null>(null);
  const [userLists, setUserLists] = useState<any>(null);
  const [showAll, setShowAll] = useState(false);
  const isFetching = useRef(false);
  
  const projectTypeOpt = [
    {
      label: "Internal Project",
      value: 0,
    },
    {
      label: "Client Project",
      value: 1,
    },
  ];

  const search = () => {
    if (isFetching.current) return; 

    isFetching.current = true;
    const request = {
      page: 1,
      pageSize: 2000,
      orderBy: "Name",
      isOrderByDesc: false,
      filters: {
        statusIds: [1]
      },
    };
    dispatch(getUsersAsync(request)).then((res) => {
      // setUserLists(res?.payload?.users);
      const activeUsers = res?.payload?.users?.filter((user:any) => user.status === "Active");
      setUserLists(activeUsers);
      isFetching.current = false;
    });
  };

  useEffect(() => {
    search();
  }, []);

  const removeSelectedMember = (memberIdToRemove: any) => {
    setSelectedMember((prevSelectedMembers: any) =>
      prevSelectedMembers.filter(
        (member: any) => member.id !== memberIdToRemove
      )
    );
  };

  const validateTitle = async (title: string) => {
    if (title.trim() !== "") {
      const jsonData = {
        propertyName: "name",
        checkValue: title,
      };
      await dispatch(checkUniquenessProjectAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setTitleFocused(false);
      });
    }
  };
  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const validateKey = async (key: string) => {
    const jsonData = {
      propertyName: "key",
      checkValue: key,
    };

    await dispatch(checkUniquenessProjectAsync(jsonData)).then((res) => {
      setIsUniqueKey(res?.payload ? true : false);
      setTitleFocused(false);
    });
  };

  const debouncedValidateKey = useDebounce(validateKey, 1000);

  const keySuggestion = (title: string, setFieldValue: any) => {
    if (!isUniqueTitle) {
      dispatch(keySuggesstionAsync(title)).then((res) => {
        const test = res?.payload || null;
        setSuggestedKey(test);
        setFieldValue("key", test);
      });
    }
  };

  const toggleShowAllMembers = () => {
    setShowAll(!showAll);
  };
  
  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <Formik
        enableReinitialize
        initialValues={{
          key: "",
          name: "",
          externalId: "",
          description: "",
          privacy: "1",
          taskManagement: "0",
          teamMembers: [
            {
              memberId: "",
            },
          ],
          projectType: 0,
          platform: 2,
        }}
        validationSchema={ProjectSchema}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          let request: any = values;

          const taskManagementValue = values.taskManagement === "0" ? 0 : 1;
          const privacyValue = values.privacy === "0" ? 0 : 1;

          const members = values.privacy === "0" ? selectedMember : userLists;
          const test = members.map((member: any) => ({
            memberId: member.identityId,
          }));

          request = {
            ...request,
            externalId: "",
            privacy: privacyValue,
            // taskManagement: taskManagementValue,
            teamMembers: test,
            platform: 2,
          };
          const result = await dispatch(createProjectAsync(request));

          projectList();
          setAddProject(false);
          toast.success(`Project Created Successfully!`, TOASTIFY_CONF);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          isSubmitting,
          setFieldTouched,
          submitForm,
          isValid,
          dirty,
        }) => {
          return (
            <Form className="w-full flex flex-col h-full">
              <ModalHeader className="bg-gray-200">
                <Typography className="font-bold" variant="h3">
                  Create New Project
                </Typography>
              </ModalHeader>
              <ModalBody className="px-20 py-10 pe-2">
                <div className="grid grid-cols-2 gap-0">
                  <div>
                    <div className="w-[25.313rem]">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Project Title{" "}
                        <span className="text-primary-100">*</span>
                      </label>
                      <Field
                        name="name"
                        render={({ field }: any) => (
                          <>
                            <TextInput
                              {...field}
                              onChange={(e: any) => {
                                const inputValue = e.target.value;
                                const regex = /^[a-zA-Z0-9._\s-]*$/;
                                if (regex.test(inputValue) || inputValue === "") {
                                  setFieldValue("name", inputValue);
                                  debouncedValidateTitle(inputValue);
                                }
                                // setFieldValue("name", e.target.value);
                                // debouncedValidateTitle(e.target.value);
                              }}
                              onBlur={() => {
                                setFieldTouched("name", true);
                                validateTitle(values.name);
                                keySuggestion(values.name, setFieldValue);
                              }}
                              placeholder="Enter Project Title Here"
                              value={field.value}
                              type="text"
                            />
                            {isUniqueTitle === true && (
                              <div className="text-primary-100 text-200 font-normal mt-1">
                                {"Project name already exists!"}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="w-[25.313rem] mt-8">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Project Type
                      </label>
                      <Field
                        name="projectType"
                        render={({ field }: any) => (
                          <>
                            <Dropdown
                              {...field}
                              onChange={(e: any) =>
                                setFieldValue("projectType", e.value)
                              }
                              placeholder="Select project type"
                              options={projectTypeOpt}
                              type="box"
                              value={
                                projectTypeOpt
                                  ? projectTypeOpt.find(
                                      (option) =>
                                        option.value === values.projectType
                                    )
                                  : null
                              }
                            />
                            {errors.projectType && touched.projectType && (
                              <div className="text-primary-100 text-200 font-normal mt-1">
                                {errors.projectType}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="w-[25.313rem] [&>div>textarea]:h-[5.313rem] mt-8">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Project Description{" "}
                        <span className="text-primary-100">*</span>
                      </label>
                      <Field
                        name="description"
                        render={({ field }: any) => (
                          <>
                            <Textarea
                              {...field}
                              onChange={(value: string) => {
                                setFieldValue("description", value);
                              }}
                              placeholder="Briefly Describe the Project Objectives, Scope, and Key Deliverables"
                              value={values.description}
                              maxCharacters={250}
                              counter={true}
                              resize
                            />

                            {errors.description && touched.description && (
                              <div className="text-primary-100 text-200 font-normal mt-1">
                                {errors.description}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="relative w-[25.313rem] mt-6">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Project Key <span className="text-primary-100">*</span>
                      </label>
                      <CustomRightToolTip>
                        <div className="tooltip">
                          <div className="w-full">
                            <Field
                              name="key"
                              render={({ field }: any) => (
                                <>
                                  <TextInput
                                    {...field}
                                    onBlur={field.onBlur}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const inputValue = e.target.value;
                                      const sanitizedValue = inputValue
                                        .replace(/[^a-zA-Z0-9]/g, "")
                                        .toUpperCase()
                                        .slice(0, 5);
                                      field.onChange({
                                        target: {
                                          name: field.name,
                                          value: sanitizedValue,
                                        },
                                      });

                                      debouncedValidateKey(inputValue);
                                    }}
                                    placeholder="Auto-Generated or Enter Manually"
                                    type="text"
                                  />
                                  {isUniqueKey === true && (
                                    <div className="text-primary-100 text-200 font-normal mt-1">
                                      {"Project key already exists!"}
                                    </div>
                                  )}
                                  {errors.key && touched.key && (
                                    <div className="text-primary-100 text-200 font-normal mt-1">
                                      {errors.key}
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <span className="tooltiptext">
                            Project key will be the prefix to the task ID. For
                            example ES-1, CG-10. Special characters are not allowed.
                          </span>
                        </div>
                      </CustomRightToolTip>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                      Accessible to
                      </label>
                      <div className="flex justify-start items-center gap-4">
                      <Field
                          type="radio"
                          name="privacy"
                          value="1"
                          render={({ field }: any) => (
                            <RadioButton
                              label="
                              All"
                              {...field}
                              onChange={(value: string) =>
                                setFieldValue("privacy", value)
                              }
                              selectedValue={values.privacy}
                            />
                          )}
                        />
                        <Field
                          type="radio"
                          name="privacy"
                          value="0"
                          render={({ field }: any) => (
                            <RadioButton
                              label="Selected users"
                              {...field}
                              onChange={(value: string) =>
                                setFieldValue("privacy", value)
                              }
                              selectedValue={values.privacy}
                            />
                          )}
                        />
                      
                      </div>
                    </div>
                    {values.privacy === "0" && (
                      <div className="mt-8">
                        <div className="w-[25.313rem]">
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Add Members
                          </label>
                          <Field
                            name="teamMembers"
                            render={({ field }: any) => (
                              <>
                                <SearchMember
                                  userList={userList}
                                  setSelectedMember={setSelectedMember}
                                  selectedMember={selectedMember}
                                  searchString={searchString}
                                  setSearchString={setSearchString}
                                />
                              </>
                            )}
                          />

                          <div className="inline-block">
                            {selectedMember.map((t: any, index: number) => (
                              <div
                                key={t.id}
                                className={`inline-block ${
                                  index >= 4 && !showAll ? "hidden" : ""
                                }`}
                              >
                                <div className="flex items-center px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
                                  <span className="me-1.5">{t?.name}</span>
                                  <button
                                    onClick={() => removeSelectedMember(t.id)}
                                  >
                                    <Icons
                                      name="cross"
                                      variant="stroke"
                                      size={10}
                                    />
                                  </button>
                                </div>
                              </div>
                            ))}
                            {selectedMember.length > 4 && (
                              <div
                                className="inline-block text-center cursor-pointer px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5"
                                onClick={toggleShowAllMembers}
                              >
                                {showAll
                                  ? "Collapse view"
                                  : `+${selectedMember.length - 4} others`}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="flex justify-between items-center w-full">
                  <div className="flex justify-start gap-5">
                    <Button
                      label="Create"
                      variant="primary"
                      size="large"
                      isDisabled={
                        isSubmitting ||
                        !isValid ||
                        !dirty ||
                        isUniqueKey === true ||
                        isUniqueTitle === true ||
                        !values.key.trim()
                      }
                    />
                    <Button
                      label="Cancel"
                      onClick={() => setAddProject(false)}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </div>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddProjectModal;
