import React, { useEffect } from "react";
import EmployeeTimeOff from "./EmployeeTimeOff/EmployeeTimeOff";
import AdminTimeOff from "./AdminTimeOff/AdminTimeOff";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUserProfileAsync } from "../../services/reducers/appReducer";

const TimeOff = () => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state: any) => state.app.userProfile);

  useEffect(() => {
    dispatch(getUserProfileAsync());
  }, []);

  return (
    <div id="adminTimeOffMain">
      <AdminTimeOff />
    </div>
  );
};

export default TimeOff;
