import React, { useState, useRef, useEffect } from "react";
import defaultImage from "../../../images/default.png";
import styled from "styled-components";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import {
  getReporteesAsync,
  getUsersAsync,
} from "../../../services/reducers/appReducer";
import { useAppDispatch } from "../../../hooks";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const StyledInput = styled.div`
  .searchmember-input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M8.62488 15.75C12.5599 15.75 15.7499 12.56 15.7499 8.625C15.7499 4.68997 12.5599 1.5 8.62488 1.5C4.68985 1.5 1.49988 4.68997 1.49988 8.625C1.49988 12.56 4.68985 15.75 8.62488 15.75Z' stroke='%23888888' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.4999 16.5L14.9999 15' stroke='%23888888' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 4%;
    background-position-y: center;
    background-size: 16px;
  }
`;

type Props = {
  userList?: any;
  setSelectedMember?: any;
  selectedMember?: any;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  identityId?: string;
  setUserList: any;
};

const SingleMemberDropdownAll: React.FC<Props> = ({
  userList,
  setSelectedMember,
  selectedMember,
  searchString,
  setSearchString,
  identityId,
  setUserList,
}) => {
  const [openAreaVisible, setOpenAreaVisible] = useState(false);
  const openAreaRef = useRef<HTMLDivElement>(null);
  const [filteredUserList, setFilteredUserList] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  const [openText, setOpenText] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const search = () => {
    setIsTaskLoading(true);
    const request = {
      page: 1,
      pageSize: 2000,
      identityId: identityId,
      searchString: searchString,
    };
    dispatch(getReporteesAsync(request)).then((res) => {
      setIsTaskLoading(false);
      setUserList(res?.payload?.users);
    });
  };

  const debouncedUserSearch = useDebounce(search, 1000);

  useEffect(() => {
    debouncedUserSearch();
  }, [searchString, identityId]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      openAreaRef.current &&
      !openAreaRef.current.contains(event.target as HTMLElement)
    ) {
      setOpenAreaVisible(false);
      setOpenText(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutsides = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (userList) {
      const filteredList = userList.filter((user: any) => {
        const userLowerCaseName = user.name.toLowerCase();
        const searchStringLowerCase = (searchString || "").toLowerCase();
        return userLowerCaseName.includes(searchStringLowerCase);
      });
      setFilteredUserList(filteredList);
    }
  }, [searchString, userList]);

  const toggleDropdown = () => {
    if (searchInput.trim() !== "") {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(!isDropdownVisible);
    }
  };

  return (
    <div className="flex flex-col relative">
      {!selectedMember ? (
        <>
          <div ref={dropdownRef}>
            <div className="">
              <StyledInput>
                <input
                  type="text"
                  placeholder="Search for a Member"
                  className="outline-none w-full bg-white border border-gray-100 ps-[2.25rem] pe-4 py-[9px] text-400 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
                         focus:border-gray-900
                         disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100 disabled:text-gray-700 searchmember-input"
                  onChange={(e) =>
                    setSearchString && setSearchString(e.target.value)
                  }
                  onFocus={toggleDropdown}
                />
              </StyledInput>
            </div>
            {isDropdownVisible &&
              (isTaskLoading ? (
                <div className="absolute left-0 top-[46px] bg-white border-gray-400 shadow-100 rounded-md z-20 w-full border flex justify-start items-center px-4 py-2.5 text-400 text-gray-300 gap-2.5 min-h-[3.5rem]">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="#D9D9D9"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="#4A154B"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>{" "}
                  <span className="">Loading...</span>
                </div>
              ) : (
                <div className="absolute max-h-[14rem] overflow-auto  left-0 top-[46px] bg-white border-gray-400 shadow-100 rounded-md z-20 w-full border min-h-[3.5rem]">
                  <ul>
                    {filteredUserList.length === 0 ? (
                      <li className="flex justify-center items-center text-400 font-semibold text-gray-300 py-2.5 px-4">
                        No users found
                      </li>
                    ) : (
                      filteredUserList.map((t: any) => (
                        <li key={t.id}>
                          <button
                            className={`flex justify-start items-center gap-2.5 px-4 py-2.5 w-full ${
                              selectedMember && selectedMember.id === t.id
                                ? "bg-gray-200"
                                : ""
                            } hover:bg-gray-200`}
                            onClick={() => {
                              setSelectedMember(t);
                              setOpenAreaVisible(false);
                            }}
                          >
                            <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-600 flex justify-center items-center text-primary-100 text-200 font-bold">
                              <span>
                                {t?.img ? (
                                  <UserImage
                                    imgKey={t?.img}
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t?.name ?? "N A",
                                      24
                                    )}
                                    alt={t?.name}
                                  />
                                )}
                              </span>
                            </div>
                            <div>
                              <div className="text-400 font-medium text-gray-300 text-left">
                                {t?.name}
                              </div>
                              <div className="text-200 font-normal text-gray-300 text-left">
                                {t?.title ?? t?.designation} | {t?.team}
                              </div>
                            </div>
                          </button>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              ))}
          </div>
        </>
      ) : (
        <div className="flex justify-start items-center gap-1.5 w-full">
          <span
            className="cursor-pointer p-1"
            onClick={() => {
              setOpenAreaVisible(!openAreaVisible);
              setOpenText(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5202 0.301551C11.325 0.121705 11.0084 0.121705 10.8131 0.301551L5.83335 4.88819L0.853574 0.301551C0.658312 0.121705 0.341728 0.121705 0.146466 0.301551C-0.0487957 0.481398 -0.0487957 0.772988 0.146466 0.952834L5.4798 5.86511C5.57357 5.95148 5.70074 6 5.83335 6C5.96596 6 6.09314 5.95148 6.18691 5.86511L11.5202 0.952834C11.7155 0.772988 11.7155 0.481398 11.5202 0.301551Z"
                fill="#232323"
              />
            </svg>
          </span>
          <div className="w-8 h-8 rounded-full overflow-hidden">
            {selectedMember.img ? (
              <UserImage
                imgKey={selectedMember.img}
                className="w-full h-full object-cover"
              />
            ) : (
              <img
                src={getInitialsInName(selectedMember.name ?? "N A", 24)}
                alt={selectedMember.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
          <div>
            <div className="text-400 font-medium text-gray-300">
              {selectedMember.name}
            </div>
            <div className="text-200 font-normal text-gray-300">
              {selectedMember.title ?? selectedMember.designation} |{" "}
              {selectedMember.team}
            </div>
          </div>
        </div>
      )}

      {openAreaVisible && (
        <div
          ref={openAreaRef}
          className="absolute left-0 top-[46px] bg-white border-gray-400 shadow-100 py-6 rounded-md z-20 w-full border"
        >
          <div className="px-4">
            <StyledInput>
              <input
                type="text"
                placeholder="Search for a Member"
                className="outline-none w-full bg-white border border-gray-100 ps-[2.25rem] pe-4 py-[9px] text-400 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
                             focus:border-gray-900
                             disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100 disabled:text-gray-700 searchmember-input"
                onChange={(e) =>
                  setSearchString && setSearchString(e.target.value)
                }
                onBlur={() => setSearchString && setSearchString("")}
              />
            </StyledInput>
          </div>
          <div className="pt-2 min-h-[3.25rem] max-h-[18rem] overflow-auto">
            <ul>
              {filteredUserList.length === 0 ? (
                <li className="flex justify-center items-center gap-2.5 px-4 py-2.5">
                  No users found
                </li>
              ) : (
                filteredUserList.map((t: any) => (
                  <li key={t.id}>
                    <button
                      className={`flex justify-start items-center gap-2.5 px-4 py-2.5 w-full ${
                        selectedMember && selectedMember.id === t.id
                          ? "bg-gray-200"
                          : ""
                      } hover:bg-gray-200`}
                      onClick={() => {
                        setSelectedMember(t);
                        setOpenAreaVisible(false);
                      }}
                    >
                      <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-600 flex justify-center items-center text-primary-100 text-200 font-bold">
                        <span>
                          {t?.img ? (
                            <UserImage
                              imgKey={t?.img}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <img
                              src={getInitialsInName(t?.name ?? "N A", 24)}
                              alt={t?.name}
                            />
                          )}
                        </span>
                      </div>
                      <div>
                        <div className="text-400 font-medium text-gray-300 text-left">
                          {t?.name}
                        </div>
                        <div className="text-200 font-normal text-gray-300">
                          {t?.title ?? t?.designation} | {t?.team}
                        </div>
                      </div>
                    </button>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleMemberDropdownAll;
