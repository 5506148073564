import React, { useEffect, useState, useRef } from "react";
import SideNavLogoOpen from "../../images/SideNavLogoOpen.svg";
import Icons from "../Icons/Icon";
import { Link, NavLink } from "react-router-dom";
import Button from "../Button/Button";
import { useAppDispatch } from "../../hooks";
import {
  createTimeCardAsync,
  getTimecardListAsync,
  updateTimesCardAsync,
} from "../../services/reducers/timeReducer";
import moment from "moment-timezone";
import styled from "styled-components";
import AllSvg from "../AllSvg/AllSvg";

export const EchoPulseMenuActive = styled.a`
  .active {
    background: #ffffff29;
  }
`;

interface NavItemProps {
  icon: string;
  isActive: boolean;
  label: string;
  link: string;
  onClick: () => void;
  hidden?: boolean;
  isOpen: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  isActive,
  label,
  link,
  onClick,
  isOpen = false,
}) => {
  return (
    <div className={isActive ? "" : ""}>
      <EchoPulseMenuActive>
        <NavLink
          to={link ?? ""}
          onClick={onClick}
          className={`cursor-pointer flex gap-3 items-center rounded-md w-full min-w-[0.625rem] px-[0.625rem] hover:bg-white hover:bg-opacity-5
          }`}
        >
          {icon && (
            <span className="h-10 inline-flex items-center justify-center">
              <Icons
                name={icon as any}
                variant="sidebar"
                color={isActive ? "#fff" : "#D9D9D9"}
                size={20}
              />
            </span>
          )}
          {label && (
            <span
              className={` group-hover:inline-block whitespace-nowrap overflow-hidden text-ellipsis ${
                isActive ? "text-white" : ""
              }`}
            >
              {label}
            </span>
          )}
        </NavLink>
      </EchoPulseMenuActive>
    </div>
  );
};

export interface MenuProps {
  navigationItems: {
    icon: string;
    label: string;
    link: string;
    sublinks?: {
      icon: string;
      label: string;
      link: string;
    }[];
  }[];
  isOpen: boolean;
  setIsOpen: any;
}

const Menu: React.FC<MenuProps> = ({
  navigationItems,
  isOpen = false,
  setIsOpen = "",
}) => {
  const [activeItem, setActiveItem] = useState<string>("Dashboard");
  const [clockLabel, setClockLabel] = useState<string>("Clock-In");
  const [list, setList] = useState<any>([]);
  const [clockIn, setClockIn] = useState<any>();
  const [clockOut, setClockOut] = useState<any>();
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const today = new Date().toISOString().split("T")[0];
  const todayList = list.filter(
    (item: any) => item.date.split("T")[0] === today
  );
  const isFetching = useRef(false);

  useEffect(() => {
    getTimeList();
  }, []);

  const handleItemClick = (label: string) => {
    setActiveItem(label);
  };

  const toggleClockLabel = () => {
    connectTime();
  };

  const connectTime = () => {
    dispatch(createTimeCardAsync()).then((res) => {
      if (res.payload) {
        getTimeList();
      }
    });
  };

  const getTimeList = () => {
    setIsTaskLoading(true);
    if (isFetching.current) return;

    isFetching.current = true;
    dispatch(getTimecardListAsync()).then((res) => {
      if (res?.payload) {
        const status = res.payload?.records[0]?.status?.toLowerCase();
        setClockLabel(status === "true" ? "Clock-Out" : "Clock-In");
        setList(res.payload.records);
        setIsTaskLoading(false);
        isFetching.current = false;
      }
    });
  };

  useEffect(() => {
    if (todayList?.length > 0) {
      const status = todayList[0]?.status?.toLowerCase();
      setClockLabel(status === "true" ? "Clock-Out" : "Clock-In");
      setClockIn(todayList[0]?.checkIn);
      setClockOut(todayList[0]?.checkOut);
    } else {
      setClockLabel("Clock-In");
      setClockIn(undefined);
      setClockOut(undefined);
    }
  }, [list, todayList]);

  const formatTime = (originalTime: string | undefined) => {
    if (!originalTime) {
      return "";
    }

    const formattedTime = moment(originalTime, "HH:mm:ss.SSSSSSS")
      .tz("Asia/Kolkata")
      .format("h:mm A");

    const newTime = moment(formattedTime, "h:mm A").add({
      hours: 5,
      minutes: 30,
    });

    return newTime.format("h:mm a");
  };

  const formatClockIn = formatTime(clockIn);
  const formatClockOut = formatTime(clockOut);

  return (
    <div className="fixed h-full z-[999] top-0 left-0">
      <div
        className={`z-50 h-full flex flex-col py-5 
         bg-gray-300 text-gray-100 text-400 transition-all ease-in-out duration-500
           group ${
             isOpen
               ? `w-56`
               : `fixed top-0 left-0 bottom-0 w-[4.5rem] hover:w-56`
           }`}
      >
        <div className="px-4">
          <img
            src={SideNavLogoOpen}
            alt="logo"
            className="w-auto max-w-full h-10 object-cover object-left-top"
          />
        </div>
        <div className="flex flex-col gap-2 mt-[3.063rem] overflow-auto px-4">
          {navigationItems.map(({ icon, label, link }) => (
            <NavItem
              key={label}
              isActive={activeItem === label}
              icon={icon}
              label={label}
              link={link}
              isOpen={isOpen}
              onClick={() => handleItemClick(label)}
            />
          ))}
        </div>
        <div className="px-4 mt-8">
          <div className="border-t-2 border-gray-400 pt-8">
            <Button
              className={`w-full transition-all ease-in-out flex justify-center items-center whitespace-nowrap !px-0 gap-2 overflow-hidden !py-2 ${
                isOpen
                  ? `w-full [&>span]:inline-block [&>span]:transition-all [&>span]:ease-in-out [&>span]:duration-200`
                  : `[&>span]:hidden w-[4.5rem] group-hover:w-full group-hover:[&>span]:inline-block transition-all ease-in-out duration-300`
              }`}
              label={clockLabel}
              onClick={() => {
                toggleClockLabel();
              }}
              variant={
                clockLabel === "Clock-In" ? "tertiary" : "tertiaryYellow"
              }
              size="extramedium"
              isLoader={isTaskLoading}
              icon={
                clockLabel === "Clock-In" ? (
                  <Icons
                    name="white-circle-tick"
                    variant="stroke"
                    size={24}
                    className="-mb-[7px]"
                  />
                ) : (
                  <Icons
                    name="white-circle-cross"
                    variant="stroke"
                    size={24}
                    className="-mb-[7px]"
                  />
                )
              }
            />

            {todayList && todayList?.length > 0 ? (
              <p
                className={`text-300 text-gray-700 font-medium mt-3 text-center transition-all ease-in-out duration-200 whitespace-nowrap overflow-hidden  ${
                  isOpen
                    ? `inline-flex w-full`
                    : `hidden w-[4.5rem] group-hover:inline-flex group-hover:w-full`
                }`}
              >
                {" "}
                You've clocked {clockLabel === "Clock-In"
                  ? "out"
                  : "in"} at{" "}
                {clockLabel === "Clock-In" ? formatClockOut : formatClockIn}
              </p>
            ) : (
              <p
                className={`text-300 text-gray-700 font-medium mt-3 text-center transition-all ease-in-out duration-200 whitespace-nowrap overflow-hidden  ${
                  isOpen
                    ? `inline-flex w-full`
                    : `hidden w-[4.5rem] group-hover:inline-flex group-hover:w-full`
                }`}
              >
                You haven’t clocked in today
              </p>
            )}
          </div>
        </div>
      </div>
      {!isOpen ? (
        <button
          className="bg-w absolute left-[4.5rem] p-[.75rem] top-1/2"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <svg
            width="0.625rem"
            height="1.125rem"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.02598 0.875713C0.769061 1.15466 0.769061 1.60692 1.02598 1.88587L7.57833 8.99984L1.02598 16.1138C0.769061 16.3928 0.769061 16.845 1.02598 17.124C1.28291 17.4029 1.69947 17.4029 1.95639 17.124L8.97393 9.50491C9.09731 9.37096 9.16663 9.18928 9.16663 8.99984C9.16663 8.8104 9.09731 8.62872 8.97393 8.49476L1.95639 0.875713C1.69947 0.596767 1.28291 0.596767 1.02598 0.875713Z"
              fill="#646464"
            />
          </svg>
        </button>
      ) : (
        <button
          className="bg-w absolute left-56 p-[.75rem] top-1/2 -translate-y-1/2"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <svg
            width="0.125rem"
            height="2rem"
            viewBox="0 0 2 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="2" height="32" rx="1" fill="#888888" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Menu;
