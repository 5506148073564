import React, { ChangeEvent, ReactElement } from "react";
// import TextInput from "../TextInput/TextInput";

export type InputWithIconProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: ReactElement;
  //   className?: string;
  placeholder?: string;
  onBlur?: () => void;
};

const SearchBarSmall: React.FC<InputWithIconProps> = ({
  value,
  onChange,
  icon,
  //   className,
  placeholder = "Search",
  onBlur,
}) => {
  return (
    <div className="relative">
      <input
        onChange={onChange}
        placeholder={placeholder} // Use the provided placeholder
        value={value}
        onBlur={onBlur}
        className="outline-none w-full bg-white border border-gray-100 ps-8 pe-4 py-[9px] text-300 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
        focus:border-gray-900 focus:shadow-200
        disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100 disabled:text-gray-700"
      />
      {icon && (
        <div className="icon-after absolute left-3 top-1/2 -translate-y-1/2">
          {icon}
        </div>
      )}
    </div>
  );
};

export default SearchBarSmall;
