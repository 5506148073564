import React, { useState, useEffect } from "react";
import AccountImg from "../../assets/images/Account.png";
import DefaultUserImage from '../../images/myProfile.png'
import { generatePresignedUrl } from "../../services/api/commonApis";
import { getGUID } from "../../utils/common";

type Props = {
  imgKey: string;
  className?: string;
};

const UserImage: React.FC<Props> = ({ imgKey, className }) => {
  const [imgUrl, setImgUrl] = useState(DefaultUserImage);
  const [formDataReady, setFormDataReady] = useState(false);

  useEffect(() => {
    getImgUrl();
  }, [imgKey]);

  const getImgUrl = () => {
    // console.log(imgKey,'imgKey');
    if (imgKey && imgKey !== "") {
      const reqBody = getGUID(imgKey);
      generatePresignedUrl(reqBody).then((res) => {
        if (res?.data) setImgUrl(res.data);
        setFormDataReady(true);
      });
    }
  };

  if (!formDataReady) {
    return null;
  }

  return <img src={imgUrl} alt="photo" className={className} />;
};

export default UserImage;
