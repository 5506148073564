import React, { useEffect, useState } from "react";
import jiraImage from "../../images/jira.png";
import clickupImage from "../../images/clickup.png";
import { useAppDispatch } from "../../hooks";
import {
  deleteIntegrationAsync,
  getIntegrationListAsync,
} from "../../services/reducers/appReducer";
import SaveChanges from "./SaveChanges";
import ModalDelete from "../TimeTracker/ModalDeletePopup";

type Props = {
  userProfile: string;
  list: any;
  roleName: any;
  getList: () => void;
};

const Integration: React.FC<Props> = ({
  userProfile,
  list,
  roleName,
  getList,
}) => {
  const dispatch = useAppDispatch();
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [integrationId, setIntegrationId] = useState("");

  const handleDelete = (id: string) => {
    setConfirmationVisible(true);
    setIntegrationId(id);
  };

  return (
    <div className="border border-gray-100 shadow-300 bg-white rounded-md relative h-[calc(100vh-11.75rem)] overflow-auto" id="settingsIntregation">
      <div className="px-8 pt-2 pb-5 h-[calc(100%-5.75rem)]">
        {list.map((item: any) => (
          <>
            {item.platform === "Jira" ? (
              <div className="flex justify-between items-center border-b mt-6 border-gray-400 pb-6">
                <div className="flex justify-start items-center">
                  <div className="h-8 w-8">
                    <img
                      src={jiraImage}
                      alt="jiraImage"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="ms-4">
                    <div className="flex justify-start items-center">
                      <span className="text-600 font-medium text-gray-300">
                        Jira
                      </span>
                      <span className="text-200 font-medium text-green-100 py-1 px-2.5 bg-pastel-100 rounded-full ms-2 flex items-center">
                        <span className="h-1.5 w-1.5 rounded-full bg-green-100 inline-block me-1"></span>
                        Active
                      </span>
                    </div>
                    <div className="text-400 font-normal text-gray-300 mt-1">
                      {userProfile}
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => handleDelete(item.id)}
                  className="text-400 font-normal text-gray-700"
                >
                  Delete
                </button>
              </div>
            ) : (
              <div className="flex justify-between items-center border-b mt-6 border-gray-400 pb-6">
                <div className="flex justify-start items-center">
                  <div className="h-8 w-8">
                    <img
                      src={clickupImage}
                      alt="clickupImage"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="ms-4">
                    <div className="flex justify-start items-center">
                      <span className="text-600 font-medium text-gray-300">
                        Clickup
                      </span>
                      <span className="text-200 font-medium text-green-100 py-1 px-2.5 bg-pastel-100 rounded-full ms-2 flex items-center">
                        <span className="h-1.5 w-1.5 rounded-full bg-green-100 inline-block me-1"></span>
                        Active
                      </span>
                    </div>
                    <div className="text-400 font-normal text-gray-300 mt-1">
                      {userProfile}
                    </div>
                  </div>
                </div>
                <button
                  className="text-400 font-normal text-gray-700"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </button>
              </div>
            )}
          </>
        ))}
      </div>

      {isConfirmationVisible && (
        <ModalDelete
          onCancel={() => {
            setConfirmationVisible(false);
            setIntegrationId("");
          }}
          onConfirm={(requestType) => {
            if (requestType === "Yes") {
              dispatch(deleteIntegrationAsync(integrationId)).then((res) => {
                getList();
              });
            }
            setConfirmationVisible(false);
            setIntegrationId("");
          }}
          chldLable="Are you sure you want to delete this Integration? "
        />
      )}
    </div>
  );
};

export default Integration;
