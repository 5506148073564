import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { useAppDispatch } from "../../hooks";
import {
  createTimesheetAsync,
  deleteTimesheetAsync,
  updateTimesheetAsync,
} from "../../services/reducers/timeReducer";
import {
  convertHourMinuteToMinutes,
  convertMinutesToHoursAndMinutes,
  convertUTCtoIST,
  formatDate,
  formatPushDate,
  formatRelativeTime,
} from "../../utils/common";
import Notification from "../../components/Notification/Notification";
// import AddTaskModal from "./AddTaskModal";
import ModalDelete from "./ModalDeletePopup";
import Timer from "../../components/Timer/Timer";
import TimeTrackerWeeklyEmpty from "./TimeTrackerWeeklyEmpty";
import CustomTooltip from "./CustomTooltip";
import Icons from "../../components/Icons/Icon";
import SubmitTimeConfirmationModal from "./SubmitTimeConfirmationModal";
import UserImage from "../../components/UserImage/UserImage";
import getInitialsInName from "../../helper/GetNameInitials";
import AddTaskModal from "./AddTaskModal";

type Props = {
  userDetail: string;
  startDate: any;
  endDate: any;
  getList: () => void;
  list: any;
  taskList: any;
  loading: boolean;
  timeoffList: any;
  userProfile: any;
  totalRecord: number;
};

const TimeTrackerWeekly: React.FC<Props> = ({
  userDetail,
  startDate,
  endDate,
  getList,
  list,
  taskList,
  loading,
  timeoffList,
  userProfile,
  totalRecord,
}) => {
  const [addTask, setAddTask] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const processedDates = new Set();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showNotification1, setShowNotification1] = useState<boolean>(false);
  const [showNotificationLock, setShowNotificationLock] =
    useState<boolean>(false);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isConfirmationDeleteVisible, setConfirmationDeleteVisible] =
    useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [submitTime, setSubmitTime] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const totalHoursPerDay: { [date: string]: number } = {};

  const isWeekend = (dateString: any) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };
  const [isThisWeek, setIsThisWeek] = useState(false);
  const [showClickShow, setShowClickShow] = useState({ taskId: "", date: "" });

  const toggleClickShow = (taskId: string, date: string) => {
    setShowClickShow((prevState) => {
      if (prevState.taskId === taskId && prevState.date === date) {
        return { taskId: "", date: "" };
      }
      return { taskId, date };
    });
  };

  const closeClickShow = () => {
    setShowClickShow({ taskId: "", date: "" });
  };

  useEffect(() => {
    getList();
  }, []);

  const getParentTimeEstimate = (
    targetDate: string,
    targetTimesheetId: string
  ) => {
    for (const parentTask of list) {
      for (const task of parentTask.tasksList) {
        if (
          task.date === targetDate &&
          task.timeSheetId === targetTimesheetId
        ) {
          const editableDiv = document.getElementsByClassName(
            `test${targetDate}_${targetTimesheetId}`
          );
          if (parentTask.timeEstimate === 0 && editableDiv.length > 0) {
            Array.from(editableDiv).forEach((editableDivElement: Element) => {
              if (editableDivElement instanceof HTMLElement) {
                editableDivElement.textContent = "-";
              }
            });
          }
          return parentTask.timeEstimate;
        }
      }
    }
    return 0;
  };

  const handleTimeSpentEdit = (
    taskId: string,
    date: string,
    timeSheetId: string,
    newValue: number
  ) => {
    const updatedList = list.map((task: any) => {
      if (task.identifier === taskId) {
        const updatedTasksList = task.tasksList.map((t1: any) => {
          if (t1.date === date) {
            return { ...t1, timeSpent: newValue || 0 };
          }
          return t1;
        });

        return { ...task, tasksList: updatedTasksList };
      }
      return task;
    });

    const updatedTask = updatedList.find(
      (task: any) => task.identifier === taskId
    );
    const values = {
      timeSheetId: timeSheetId,
      date: date,
      timeSpent: convertHourMinuteToMinutes(
        `${updatedTask?.tasksList.find((t1: any) => t1.date === date)
          ?.timeSpent || "00h00m00s"
        }`
      ),
      identifier: taskId,
    };

    const content =
      values.timeSpent > 0
        ? convertMinutesToHoursAndMinutes(values.timeSpent)
        : "-";

    const editableDiv = document.getElementById(`test${timeSheetId}`);
    if (editableDiv) {
      editableDiv.textContent = content;
    }

    dispatch(updateTimesheetAsync(values)).then((res) => {
      getList();
    });
  };

  const timerRunningState = (task: any) => {
    const activeTimerItem = task.find((item: any) => item.isTimerActive);

    return activeTimerItem;
  };

  const deactivateTimmer = () => {
    setTimerRunning(true);
    getList();
  };

  const handleTimePlay = (
    task: any,
    isTimerActive: boolean,
    identifier: string,
    key?: string,
    estimate?: number,
    taskSummary?: string
  ) => {
    //debugger;
    setShowNotification(false);
    setShowNotification1(false);
    setShowNotificationLock(false);
    // Extract year, month, and day
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");

    // Form the "yyyy-mm-dd" format
    const formattedDate = year + "-" + month + "-" + day;
    const timesheet = task.tasksList.find((m: any) => m.date === formattedDate);

    if (timesheet?.timeSheetId === "00000000-0000-0000-0000-000000000000") {
      const request = {
        weekStartDate: timesheet.date,
        tasks: {
          identifier: identifier,
          key: key,
          project: {
            id: timesheet.project.id,
            externalId: "",
            key: timesheet.project.key,
            name: timesheet.project.name,
            projectStatus: timesheet.project.projectStatus,
            platform: timesheet.project.platform,
            deleted: false,
          },
          summary: taskSummary,
          timeEstimate: estimate,
          entryFrom: 0
        },
        platformId: timesheet.project.platform,
      };

      dispatch(createTimesheetAsync(request)).then((response) => {
        const newTimeSheetId = response.payload.timeSheetId;
        if (
          getParentTimeEstimate(timesheet.date, timesheet.timeSheetId) === 0
        ) {
          setShowNotification(true);
        } else if (task.isTaskOpen === false) {
          setShowNotification1(true);
        } else if (timesheet?.project?.projectStatus === 4) {
          setShowNotificationLock(true);
        } else {
          setShowNotification(false);
          setShowNotification1(false);
          setShowNotificationLock(false);
          timerRunningState(task?.tasksList);
          const values = {
            ...timesheet,
            timeSheetId: newTimeSheetId,
            isTimerActive: isTimerActive,
            timeSpent: timesheet.timerRunning,
          };

          dispatch(updateTimesheetAsync(values)).then((res) => {
            getList();
          });
        }
      });
    } else if (timesheet?.date && timesheet?.timeSheetId) {
      if (getParentTimeEstimate(timesheet.date, timesheet.timeSheetId) === 0) {
        setShowNotification(true);
      } else if (task.isTaskOpen === false) {
        setShowNotification1(true);
      } else if (timesheet?.project?.projectStatus === 4) {
        setShowNotificationLock(true);
      } else {
        setShowNotification(false);
        setShowNotification1(false);
        setShowNotificationLock(false);
        timerRunningState(task?.tasksList);
        const values = {
          ...timesheet,
          isTimerActive: isTimerActive,
          timeSpent: timesheet.timerRunning,
        };

        dispatch(updateTimesheetAsync(values)).then((res) => {
          getList();
        });
      }
    }
  };

  const handleDelete = (id: string, proId: string, status: any) => {
    const projectStatus = status[0];
    if (projectStatus === 4) {
      setConfirmationDeleteVisible(true);
    } else {
      setConfirmationVisible(true);
      setDeleteItemId(id);
      setProjectId(proId);
    }
  };

  list.forEach((task: any) => {
    task.tasksList.forEach((t: any) => {
      const date = t.date;
      totalHoursPerDay[date] = (totalHoursPerDay[date] || 0) + t.timeSpent;
    });
  });

  // const handleInput = (e: any) => {
  //   const selection = window.getSelection();
  //   const anchorOffset = selection?.anchorOffset || 0;
  //   const validInput = e.currentTarget.textContent;

  //   if (validInput === "-") {
  //     e.currentTarget.textContent = "";
  //     return;
  //   }

  //   if (selection) {
  //     const firstChild = e.currentTarget.firstChild;

  //     if (firstChild) {
  //       const range = document.createRange();
  //       const offset = Math.min(anchorOffset, validInput.length);
  //       range.setStart(firstChild, offset);
  //       range.setEnd(firstChild, offset);
  //       selection.removeAllRanges();
  //       selection.addRange(range);
  //     }
  //   }

  //   if (validInput !== "") {
  //     e.currentTarget.textContent = validInput;
  //   }
  // };
  const handleInput = (e: any) => {
    const selection = window.getSelection();
    const anchorOffset = selection?.anchorOffset || 0;
    const currentValue = e.currentTarget.textContent;

    // Sanitize input to allow only numbers, 'h', 'm', and spaces
    const sanitizedInput = currentValue.replace(/[^0-9hm\s]/g, "");

    // Set sanitized input back to the element
    e.currentTarget.textContent = sanitizedInput;

    // Ensure the caret remains at the correct position
    if (selection) {
      const firstChild = e.currentTarget.firstChild;
      if (firstChild) {
        const range = document.createRange();
        const offset = Math.min(anchorOffset, sanitizedInput.length);
        range.setStart(firstChild, offset);
        range.setEnd(firstChild, offset);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const onFocus = (e: any) => {
    if (e.currentTarget.textContent === "-") {
      e.currentTarget.textContent = "";
    }
    e.currentTarget.dataset.prevValue = e.currentTarget.textContent.trim();
  };

  const onBlur = (
    e: any,
    taskId: string,
    key: string,
    date: string,
    timeSheetId: string,
    projectStatus: number,
    platform: number,
    projectId: string,
    projectKey: string,
    projectName: string,
    estimate: number,
    taskSummary: string
  ) => {
    const inputValue = e.currentTarget.textContent.trim();
    const prevValue = e.currentTarget.dataset.prevValue;

    if (inputValue === "-") {
      e.currentTarget.textContent = "";
    }
    if (timeSheetId === "00000000-0000-0000-0000-000000000000") {
      const request = {
        weekStartDate: date,
        tasks: {
          identifier: taskId,
          key: key,
          project: {
            id: projectId,
            externalId: "",
            key: projectKey,
            name: projectName,
            projectStatus: projectStatus,
            platform: platform,
            deleted: false,
          },
          summary: taskSummary,
          timeEstimate: estimate,
          entryFrom: 0
        },
        platformId: platform,

      };

      dispatch(createTimesheetAsync(request)).then((response) => {
        const newTimeSheetId = response.payload.timeSheetId;
        if (inputValue !== prevValue) {
          const newValue = inputValue === "" ? 0 : inputValue;
          const editableDiv = document.getElementById(`test${newTimeSheetId}`);

          if (editableDiv) {
            editableDiv.textContent =
              inputValue !== ""
                ? convertMinutesToHoursAndMinutes(newValue)
                : "-";
          } else {
            console.warn(`Element with id 'test${newTimeSheetId}' not found`);
          }

          handleTimeSpentEdit(taskId, date, newTimeSheetId, newValue);
        } else {
          if (inputValue === "") {
            e.currentTarget.textContent = "-";
          } else {
            e.currentTarget.textContent = prevValue;
          }
        }
      });
    } else {
      if (inputValue !== prevValue) {
        const newValue = inputValue === "" ? 0 : inputValue;
        const editableDiv = document.getElementById(`test${timeSheetId}`);

        if (editableDiv) {
          editableDiv.textContent =
            inputValue !== "" ? convertMinutesToHoursAndMinutes(newValue) : "-";
        } else {
          console.warn(`Element with id 'test${timeSheetId}' not found`);
        }

        handleTimeSpentEdit(taskId, date, timeSheetId, newValue);
      } else {
        if (inputValue === "") {
          e.currentTarget.textContent = "-";
        } else {
          e.currentTarget.textContent = prevValue;
        }
      }
    }
  };

  const handleMouseEnter = () => {
    setShowNotification(false);
    setShowNotification1(false);
    setShowNotificationLock(false);
  };

  const handleMouseLeave = () => {
    setShowNotification(false);
    setShowNotification1(false);
    setShowNotificationLock(false);
  };

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const diff =
      currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
    const firstDayOfWeek = new Date(currentDate.setDate(diff));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    return { firstDayOfWeek, lastDayOfWeek };
  };

  const isCurrentWeek = (startOfWeek: Date, endOfWeek: Date): boolean => {
    const currentWeek = getCurrentWeek();
    return (
      startOfWeek.toDateString() ===
      currentWeek.firstDayOfWeek.toDateString() &&
      endOfWeek.toDateString() === currentWeek.lastDayOfWeek.toDateString()
    );
  };

  useEffect(() => {
    const startOfWeek = new Date(startDate);
    const endOfWeek = new Date(endDate);
    setIsThisWeek(isCurrentWeek(startOfWeek, endOfWeek));
  }, [startDate, endDate]);

  // const hasSubmitTimesheet = list.some(
  //   (t: any) => t.timeSheetActionStatus !== 0
  // );

  const hasPushTimesheet = list.some((t: any) => t.timeSheetActionStatus === 1);

  const timeOffHoliday = timeoffList.filter(
    (item: any) => item.timeOffType === "Holiday"
  );

  let taskTotal = 0;
  let isFirstTask = true;

  list.forEach((task: any) => {
    let taskTime = 0;
    task.tasksList.forEach((item: any) => {
      taskTime += item.timeSpent;
      // console.log(`Adding time spent for ${item.date}: ${item.timeSpent} minutes`);

      item.timeOffList.forEach((timeOff: any) => {
        if (isFirstTask) {
          if (!isWeekend(timeOff.timeOffDate)) {
            taskTime += timeOff.approvedTimeOffInMin;
            // console.log(
            //   `Adding time off for ${timeOff.timeOffDate}: ${timeOff.approvedTimeOffInMin} minutes`
            // );
          }
        }
      });
    });

    // console.log(`Total time for task ${task.key}: ${taskTime} minutes`);
    taskTotal += taskTime;

    if (isFirstTask) {
      isFirstTask = false;
    }
  });
  const timesheetActions = list.map(
    (record: any) => record.timeSheetActionStatus
  );
  const lastAction = timesheetActions[timesheetActions.length - 1];

  const hasSubmitTimesheet = list.some(
    // (t: any) => t.timeSheetActionStatus === null || t.timeSheetActionStatus === 1
    (t: any) => lastAction === null || lastAction === 1
  );
  const hasPushOrNullTimesheet = list.some(
    (t: any) =>
      // t.timeSheetActionStatus === 1 || t.timeSheetActionStatus === null
      lastAction === 1 || lastAction === null
  );
  return (
    <div className="relative" id="timeTrackerWeekly">
      {hasPushOrNullTimesheet && (
        <div className="absolute right-[155px] -top-[53px] z-10">
          <button
            className="flex justify-center items-center gap-0.5 text-400 text-white font-bold bg-primary-100 px-3 border border-pastel-400 py-[0.563rem] rounded-md transition-all hover:bg-primary-200 hover:shadow-100"
            onClick={() => setAddTask(true)}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 10H15"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 15V5"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            Add Task
          </button>
        </div>
      )}
      <div className="h-[calc(100vh-11.1rem)] shadow-300 border border-gray-100 rounded-md flex flex-col flex-grow">
        <div className="bg-white relative pb-6 overflow-auto h-full flex-grow">
          <div className="h-[3.75rem] bg-gray-200 rounded-tr-md sticky left-0 top-0 z-50 ps-8 pe-6">
            <div className="flex justify-start gap-2 h-full">
              <div className="flex items-center justify-start w-[calc(100%-44rem)]">
                <div className="text-500 font-bold text-gray-300">Tasks</div>
              </div>

              <div className="flex justify-end text-center gap-[0.75rem]">
                {taskList.map((task: any) => {
                  const currentDate = task.date;
                  const isToday =
                    new Date(currentDate).toLocaleDateString() ===
                    new Date().toLocaleDateString();
                  const dayKey = `${currentDate}_${new Date(
                    currentDate
                  ).toLocaleDateString("en-US", { weekday: "short" })}`;
                  if (processedDates.has(dayKey)) {
                    return null;
                  }
                  processedDates.add(dayKey);
                  const isWeekendWithTimeSpent =
                    isWeekend(currentDate) &&
                    taskList.some(
                      (t: any) => t.date === currentDate && t.timeSpent > 0
                    );

                  return (
                    <div
                      key={currentDate}
                      className={`flex justify-center items-center w-[5rem] h-full relative ${isToday
                        ? "after:absolute after:left-1/2 after:-translate-x-1/2 after:top-0 after:bg-primary-100 after:h-[0.188rem] after:w-9 after:rounded-b-md"
                        : ""
                        } ${isWeekend(currentDate)
                          ? isWeekendWithTimeSpent
                            ? "opacity-100"
                            : "opacity-50"
                          : ""
                        }`}
                    >
                      <div>
                        <div className={`text-500 font-bold text-gray-300`}>
                          {new Date(currentDate).toLocaleDateString("en-US", {
                            weekday: "short",
                          })}
                        </div>
                        <div className={`text-400 font-normal text-gray-300`}>
                          {new Date(currentDate).toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "short",
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="flex justify-end items-center w-[5rem] h-full">
                  <div>
                    <div className="text-500 font-bold text-gray-300">
                      Total
                    </div>
                    <div className="text-400 font-normal text-gray-300">
                      Hours
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="h-[3.75rem] bg-white rounded-tr-md ps-8 pe-6 sticky left-0 z-50 top-[3.75rem]">
            <div className="flex justify-start border-b border-gray-400 gap-2 h-full">
              <div className="flex items-center justify-start w-[calc(100%-44rem)] h-full">
                <div className="text-400 font-normal text-gray-300">
                  Total hours
                </div>
              </div>
              <div className="flex justify-end text-center gap-[0.75rem]">
                {Object.keys(totalHoursPerDay).map((date) => {
                  const isTimeOffRequested =
                    timeoffList &&
                    timeoffList.some(
                      (timeOffItem: any) => timeOffItem.timeOffDate === date
                    );

                  const timeOffItems = timeoffList.filter(
                    (item: any) => item.timeOffDate === date
                  );

                  const getClassNames = (type: any) => {
                    switch (type) {
                      case "Holiday":
                        return "font-bold text-primary-100";
                      case "Leave":
                        return "font-bold text-secondary-100";
                      default:
                        return "font-normal text-gray-300";
                    }
                  };

                  // const totalApprovedTimeOffInMin = timeOffItems.reduce(
                  //   (acc: any, curr: any) => acc + curr.approvedTimeOffInMin,
                  //   0
                  // );
                  let totalApprovedTimeOffInMin = 0;
                  // if (!isWeekend(date)) {
                  //   totalApprovedTimeOffInMin = timeOffItems.reduce(
                  //     (acc: any, curr: any) => acc + curr.approvedTimeOffInMin,
                  //     0
                  //   );
                  // }
                  if (!isWeekend(date)) {

                    const holidayItem = timeOffItems.find(
                      (item: any) => item.timeOffType === "Holiday"
                    );
                    const leaveItem = timeOffItems.find(
                      (item: any) => item.timeOffType === "Leave"
                    );


                    if (holidayItem && leaveItem) {
                      totalApprovedTimeOffInMin = holidayItem.approvedTimeOffInMin;
                    } else {

                      totalApprovedTimeOffInMin = timeOffItems.reduce(
                        (acc: any, curr: any) => acc + curr.approvedTimeOffInMin,
                        0
                      );
                    }
                  }
                  const timeOffType =
                    timeOffItems.length > 0
                      ? timeOffItems[0].timeOffType
                      : null;

                  return (
                    <div
                      key={date}
                      className={`flex justify-center items-center w-[5rem] h-full ${isTimeOffRequested ? "relative" : "overflow-hidden"
                        }`}
                    >
                      <div className={`text-400 ${getClassNames(timeOffType)}`}>
                        {isTimeOffRequested ? (
                          <>
                            <div className="">
                              <CustomTooltip
                                tooltipType="Holiday"
                                trigger
                                timeoffList={timeoffList}
                                date={date}
                              >
                                <div className="text-400 font-bold text-secondary-100">
                                  {/* <div
                                  className={`text-400 font-bold ${getClassNames(
                                    timeOffType
                                  )}`}
                                > */}
                                  {convertMinutesToHoursAndMinutes(
                                    totalApprovedTimeOffInMin +
                                    totalHoursPerDay[date]
                                  )}
                                </div>
                                <div className="text-100 text-center line-clamp-1 font-bold text-secondary-100">
                                  {/* <div
                                   className={`text-100 font-normal text-center line-clamp-1 ${getClassNames(
                                     timeOffType
                                   )}`}
                                 > */}
                                  {timeOffHoliday.some(
                                    (item: any) => item.timeOffDate === date
                                  )
                                    ? "HOLIDAY"
                                    : "ON LEAVE"}
                                </div>
                              </CustomTooltip>
                            </div>
                          </>
                        ) : (
                          convertMinutesToHoursAndMinutes(
                            totalHoursPerDay[date]
                          )
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="flex justify-end items-center w-[5rem] h-full overflow-hidden">
                  <div className="text-400 font-bold text-primary-100">
                    {/* {convertMinutesToHoursAndMinutes(
                      list.reduce((total: number, d: any) => {
                        return (
                          total +
                          d.tasksList.reduce(
                            (taskTotal: number, t: any) =>
                              taskTotal + (t.timeSpent > 0 ? t.timeSpent : 0),
                            0
                          )
                        );
                      }, 0)
                    )} */}
                    {convertMinutesToHoursAndMinutes(taskTotal)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {loading && <EchopulseLoader />}  */}
          {list &&
            list.map((t: any) =>
              t.identifier !== "" ? (
                <div className="h-[4.875rem] bg-white rounded-tr-md relative ps-8 pe-6 after:absolute after:h-9 after:w-[0.1875rem] after:bg-green-100 after:top-1/2 after:-translate-y-1/2 after:left-0 after:rounded-e-md group gap-2 block">
                  <div className="flex justify-start border-b border-gray-400 gap-2 h-full">
                    <div className="flex items-center justify-start w-[calc(100%-44rem)] h-full py-4">
                      <div className="">
                        <div className="text-400 font-bold text-gray-300 flex items-center">
                          <span className="line-clamp-1">{t.summary}</span>

                          {timerRunningState(t.tasksList) ? (
                            <span
                              className="ms-2.5 cursor-pointer invisible group-hover:visible"
                              onClick={() => {
                                handleTimePlay(
                                  t,
                                  false,
                                  t.identifier,
                                  t.key,
                                  t?.timeEstimate,
                                  t?.summary
                                );
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              {t.timeSheetActionStatus !== 0 && isThisWeek && (
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="vuesax/bold/stop-circle">
                                    <g id="stop-circle">
                                      <path
                                        id="Vector"
                                        d="M8.47884 1.41663C4.56884 1.41663 1.39551 4.58996 1.39551 8.49996C1.39551 12.41 4.56884 15.5833 8.47884 15.5833C12.3888 15.5833 15.5622 12.41 15.5622 8.49996C15.5622 4.58996 12.3959 1.41663 8.47884 1.41663ZM11.4963 9.37121C11.4963 10.547 10.5472 11.4962 9.37134 11.4962H7.62884C6.45301 11.4962 5.50384 10.547 5.50384 9.37121V7.62871C5.50384 6.45288 6.45301 5.50371 7.62884 5.50371H9.37134C10.5472 5.50371 11.4963 6.45288 11.4963 7.62871V9.37121Z"
                                        fill="#F15845"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              )}
                            </span>
                          ) : (
                            <span
                              className="ms-2.5 cursor-pointer invisible group-hover:visible"
                              onClick={() => {
                                handleTimePlay(
                                  t,
                                  true,
                                  t.identifier,
                                  t.key,

                                  t?.timeEstimate,
                                  t?.summary
                                );
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              {t.timeSheetActionStatus !== 0 && isThisWeek && (
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="vuesax/linear/play-circle">
                                    <g id="play-circle">
                                      <path
                                        id="Vector"
                                        d="M8.97754 16.5C13.1197 16.5 16.4775 13.1421 16.4775 9C16.4775 4.85786 13.1197 1.5 8.97754 1.5C4.8354 1.5 1.47754 4.85786 1.47754 9C1.47754 13.1421 4.8354 16.5 8.97754 16.5Z"
                                        fill="#2EB67D"
                                      />
                                      <path
                                        id="Vector_2"
                                        d="M6.55518 9.17247V7.91997C6.55518 6.35997 7.65768 5.72247 9.00768 6.50247L10.0952 7.13247L11.1827 7.76247C12.5327 8.54247 12.5327 9.81747 11.1827 10.5975L10.0952 11.2275L9.00768 11.8575C7.65768 12.6375 6.55518 12 6.55518 10.44V9.17247Z"
                                        fill="white"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              )}
                            </span>
                          )}
                        </div>
                        <div className="text-200 font-normal text-gray-700 flex mt-1">
                          <span className="max-w-[70%] line-clamp-1">
                            {" "}
                            {t.tasksList[0]?.project?.key ||
                              t.tasksList[0]?.project?.id}{" "}
                            - {t.tasksList[0]?.project?.name}
                          </span>
                          <span className="ms-2.5 ps-2.5 border border-y-0 border-e-0 border-s-gray-700 line-clamp-1">
                            #{t.key}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-start text-center gap-[0.75rem] py-4">
                      {t.tasksList.map((t1: any, index: number) => (
                        <div
                          key={t1.date}
                          className={`empClickArea flex justify-center items-center w-[5rem] h-full border rounded-md cursor-pointer focus-within:border-green-100 relative ${t1?.comments?.length > 0 && hasPushTimesheet
                            ? "border-primary-100 hover:border-primary-100"
                            : "border-gray-400 hover:border-secondary-100"
                            } ${(new Date(t1.date) < new Date() &&
                              t1.isDisabled === true &&
                              lastAction !== 1) ||
                              // t.timeSheetActionStatus === 0 ||
                              lastAction === 0 ||
                              t.timeSheetActionStatus === 2
                              ? "bg-gray-800 bg-opacity-10 cursor-auto hover:border-0 focus-within:border-0"
                              : ""
                            }`}
                        >
                          {t1.isTimerActive === false && (
                            <div
                              key={`test${t1.date}`}
                              id={`test${t1.timeSheetId}`}
                              className={`text-400 font-normal text-gray-300 outline-0 focus-visible:outline-none test${t1.date}_${t1.timeSheetId}`}
                              //contentEditable={t1.isDisabled === true || !t.isTaskOpen ? false : true}
                              contentEditable={
                                // (t1.isDisabled === true && t.timeSheetActionStatus !== 1) || t.timeSheetActionStatus === 2
                                (t1.isDisabled === true && lastAction !== 1) ||
                                  t.timeSheetActionStatus === 2
                                  ? false
                                  : // : t.timeSheetActionStatus !== 0
                                  lastAction !== 0
                                    ? true
                                    : false
                              }
                              onInput={
                                t1?.project?.projectStatus === 4
                                  ? () => setShowNotificationLock(true)
                                  : t.isTaskOpen
                                    ? (e: any) => {
                                      handleInput(e);
                                      const estimate = getParentTimeEstimate(t1.date, t1.timeSheetId);
                                      setShowNotification(estimate === 0);
                                    }
                                    : t.isTaskOpen === false
                                      ? () => setShowNotification1(true)
                                      : undefined
                              }
                              onFocus={
                                t1.timeSpent > 0
                                  ? (e: any) => {
                                    onFocus(e);
                                    setShowNotification(false);
                                    setShowNotification1(false);
                                    //setShowNotificationLock(false)
                                  }
                                  : undefined
                              }
                              onBlur={
                                t.isTaskOpen
                                  ? (e: any) => {
                                    onBlur(
                                      e,
                                      t.identifier,
                                      t.key,
                                      t1.date,
                                      t1.timeSheetId,
                                      t1?.project?.projectStatus,
                                      t1?.project?.platform,
                                      t1?.project?.id,
                                      t1?.project?.key,
                                      t1?.project?.name,
                                      t?.timeEstimate,
                                      t?.summary
                                    );
                                    setShowNotification(false);
                                    setShowNotification1(false);
                                  }
                                  : undefined
                              }
                            >
                              {t1.timeSpent > 0
                                ? convertMinutesToHoursAndMinutes(t1.timeSpent)
                                : "-"}
                            </div>
                          )}

                          {t1.isTimerActive === true && (
                            <Timer
                              initialMinutes={t1.timerRunning}
                              identifier={t1.identifier}
                              date={t1.date}
                              onClick={() =>
                                handleTimePlay(
                                  t,
                                  false,
                                  t.identifier,
                                  t.key,

                                  t?.timeEstimate,
                                  t?.summary
                                )
                              }
                              deactivateTimmer={deactivateTimmer}
                              setShowNotification={setShowNotification}
                              setShowNotification1={setShowNotification1}
                              setShowNotificationLock={setShowNotificationLock}
                            />
                          )}
                          {t1?.comments?.length > 0 && hasPushTimesheet && (
                            <span
                              className="left-1/2 -translate-x-1/2 -bottom-[0.813rem] h-2 w-2 bg-primary-100 absolute rounded-full drop-shadow-2xl clickOpen"
                              onClick={() =>
                                toggleClickShow(t1.timeSheetId, t1.date)
                              }
                            ></span>
                          )}
                          {showClickShow.taskId === t1.timeSheetId &&
                            showClickShow.date === t1.date && (
                              <div className="empCommentShowArea absolute top-[135%] border border-gray-100 rounded-md w-[23.125rem] bg-white clickShow z-10">
                                <div className="ps-2.5 pe-4 pt-2 pb-2.5 w-full">
                                  <div className="border-b border-gray-400 w-full flex justify-end items-center pe-2">
                                    <button onClick={closeClickShow}>
                                      <Icons
                                        name="cross"
                                        variant="stroke"
                                        size={16}
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className="pb-1 ps-2.5 h-[8.5rem] pe-4 overflow-auto">
                                  {t1?.comments.map(
                                    (t2: any, index: number) => (
                                      <div className="pt-3 pb-4">
                                        <div className="flex justify-start items-center gap-2.5">
                                          <div>
                                            {t2?.profileImagePath ? (
                                              <UserImage
                                                imgKey={t2.profileImagePath}
                                                className="w-8 h-8 rounded-full object-cover"
                                              />
                                            ) : (
                                              <img
                                                src={getInitialsInName(
                                                  t2.postedBy ?? "N A",
                                                  24
                                                )}
                                                alt={t2.postedBy}
                                                className="w-8 h-8 rounded-full object-cover"
                                              />
                                            )}
                                          </div>
                                          <div className="text-400 font-normal text-gray-300">
                                            {t2.postedBy}
                                          </div>
                                          <div className="text-gray-700 text-400 font-normal">
                                            {convertUTCtoIST(t2.postedAt)}
                                          </div>
                                        </div>
                                        <div className="mt-3 text-gray-700 text-400 text-left">
                                          {t2?.comment}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                      <div className="flex justify-end items-center w-[5rem] h-full"></div>

                      {/* {(t.timeSheetActionStatus !== 0 && t.timeSheetActionStatus !== 2) && ( */}
                      {lastAction !== 0 && lastAction !== 2 && (
                        <div className="absolute right-8 top-8 invisible group-hover:visible">
                          <button
                            onClick={() =>
                              handleDelete(
                                t.identifier,
                                t.projectId,
                                t.tasksList.map(
                                  (t2: any) => t2?.project?.projectStatus
                                )
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                stroke="#F15845"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.0835 4.14175L7.26683 3.05008C7.40016 2.25841 7.50016 1.66675 8.9085 1.66675H11.0918C12.5002 1.66675 12.6085 2.29175 12.7335 3.05841L12.9168 4.14175"
                                stroke="#F15845"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.7077 7.6167L15.166 16.0084C15.0744 17.3167 14.9993 18.3334 12.6743 18.3334H7.32435C4.99935 18.3334 4.92435 17.3167 4.83268 16.0084L4.29102 7.6167"
                                stroke="#F15845"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.6084 13.75H11.3834"
                                stroke="#F15845"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.9165 10.4167H12.0832"
                                stroke="#F15845"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <TimeTrackerWeeklyEmpty />
              )
            )}
        </div>
        {hasPushTimesheet && (
          <div className="flex justify-start items-center gap-2 bg-white px-8 py-2.5">
            <span className="h-2 w-2 bg-primary-100 rounded-full"></span>
            <div className="text-400 font-normal text-primary-100 italic">
              Pushed Back Hours
            </div>
          </div>
        )}
        {totalRecord !== 0 && (
          <div className="relative flex justify-start items-center px-8 w-full bg-white py-5 gap-4 border-t border-gray-100 rounded-b-md">
            {hasSubmitTimesheet ? (
              <Button
                label="Submit"
                onClick={() => setSubmitTime(true)}
                variant="primary"
                size="large"
              />
            ) : (
              <Button
                label="Submitted"
                onClick={function noRefCheck() { }}
                variant="tertiary"
                size="large"
                className="!cursor-auto hover:!shadow-0 !font-bold hover:!bg-green-100"
              />
            )}

            {!hasPushOrNullTimesheet && (
              <div className="flex justify-end items-center gap-2">
                {/* <span className="h-2 w-2 bg-primary-100 rounded-full"></span> */}
                <div className="text-400 font-medium text-primary-100 italic">
                  You cannot make changes after submitting the timesheet
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {addTask && (
        <AddTaskModal
          onClose={() => setAddTask(false)}
          userDetail={userDetail}
          getLists={getList}
          setAddTask={setAddTask}
          startDate={startDate}
          endDate={endDate}
          type="Employee"
        />
      )}

      {showNotification && (
        <Notification
          type="warning"
          message="Unable to Log Time: Task Lacks Required Estimates. Please Review Team Policies."
        />
      )}
      {showNotification1 && (
        <Notification
          type="warning"
          message="Task Closed: Time Logging Restricted by Administrator."
        />
      )}
      {showNotificationLock && (
        <Notification
          type="warning"
          message="Project Locked: Administrator Has Restricted Time Logging."
        />
      )}

      {isConfirmationVisible && (
        <ModalDelete
          onCancel={() => {
            setConfirmationVisible(false);
            setDeleteItemId(null);
            setProjectId(null);
          }}
          onConfirm={(requestType) => {
            if (requestType === "Yes") {
              // debugger;
              const request: any = {
                Identifier: deleteItemId,
                WeekStartDate: formatDate(startDate),
                WeekEndDate: formatDate(endDate),
                MemberId: "",
                ProjectId: projectId,
              };
              dispatch(deleteTimesheetAsync(request)).then((res) => {
                getList();
              });
            }
            setConfirmationVisible(false);
            setDeleteItemId(null);
            setProjectId(null);
          }}
          chldLable="Are you sure you want to remove this task?"
        />
      )}
      {isConfirmationDeleteVisible && (
        <ModalDelete
          onCancel={(requestType) => {
            setConfirmationDeleteVisible(false);
          }}
          onConfirm={(requestType) => {
            setConfirmationDeleteVisible(false);
          }}
          chldLable="The Task cannot be deleted."
          headerLable="As per your organisation’s policy, an added task cannot be deleted."
          requestType="NotDelete"
        />
      )}
      {submitTime && (
        <SubmitTimeConfirmationModal
          onClose={() => setSubmitTime(false)}
          userDetail={userDetail}
          setSubmitTime={setSubmitTime}
          startDate={startDate}
          endDate={endDate}
          setIsSubmitted={setIsSubmitted}
          getLists={getList}
        />
      )}
    </div>
  );
};

export default TimeTrackerWeekly;
