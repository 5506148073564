import React from "react";
import Typography from "../../components/Typography/Typography";
import Icons from "../../components/Icons/Icon";

const DashboardOverviewEmpty = () => {
  return (
    <div className="w-full h-[23.5rem] border border-gray-100 shadow-30 bg-white rounded-md" id="dashboardOverviewEmpty">
      <div className="px-6 pt-8">
        <div className="grid grid-cols-2 gap-2.5 border-b border-gray-400 pb-6">
          <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
            <div
              className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
              style={{
                filter: "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
              }}
            >
              <Icons name="watch-color" variant="stroke" size={20} />
            </div>
            <div className="ms-2.5">
              <div className="text-200 font-medium text-gray-700">
                Total Time Recored
              </div>
              <div className="text-500 font-bold text-gray-300">-</div>
            </div>
          </div>
          <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
            <div
              className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
              style={{
                filter: "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
              }}
            >
              <Icons name="round-small-color" variant="stroke" size={20} />
            </div>
            <div className="ms-2.5">
              <div className="text-200 font-medium text-gray-700">
                Average Daily Time Logged
              </div>
              <div className="text-500 font-bold text-gray-300">-</div>
            </div>
          </div>
          <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
            <div
              className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
              style={{
                filter: "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
              }}
            >
              <Icons name="soap-color" variant="stroke" size={20} />
            </div>
            <div className="ms-2.5">
              <div className="text-200 font-medium text-gray-700">
                Ongoing Project
              </div>
              <div className="text-500 font-bold text-gray-300">-</div>
            </div>
          </div>
          <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
            <div
              className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
              style={{
                filter: "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
              }}
            >
              <Icons name="document-color" variant="stroke" size={20} />
            </div>
            <div className="ms-2.5">
              <div className="text-200 font-medium text-gray-700">
                Project with the most hours
              </div>
              <div className="text-500 font-bold text-gray-300">-</div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 pt-6 pb-8">
        <Typography variant="h5" className="text-gray-300 font-bold">
          Timesheet Status
        </Typography>
        <div className="flex justify-center items-center h-[5.625rem]">
          <Typography variant="h6" className="text-gray-700 font-normal">
            Your timesheet status will be represented here.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverviewEmpty;
