import React, { useEffect, useState } from "react";
import Typography from "../../components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUserProfileAsync } from "../../services/reducers/appReducer";
import PersonProfile from "./PersonProfile";
import { ActivityLog } from "../ActivityLog/ActivityLog";

type Props = {
  title?: string;
  isOpen: boolean;
  userProfile: any;
};
const HeaderEchoPulse: React.FC<Props> = ({
  title,
  isOpen,
  userProfile
}) => {
  return (
    <div id="pulseHeader"
      className={`${
        isOpen
          ? `left-[14rem] top-0 w-[calc(100%-14rem)]`
          : `left-[4.5rem] top-0 w-[calc(100%-4.5rem)]`
      } px-10 py-4 bg-white fixed h-[5rem] z-[100] transition-all ease-in-out duration-200`}
    >
      <div className="w-full relative h-full">
        <div className="flex justify-between items-center me-[5rem] h-full">
          <div>
            {title === "Projects" ||
            title === "Tasks" ||
            title === "Team Dashboard" ||
            title === "Time Tracker" ||
            title === "Settings" ||
            title === "Screenshots" ||
            title === "Time Off" ? (
              <Typography
                variant="h3"
                size="h3md"
                className="font-bold text-gray-300"
              >
                {title === "Projects"
                  ? "Projects"
                  : title === "Team Dashboard"
                  ? "Team Timesheet Overview"
                  : title}
              </Typography>
            ) : (
              <>
                <Typography variant="h3" className="font-bold text-gray-300">
                  Welcome to Your Dashboard
                </Typography>

                <Typography
                  className="font-normal text-gray-900 mt-1"
                  size="lg"
                  variant="p"
                >
                  Here's a Summary of Your Recent Activity
                </Typography>
              </>
            )}
            {title === "Team Dashboard" && (
              <Typography
                className="font-normal text-gray-900 mt-1"
                size="lg"
                variant="p"
              >
                Here's a View of Your Team’s Activity at Millipixels
              </Typography>
            )}
            {/* {title === "Projects" && (
              <Typography
                className="font-normal text-gray-900 mt-1"
                size="lg"
                variant="p"
              >
                Here’s a list of ongoing projects.
              </Typography>
            )} */}
            {title === "Screenshots" && (
              <Typography
                className="font-normal text-gray-900 mt-1"
                size="lg"
                variant="p"
              >
                Here’s a collection of screenshots captured during your activity
                at Millipixels
              </Typography>
            )}
          </div>
        </div>
        <PersonProfile userProfile={userProfile} />
        <ActivityLog userProfile={userProfile}/>
      </div>
    </div>
  );
};

export default HeaderEchoPulse;
