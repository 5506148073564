import React, { useEffect, useState } from "react";
import PersonProfile from "./PersonProfile";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getUserIdentityAsync,
  getUserProfileAsync,
} from "../../services/reducers/appReducer";
import Typography from "../../components/Typography/Typography";
import { useNavigate } from "react-router-dom";
import Icons from "../../components/Icons/Icon";
import { ActivityLog } from "../ActivityLog/ActivityLog";

const LeaveBalanceRegularizationHeader = () => {
  const { userProfile } = useAppSelector((state: any) => state.app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserProfileAsync());
  }, []);
  return (
    <div className="px-10 py-4 bg-white fixed left-[4.5rem] top-0 w-[calc(100%-4.5rem)] h-[5rem] z-[100]" id="pulseHeaderRegu">
      <div className="w-full relative h-full">
        <div className="flex justify-start items-center me-[5rem] h-full">
          <button
            className="flex justify-start items-center text-600 font-normal text-gray-300"
            onClick={() => navigate(-1)}
          >
            <Icons name="left" variant="arrow" className="me-2" />
          </button>
          <Typography
            variant="h3"
            size="h3md"
            className="font-bold text-gray-300"
          >
            Balances & Regularization 
          </Typography>
        </div>
        <PersonProfile userProfile={userProfile} />
        <ActivityLog userProfile={userProfile}/>
      </div>
    </div>
  );
};

export default LeaveBalanceRegularizationHeader;
