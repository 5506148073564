import React, { useEffect, useRef, useState } from "react";
import ProjectsHeaderContent from "../../MenuEchoPulse/ProjectsHeaderContent";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import ActionCustomDropdown from "./ActionCustomDropdown";
import { ProjectDropdown } from "../../../components/ProjectDropdown/ProjectDropdown";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import echoImage from "../../../images/favicon.svg";
import activeTaskEmpty from "../../../images/activeTaskEmpty.svg";
import closeTaskEmpty from "../../../images/closeTaskEmpty.svg";
import deleteTaskEmpty from "../../../images/deleteTaskEmpty.svg";
import {
  getProjectByIdAsync,
  getProjectTeamMemberListAsync,
  getTaskAsync,
} from "../../../services/reducers/adminReducer";
import AddProjectTaskModal from "./AddProjectTaskModal";
import { useNavigate } from "react-router-dom";
import getInitialsInName from "../../../helper/GetNameInitials";
import EchopulseLoader from "../../loader/EchopulseLoader";
import InvitedMemberModal from "./InvitedMemberModal";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import Typography from "../../../components/Typography/Typography";
import SearchProjects from "./SearchProjects";
import Pagination from "./Pagination";

const ViewProject: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAppSelector((state: any) => state.app);
  const dispatch = useAppDispatch();
  const [projectData, setProjectData] = useState<any>({});
  const [userList, setUserList] = useState<any>(null);
  const [addTask, setAddTask] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [userSearchString, setUserSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [taskList, setTaskList] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [selectedListType, setSelectedListType] = useState<string>("1");
  const [inviteMember, setInviteMember] = useState<boolean>(false);
  const [listTypeValue, setListTypeValue] = useState<string>("");
  const [pageSize1, setPageSize1] = useState(50);
  const [taskId, setTaskId] = useState<string>("");
  const [selectedMember, setSelectedMember] = useState<any>([]);
  const [fetchUserListCustomRequest, setFetchUserListCustomRequest] =
    useState(true);
  const handleDropdownSelect = (action: string) => { };
  const isFetching = useRef(false);
  const [count, setCount] = useState<any>([]);
  const [filterss, setFilterss] = useState(false);

  useEffect(() => {
    if (isFetching.current) return;

    isFetching.current = true;
    if (fetchUserListCustomRequest) {
      const request = {
        ProjectId: id,
        Page: currentPage,
        PageSize: pageSize1,
        Team: "",
        IsOrderByDesc: false,
        SearchString: userSearchString,
      };

      dispatch(getProjectTeamMemberListAsync(request)).then((res) => {
        setUserList(res?.payload?.item2);
        isFetching.current = false;
      });
    }
  }, [userSearchString, id, fetchUserListCustomRequest]);

  useEffect(() => {
    // if (isFetching.current) return; 

    // isFetching.current = true;
    if (id) {
      dispatch(getProjectByIdAsync(id)).then((res) => {
        if (res.payload) {
          setProjectData(res.payload?.records[0]);
          // isFetching.current = false;
        }
      });
    }
  }, [id]);

  const getTask = () => {
    setLoading(true);
    let newListTypeValue = "";

    if (selectedListType === "1") {
      newListTypeValue = "all";
    } else if (selectedListType === "3") {
      newListTypeValue = "deleted";
    } else if (selectedListType === "2") {
      newListTypeValue = "closed";
    }

    setListTypeValue(newListTypeValue);
    const request = {
      searchString: searchString,
      ProjectId: id,
      Page: currentPage,
      PageSize: pageSize,
      listType: newListTypeValue,
    };

    dispatch(getTaskAsync(request)).then((res) => {
      setCount(res?.payload?.totalRecords);
      setTaskList(res?.payload?.records);
      setLoading(false);
    });
  };

  const debouncedSearch = useDebounce(getTask, 1000);

  useEffect(() => {
    debouncedSearch();
  }, [searchString, selectedListType]);

  const handleInvite = (id: string) => {
    setInviteMember(true);
    setTaskId(id);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="" id="projectView">
      <ProjectsHeaderContent title="View" />
      <div className="flex justify-between items-center relative">
        <div className="w-[7.7rem] ms-1">
          <ProjectDropdown
            value={{
              label:
                selectedListType === "1"
                  ? "Active Tasks"
                  : selectedListType === "2"
                    ? "Closed Tasks"
                    : selectedListType === "3"
                      ? "Deleted Tasks"
                      : "",
              value: selectedListType,
            }}
            onChange={(selectedValue, actionMeta) => {
              if (selectedValue && !Array.isArray(selectedValue)) {
                const newValue =
                  selectedValue.value !== undefined
                    ? String(selectedValue.value)
                    : "";

                setSelectedListType(newValue);
                setSearchString("");
              } else {
                setSelectedListType("");
              }
            }}
            placeholder="Select"
            type="smbox"
            options={[
              { label: "Active Tasks", value: "1" },
              { label: "Closed Tasks", value: "2" },
              { label: "Deleted Tasks", value: "3" },
            ]}
          />
        </div>
        <div>
          <SearchProjects
            title="View"
            searchString={searchString}
            setSearchString={setSearchString}
          />
        </div>

        <div
          className="absolute -left-[0.5rem] top-[3rem]"
          onClick={() => navigate(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="140"
            height="2"
            viewBox="0 0 112 2"
            fill="none"
          >
            <path
              d="M1 1H111"
              stroke="#F15845"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      <div className="border border-gray-100 shadow-300 bg-white rounded-md relative min-h-[10rem] h-[calc(100vh-11.1rem)] mt-2.5 overflow-auto flex flex-col flex-grow">
        <div className="flex-grow h-full overflow-auto px-8 py-5">
          <div className="border-b border-gray-400 pb-8 pt-2">
            <div className="flex justify-between items-start gap-5">
              <div className="flex justify-start items-center w-[calc(100%-120px)]">
                <div className="w-8 h-8 overflow-hidden">
                  <img
                    src={echoImage}
                    alt="hrImage"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ms-3 w-[calc(100%-44px)]">
                  <div className="text-600 text-gray-300 font-medium line-clamp-2 break-words">
                    {projectData?.name}
                  </div>
                  <div className="text-400 font-normal text-gray-300">
                    {projectData?.projectType === 0 ? "Internal" : "External"}
                  </div>
                </div>
              </div>

              <div className=" flex justify-end items-center gap-5">
                <Button
                  label="Add Task"
                  onClick={() => setAddTask(true)}
                  size="extramedium"
                />
              </div>
              {/* )} */}
            </div>
          </div>
          {loading && (
            <div className="relative [&>div>.loader-container]:h-[calc(100vh-18rem)] [&>div>.loader-container]:overflow-hidden">
              <EchopulseLoader />
            </div>
          )}

          {!loading &&
            taskList?.length > 0 &&
            taskList.map((t: any) => (
              <div className="py-8 border-b border-gray-400">
                <div className="grid grid-cols-12 col gap-6">
                  <div className="col-span-5">
                    <div className="max-w-[27.5rem]">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Task Name
                      </label>
                      <TextInput
                        onBlur={() => { }}
                        onChange={() => { }}
                        onFocus={() => { }}
                        onKeyPress={() => { }}
                        placeholder=""
                        value={t?.taskSummary}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div>
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Members
                      </label>
                      <div onClick={() => handleInvite(t.id)}>
                        <div className="flex justify-start items-center group relative mt-4 cursor-pointer">
                          {t?.teamMembersList?.length > 0 &&
                            t?.teamMembersCount === 1 ? (
                            <div className="w-8 h-8 rounded-full overflow-hidden">
                              {t.teamMembersList[0]?.profilePic ? (
                                <img
                                  src={t.teamMembersList[0]?.profilePic}
                                  alt="defaultImage"
                                  className="w-full h-full object-cover"
                                />
                              ) : (
                                <img
                                  src={getInitialsInName(
                                    t.teamMembersList[0]?.name ?? "N A",
                                    24
                                  )}
                                  alt={t.teamMembersList[0]?.name}
                                  className="w-full h-full object-cover"
                                />
                              )}
                            </div>
                          ) : t?.teamMembersList?.length > 0 &&
                            t?.teamMembersCount === 2 ? (
                            <>
                              <div className="w-8 h-8 rounded-full overflow-hidden">
                                {t.teamMembersList[0]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[0]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[0]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[0]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                              <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                {t.teamMembersList[1]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[1]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[1]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[1]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                            </>
                          ) : t?.teamMembersList?.length > 0 &&
                            t?.teamMembersCount === 3 ? (
                            <>
                              <div className="w-8 h-8 rounded-full overflow-hidden">
                                {t.teamMembersList[0]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[0]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[0]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[0]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                              <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                {t.teamMembersList[1]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[1]?.name}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[1]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[1]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                              <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                {t.teamMembersList[2]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[2]?.name}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[2]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[2]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                            </>
                          ) : t?.teamMembersList?.length > 0 &&
                            t?.teamMembersCount > 3 ? (
                            <>
                              <div className="w-8 h-8 rounded-full overflow-hidden">
                                {t.teamMembersList[0]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[0]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[0]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[0]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                              <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                {t.teamMembersList[1]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[1]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[1]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[1]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                              <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                {t.teamMembersList[2]?.profilePic ? (
                                  <img
                                    src={t.teamMembersList[2]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMembersList[2]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMembersList[2]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                            </>
                          ) : null}
                          {t?.teamMembersCount > 3 ? (
                            <div className="rounded-full overflow-hidden ms-1 first:ms-0 flex justify-center items-center text-primary-100 text-200 font-bold">
                              <span>{`+ ${t?.teamMembersCount - 3}`}</span>
                            </div>
                          ) : t?.teamMembersCount === 0 ? (
                            <div className="w-8 h-8 rounded-full overflow-hidden -ms-3 first:ms-0 bg-gray-600 flex justify-center items-center text-primary-100 text-200 font-bold">
                              <span>0</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div>
                      <label className="text-400 font-medium text-gray-300 block mb-4">
                        Task Key
                      </label>
                      <div className="text-gray-300 text-500 font-normal">
                        {t?.taskKey}
                      </div>
                    </div>
                  </div>
                  {selectedListType !== "3" && (
                    <div className="col-span-2 text-right">
                      <div>
                        <label className="text-400 font-medium text-gray-300 block mb-4">
                          Actions
                        </label>
                        <ActionCustomDropdown
                          onSelect={handleDropdownSelect}
                          id={t?.id}
                          userList={userList}
                          projectData={projectData}
                          taskList={taskList}
                          getTask={getTask}
                          projectId={id}
                          listTypeValue={listTypeValue}
                          searchString={userSearchString}
                          setSearchString={setUserSearchString}
                          selectedMember={selectedMember}
                          setSelectedMember={setSelectedMember}
                          fetchUserListCustomRequest={fetchUserListCustomRequest}
                          userProfile={userProfile}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

          {!loading && taskList?.length === 0 && (
            <div className="h-[calc(100%-5.313rem)] w-full flex justify-center items-center">
              <div className="text-center w-[16.875rem]">
                <div className="flex justify-center items-center">
                  <img
                    src={selectedListType === "1" ? activeTaskEmpty : selectedListType === "2" ? closeTaskEmpty : deleteTaskEmpty}
                    alt="emptyStateImage"
                    className="w-[8.75rem] h-[8.75rem] object-cover"
                  />
                </div>
                <Typography className="text-gray-700 font-normal mt-2.5 text-center" size="lg" variant="p">
                  {selectedListType === "1" && "Ready to start something amazing? Dive into your Active Tasks now!"}
                  {selectedListType === "2" && "Tasks Closed: Awaiting the Next Task to Unfold."}
                  {selectedListType === "3" && "Nothing to see here! This task has been Deleted."}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white py-3 px-6 rounded-b-md">
          {" "}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={pageSize}
            totalItems={count}
            onPageChange={handlePageChange}
            setFilterss={setFilterss}
            filterss={filterss}
            pageTitle={"Task"}
          />
        </div>
      </div>

      {addTask && (
        <AddProjectTaskModal
          onClose={() => setAddTask(false)}
          userList={userList}
          setAddTask={setAddTask}
          projectId={id}
          getTask={getTask}
          searchString={userSearchString}
          setSearchString={setUserSearchString}
          fetchUserListCustomRequest={fetchUserListCustomRequest}
          userProfile={userProfile}
        />
      )}

      {inviteMember && (
        <InvitedMemberModal
          onClose={() => setInviteMember(false)}
          taskId={taskId}
        />
      )}
    </div>
  );
};

export default ViewProject;
