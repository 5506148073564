import React from "react";
import Typography from "../../components/Typography/Typography";
import OngoingProjectEmptyImage from "../../images/ongoingProjectEmpty.svg";

const OnGoingProjectEmpty = () => {
  return (
    <div className="border border-gray-100 shadow-300  py-6 ps-0 pe-2 bg-white rounded-md relative mt-6 h-[24.1rem]" id="dashboardOngoProjectsEmpty">
      <div className="flex justify-between items-center relative ps-6 mb-2">
        <Typography variant="h5" className="text-gray-300 font-bold">
          Ongoing Projects
        </Typography>
      </div>
      <div className="h-[calc(100%-2rem)] overflow-auto pe-4 ps-6 flex justify-center items-center text-center">
        <div>
          <div className="flex justify-center items-center">
            <img
              src={OngoingProjectEmptyImage}
              alt="OngoingProjectEmptyImage"
              className="w-[6.938rem] h-[6.938rem]"
            />
          </div>
          <Typography
            className="text-gray-700 font-normal mt-2.5 w-[20.688rem]"
            size="lg"
            variant="p"
          >
            No Active Projects This Week. Ongoing Projects will be listed here.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OnGoingProjectEmpty;
