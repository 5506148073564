import React, { useState, useEffect, useRef } from "react";
import Menu from "../../components/Menu/Menu";
import { useAppDispatch } from "../../hooks";
import { getUserProfileAsync } from "../../services/reducers/appReducer";
import HeaderEchoPulse from "./HeaderEchoPulse";
import { getActiveMenu } from "../../utils/common";
import { useLocation } from "react-router-dom";

interface MenuProps {
  children: React.ReactNode;
}

const MenuEchoPulse: React.FC<MenuProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const [isLoading, setIsLoading] = useState(true);
  const isFetching = useRef(false);
  const isActive = getActiveMenu(pathname[1]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    if (isFetching.current) return; 

    isFetching.current = true;
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
        setIsLoading(false);
        isFetching.current = false;
      }
    });
  };

  const navigationItems = [
    {
      icon: "property1-default",
      label: "Dashboard",
      link: "/",
    },
    ...(userProfile?.roleName === "Super Admin" ||
    userProfile?.roleName === "Admin" ||
    userProfile?.isReportingManager
      ? [
          {
            icon: "property10-default",
            label: "Team Dashboard",
            link: "/teams",
            sublinks: [
              {
                icon: "property10-default",
                label: "Team Dashboard",
                link: "/teams/:id",
              },
              {
                icon: "property10-default",
                label: "Team Screenshots",
                link: "/teams/:identityId/:date/screenshots",
              },
            ],
          },
        ]
      : []),
    {
      icon: "property6-default",
      label: "Time Tracker",
      link: "/timeTracker",
    },
    {
      icon: "property7-default",
      label: "Projects",
      link: "/projects",
      sublinks: [
        {
          icon: "property7-default",
          label: "Projects",
          link: "/projects/:id",
        },
      ],
    },
    {
      icon: "property11-default",
      label: "Screenshots",
      link: "/screenshots",
    },
    {
      icon: "property12-default",
      label: "Time Off",
      link: "/timeoff",
      sublinks: [
        {
          icon: "property12-default",
          label: "Time Off",
          link: "/timeoff/regular",
        },
      ],
    },
    {
      icon: "property9-default",
      label: "Settings",
      link: "/settings",
    },
  ];

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div className="flex justify-start items-start" id="echoPulseFull">
      <Menu
        navigationItems={navigationItems}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div
        className={`${
          isOpen
            ? `bg-gray-400 min-h-[100vh] ms-[14rem] w-[calc(100%-14rem)] transition-all ease-in-out duration-200`
            : `bg-gray-400 min-h-[100vh] ms-[4.5rem] w-[calc(100%-4.5rem)] transition-all ease-in-out duration-200`
        }`}
      >
        <HeaderEchoPulse title={isActive} isOpen={isOpen} userProfile={userProfile}/>
        <div id="pageMainArea"
          className={`${
            isActive === "Time Off" ? "mt-[5rem]" : `px-10 py-6 mt-[5rem]`
          } `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MenuEchoPulse;
