import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  convertHourMinuteToMinutes,
  convertMinutesToHoursAndMinutes,
  formatDate,
} from "../../utils/common";
import {
  getListAsync,
  getNoteListAsync,
  updateNoteAsync,
  updateTimesheetAsync,
} from "../../services/reducers/timeReducer";
import Timer from "../../components/Timer/Timer";
import Notification from "../../components/Notification/Notification";
import ClearNoteConfirmModal from "./ClearNoteConfirmModal";
import TimeTrackerEmpty from "./TimeTrackerEmpty";
import ColorLabel from "../TimeOff/ColorLable";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../services/sharedService/constants";
import AddTaskModal from "./AddTaskModal";

type Props = {
  startDaily: any;
  userDetail: string;
  activeTab: string;
};

const TimeTrackerDaily: React.FC<Props> = ({
  startDaily,
  userDetail,
  activeTab,
}) => {
  const dispatch = useAppDispatch();
  const [clearNoteConfirm, setClearNoteConfirm] = useState<boolean>(false);
  const [dailyList, setDailyList] = useState<any>([]);
  const [dailyAction, setDailyAction] = useState<any>([]);
  const [dailyCount, setDailyCount] = useState();
  const [taskList, setTaskList] = useState<any>([]);
  const [timeoffList, setTimeoffList] = useState<any>([]);
  const { getIntegrationList } = useAppSelector((state: any) => state.app);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showNotification1, setShowNotification1] = useState<boolean>(false);
  const [showNotificationLock, setShowNotificationLock] =
    useState<boolean>(false);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isThisWeek, setIsThisWeek] = useState(false);
  const currentDate = new Date();
  const [addTask, setAddTask] = useState<boolean>(false);
  const [notes, setNotes] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [getNote, setGetNote] = useState("");
  const [getTimeLoged, setGetTimeLoged] = useState(0);
  // const [newNote, setNewNote] = useState<string>("");
  const [timeSheetIds, setTimeSheetIds] = useState<string>("");
  const isFetching = useRef(false);
  const [newNote, setNewNote] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (activeTab === "tab1") {
      getDailyList();
    }
  }, [activeTab, startDaily]);

  // const toggleSectionAccordion = (index: number) => {
  //   setOpenSections((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));

  //   if (!openSections[index]) {
  //     const timeSheetId = dailyList[index].tasksList.map(
  //       (task: any) => task.timeSheetId
  //     );
  //     dispatch(getNoteListAsync(timeSheetId)).then((res) => {
  //       if (res.payload) {
  //         setNotes(res.payload);
  //       }
  //     });
  //   }
  // };
  const toggleSectionAccordion = (index: number) => {
    if (!openSections[index]) {
      const timeSheetId = dailyList[index].tasksList.map(
        (task: any) => task.timeSheetId
      );
      dispatch(getNoteListAsync(timeSheetId)).then((res) => {
        if (res.payload && res.payload.length > 0) {
          setOpenSections((prevState) => {
            const newState = Object.keys(prevState).reduce(
              (acc: any, key: any) => {
                acc[key] = false;
                return acc;
              },
              {}
            );
            return {
              ...newState,
              [index]: true,
            };
          });
          setNotes(res.payload);
        }
      });
    } else {
      // Close the section if it is currently open
      setOpenSections((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    }
  };

  const getDailyList = () => {
    if (isFetching.current) return;

    isFetching.current = true;
    const request: any = {
      StartDate: formatDate(startDaily),
      EndDate: "",
      PlatformId:
        getIntegrationList?.records?.filter((item: any) => item?.platform)[0]
          ?.platform === "Jira"
          ? 0
          : 1,
      PageSize: 250,
      ViewType: 2,
      Page: 1,
    };
    dispatch(getListAsync(request)).then((res) => {
      if (res.payload) {
        setDailyList(res?.payload?.item1?.records);
        setDailyAction(res?.payload?.item2);
        setDailyCount(res?.payload?.item1?.totalRecords);
        setTaskList(res?.payload?.item1?.records?.flatMap((t: any) => t.tasksList));
        if (res?.payload?.item1?.records?.length > 0 && res?.payload?.item1?.records[0]?.tasksList) {
          setTimeoffList(
            res?.payload?.item1?.records[0]?.tasksList?.flatMap((t1: any) => t1.timeOffList)
          );
        } else {
          setTimeoffList([]);
        }
        isFetching.current = false;
      }
    });
  };

  // const handleInput = (e: any) => {
  //   const selection = window.getSelection();
  //   const anchorOffset = selection?.anchorOffset || 0;
  //   const validInput = e.currentTarget.textContent;

  //   if (validInput === "-") {
  //     e.currentTarget.textContent = "";
  //     return;
  //   }

  //   if (selection) {
  //     const firstChild = e.currentTarget.firstChild;

  //     if (firstChild) {
  //       const range = document.createRange();
  //       const offset = Math.min(anchorOffset, validInput.length);
  //       range.setStart(firstChild, offset);
  //       range.setEnd(firstChild, offset);
  //       selection.removeAllRanges();
  //       selection.addRange(range);
  //     }
  //   }

  //   if (validInput !== "") {
  //     e.currentTarget.textContent = validInput;
  //   }
  // };
  const handleInput = (e: any) => {
    const selection = window.getSelection();
    const anchorOffset = selection?.anchorOffset || 0;
    const currentValue = e.currentTarget.textContent;

    // Sanitize input to allow only numbers, 'h', 'm', and spaces
    const sanitizedInput = currentValue.replace(/[^0-9hm\s]/g, "");

    // Set sanitized input back to the element
    e.currentTarget.textContent = sanitizedInput;

    // Ensure the caret remains at the correct position
    if (selection) {
      const firstChild = e.currentTarget.firstChild;
      if (firstChild) {
        const range = document.createRange();
        const offset = Math.min(anchorOffset, sanitizedInput.length);
        range.setStart(firstChild, offset);
        range.setEnd(firstChild, offset);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const onFocus = (e: any) => {
    if (e.currentTarget.textContent === "-") {
      e.currentTarget.textContent = "";
    }
    e.currentTarget.dataset.prevValue = e.currentTarget.textContent.trim();
  };

  const onBlur = (
    e: any,
    taskId: string,
    date: string,
    timeSheetId: string
  ) => {
    const inputValue = e.currentTarget.textContent.trim();
    const prevValue = e.currentTarget.dataset.prevValue;

    if (inputValue === "-") {
      e.currentTarget.textContent = "";
    }

    if (inputValue !== prevValue) {
      const newValue = inputValue === "" ? 0 : inputValue;
      const editableDiv = document.getElementById(`test${timeSheetId}`);

      if (editableDiv) {
        editableDiv.textContent =
          inputValue !== "" ? convertMinutesToHoursAndMinutes(newValue) : "-";
      }

      handleTimeSpentEdit(taskId, date, timeSheetId, newValue);
    } else {
      if (inputValue === "") {
        e.currentTarget.textContent = "-";
      } else {
        e.currentTarget.textContent = prevValue;
      }
    }
  };

  const handleMouseEnter = () => {
    setShowNotification(false);
    setShowNotification1(false);
    setShowNotificationLock(false);
  };

  const handleMouseLeave = () => {
    setShowNotification(false);
    setShowNotification1(false);
    setShowNotificationLock(false);
  };

  const timerRunningState = (task: any) => {
    const activeTimerItem = task.find((item: any) => item.isTimerActive);

    return activeTimerItem;
  };

  const deactivateTimmer = () => {
    setTimerRunning(true);
    getDailyList();
  };

  const handleTimePlay = (
    task: any,
    isTimerActive: boolean,
    identifier: string
  ) => {
    setShowNotification(false);
    setShowNotification1(false);
    setShowNotificationLock(false);
    // Extract year, month, and day
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");

    // Form the "yyyy-mm-dd" format
    const formattedDate = year + "-" + month + "-" + day;
    const timesheet = task.tasksList.find((m: any) => m.date === formattedDate);

    if (timesheet?.date && timesheet?.timeSheetId) {
      if (getParentTimeEstimate(timesheet.date, timesheet.timeSheetId) === 0) {
        setShowNotification(true);
      } else if (task.isTaskOpen === false) {
        setShowNotification1(true);
      } else if (timesheet.project.projectStatus === 4) {
        setShowNotificationLock(true);
      } else {
        setShowNotification(false);
        setShowNotification1(false);
        setShowNotificationLock(false);
        timerRunningState(task?.tasksList);
        const values = {
          ...timesheet,
          isTimerActive: isTimerActive,
          timeSpent: timesheet.timerRunning,
        };

        dispatch(updateTimesheetAsync(values)).then((res) => {
          getDailyList();
        });
      }
    }
  };

  const getParentTimeEstimate = (
    targetDate: string,
    targetTimesheetId: string
  ) => {
    for (const parentTask of dailyList) {
      for (const task of parentTask.tasksList) {
        if (
          task.date === targetDate &&
          task.timeSheetId === targetTimesheetId
        ) {
          const editableDiv = document.getElementsByClassName(
            `test${targetDate}_${targetTimesheetId}`
          );
          if (parentTask.timeEstimate === 0 && editableDiv.length > 0) {
            Array.from(editableDiv).forEach((editableDivElement: Element) => {
              if (editableDivElement instanceof HTMLElement) {
                editableDivElement.textContent = "-";
              }
            });
          }

          return parentTask.timeEstimate;
        }
      }
    }
    return 0;
  };

  const handleTimeSpentEdit = (
    taskId: string,
    date: string,
    timeSheetId: string,
    newValue: number,
    newNote?: string
  ) => {
    const updatedList = dailyList.map((task: any) => {
      if (task.identifier === taskId) {
        const updatedTasksList = task.tasksList.map((t1: any) => {
          if (t1.date === date) {
            return { ...t1, timeSpent: newValue || 0 };
          }
          return t1;
        });

        return { ...task, tasksList: updatedTasksList };
      }
      return task;
    });

    const updatedTask = updatedList.find(
      (task: any) => task.identifier === taskId
    );
    const values = {
      timeSheetId,
      date,
      timeSpent: convertHourMinuteToMinutes(
        `${updatedTask?.tasksList.find((t1: any) => t1.date === date)
          ?.timeSpent || "00h00m00s"
        }`
      ),
      identifier: taskId,
      notes: newNote || "",
    };

    const content =
      values.timeSpent > 0
        ? convertMinutesToHoursAndMinutes(values.timeSpent)
        : "-";

    // Update the content of the div
    const editableDiv = document.getElementById(`test${timeSheetId}`);
    if (editableDiv) {
      editableDiv.textContent = content;
    }

    dispatch(updateTimesheetAsync(values)).then(() => {
      toast.success(`Note added successfully.`, TOASTIFY_CONF);
      getDailyList();
    });
  };

  const isCurrentDay = (taskDate: Date) => {
    const taskDateObj = new Date(taskDate);
    return (
      taskDateObj.getDate() === currentDate.getDate() &&
      taskDateObj.getMonth() === currentDate.getMonth() &&
      taskDateObj.getFullYear() === currentDate.getFullYear()
    );
  };

  useEffect(() => {
    const startOfWeek = new Date(startDaily);
    setIsThisWeek(isCurrentDay(startOfWeek));
  }, [startDaily]);

  const handleNoteEdit = (e: any, noteId: string, index: number) => {
    const newValue = e.currentTarget.textContent;
    const values = {
      noteId: noteId,
      notes: newValue,
    };
    dispatch(updateNoteAsync(values)).then(() => {
      setOpenSections({});
    });
  };

  const handleDelete = (
    id: string,
    note: string,
    timeLoged: number,
    index: number
  ) => {
    setClearNoteConfirm(true);
    setDeleteItemId(id);
    setGetNote(note);
    setGetTimeLoged(timeLoged);
    setOpenSections({});
  };

  const handleNoteEdit2 = (
    e: any,
    taskId: string,
    date: string,
    timeSheetId: string
  ) => {
    const inputValue = e.currentTarget.textContent.trim();
    const prevValue = e.currentTarget.dataset.prevValue;

    if (inputValue === "-") {
      e.currentTarget.textContent = "";
    }

    if (inputValue !== prevValue) {
      const newValue = inputValue === "" ? 0 : inputValue;
      const editableDiv = document.getElementById(`test${timeSheetId}`);

      if (editableDiv) {
        editableDiv.textContent =
          inputValue !== "" ? convertMinutesToHoursAndMinutes(newValue) : "-";
      }

      handleTimeSpentEdit(
        taskId,
        date,
        timeSheetId,
        newValue,
        newNote[timeSheetId]
      );
      setNewNote((prev) => ({ ...prev, [timeSheetId]: "" }));
    } else {
      // If the field is clicked outside without any value, show the hyphen
      if (inputValue === "") {
        e.currentTarget.textContent = "-";
      } else {
        e.currentTarget.textContent = prevValue;
      }
    }
  };

  let taskTotal = 0;
  let isFirstTask = true;

  dailyList?.forEach((task: any) => {
    let taskTime = 0;
    task.tasksList.forEach((item: any) => {
      taskTime += item.timeSpent;
      // console.log(`Adding time spent for ${item.date}: ${item.timeSpent} minutes`);

      item?.timeOffList?.forEach((timeOff: any) => {
        if (isFirstTask) {
          taskTime += timeOff.approvedTimeOffInMin;
          console.log(
            `Adding time off for ${timeOff.timeOffDate}: ${timeOff.approvedTimeOffInMin} minutes`
          );
        }
      });
    });

    // console.log(`Total time for task ${task.key}: ${taskTime} minutes`);
    taskTotal += taskTime;

    if (isFirstTask) {
      isFirstTask = false;
    }
  });

  const timesheetActions = dailyList.map(
    (record: any) => record.timeSheetActionStatus
  );
  const lastAction = timesheetActions[timesheetActions.length - 1];
  // const hasPushOrNullTimesheet = dailyList.some(
  //   // (t: any) =>
  //   //   t.timeSheetActionStatus === 1 || t.timeSheetActionStatus === null || (t.timeSheetActionStatus !== 0 && t.timeSheetActionStatus !== 2)
  //   (t: any) =>
  //     lastAction === 1 ||
  //     lastAction === null ||
  //     (lastAction !== 0 && lastAction !== 2)
  // );
  const hasPushOrNullTimesheet = dailyAction === 1 || dailyAction === null ||
    (dailyAction !== 0 && dailyAction !== 2);

  return (
    <div className="relative" id="timeTrackerDaily">
      {hasPushOrNullTimesheet && (
        <div className="absolute right-[135px] -top-[53px] z-10">
          <button
            className="flex justify-center items-center gap-0.5 text-400 text-white font-bold bg-primary-100 px-3 border border-primary-100 py-[0.563rem] rounded-md transition-all hover:bg-primary-200 hover:shadow-100"
            onClick={() => setAddTask(true)}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 10H15"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 15V5"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            Add Task
          </button>
        </div>
      )}
      <div className="border border-gray-100 shadow-300 bg-white rounded-md relative min-h-[8rem] pb-6 h-[calc(100vh-11.1rem)] overflow-auto">
        <div className="h-[3.75rem] bg-gray-200 rounded-tr-md sticky left-0 top-0 z-50 ps-8 pe-6">
          <div className="flex justify-start h-full">
            <div className="flex items-center justify-start w-[calc(100%-18.75rem)]">
              <div className="text-500 font-bold text-gray-300">
                Tasks({dailyCount})
              </div>
            </div>
            <div className="flex justify-start items-center">
              <div className="w-[5rem] text-center">
                <div className="text-400 text-gray-300 font-normal">Hours</div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[3.75rem] bg-white rounded-tr-md ps-8 pe-6 sticky left-0 z-50 top-[3.75rem] block">
          <div className="flex justify-start h-full">
            <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full">
              <div className="text-400 font-normal text-gray-300">
                Total hours
              </div>
            </div>
            <div className="flex justify-start items-center">
              <div className="w-[5rem] text-center">
                <div className={`text-400 ${timeoffList?.length > 0 ? 'text-primary-100' : 'text-gray-300'} font-bold`}>
                  {/* {convertMinutesToHoursAndMinutes(
                    dailyList.reduce((total: number, d: any) => {
                      return (
                        total +
                        d.tasksList.reduce(
                          (taskTotal: number, t: any) =>
                            taskTotal + (t.timeSpent > 0 ? t.timeSpent : 0),
                          0
                        )
                      );
                    }, 0)
                  )} */}
                  {convertMinutesToHoursAndMinutes(taskTotal)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {timeoffList?.length > 0 && (
          <>
            {timeoffList.map((t1: any, index: number) => (
              <>
                {t1?.timeOffType === "Holiday" && (
                  <div className="bg-white rounded-tr-md ps-8 pe-6 block mb-2.5 relative h-[3.5rem]">
                    <ColorLabel text={t1?.timeOffType} className="absolute !h-9 !w-[0.1875rem] !rounded-lg !left-0 !top-1/2 -translate-y-1/2" />
                    <div className="flex justify-start h-full">
                      <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full bg-opacity-10 ps-2 py-1.5 rounded-l-md">
                        <div className="text-300 text-gray-300 font-semibold">
                          {t1?.title ?? t1?.timeOffModeType}
                        </div>
                      </div>
                      <div className="flex justify-start items-center bg-opacity-10 py-1.5 rounded-r-md">
                        <div className="w-[5rem] text-center">
                          <div className="text-300 text-secondary-100 font-bold">
                            {convertMinutesToHoursAndMinutes(
                              t1?.approvedTimeOffInMin
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                )}
                {t1?.timeOffType === "Leave" && (
                  <div className="bg-white rounded-tr-md ps-8 pe-6 block mb-2.5 relative h-[3.5rem]">
                    <ColorLabel text={t1?.timeOffModeType} className="absolute !h-9 !w-[0.1875rem] !rounded-lg !left-0 !top-1/2 -translate-y-1/2" />
                    <div className="flex justify-start h-full">
                      <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full bg-opacity-10 ps-2 py-1.5 rounded-l-md">
                        <div className="text-300 text-gray-300 font-semibold">
                          {t1?.timeOffModeType}
                        </div>
                      </div>
                      <div className="flex justify-start items-center bg-opacity-10 py-1.5 rounded-r-md">
                        <div className="w-[5rem] text-center">
                          <div className="text-300 text-secondary-100 font-bold">
                            {convertMinutesToHoursAndMinutes(
                              t1?.approvedTimeOffInMin
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
        )} */}
        {timeoffList?.length > 0 && (
          <>
            {timeoffList.map((t1: any, index: number) => {
              const isHoliday = t1?.timeOffType === "Holiday";
              const isLeave = t1?.timeOffType === "Leave";

              if (isHoliday) {
                return (
                  <div key={index} className="bg-white rounded-tr-md ps-8 pe-6 block mb-2.5 relative h-[3.5rem]">
                    <ColorLabel text={t1?.timeOffType} className="absolute !h-9 !w-[0.1875rem] !rounded-lg !left-0 !top-1/2 -translate-y-1/2" />
                    <div className="flex justify-start h-full">
                      <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full bg-opacity-10 ps-2 py-1.5 rounded-l-md">
                        <div className="text-300 text-gray-300 font-semibold">
                          {t1?.title ?? t1?.timeOffModeType}
                        </div>
                      </div>
                      <div className="flex justify-start items-center bg-opacity-10 py-1.5 rounded-r-md">
                        <div className="w-[5rem] text-center">
                          <div className="text-300 text-secondary-100 font-bold">
                            {convertMinutesToHoursAndMinutes(t1?.approvedTimeOffInMin)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              if (isLeave) {
                return (
                  <div key={index} className="bg-white rounded-tr-md ps-8 pe-6 block mb-2.5 relative h-[3.5rem]">
                    <ColorLabel text={t1?.timeOffType} className="absolute !h-9 !w-[0.1875rem] !rounded-lg !left-0 !top-1/2 -translate-y-1/2" />
                    <div className="flex justify-start h-full">
                      <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full bg-opacity-10 ps-2 py-1.5 rounded-l-md">
                        <div className="text-300 text-gray-300 font-semibold">
                          {t1?.title ?? t1?.timeOffModeType}
                        </div>
                      </div>
                      <div className="flex justify-start items-center bg-opacity-10 py-1.5 rounded-r-md">
                        <div className="w-[5rem] text-center">
                          <div className="text-300 text-secondary-100 font-bold">
                            {convertMinutesToHoursAndMinutes(t1?.approvedTimeOffInMin)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              return null;
            })}
          </>
        )}

        {/* {timeoffList?.length > 0 && timeoffList?.timeOffType === "Leave" ? (
          <div className="bg-white ps-8 pe-6">
            {timeoffList.map((t1: any, index: number) => (
              <div className="flex justify-start border-b border-gray-400 h-full py-4">
                <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full gap-3">
                  {t1?.timeOffType === "Leave" && (
                    //   <div>
                    //     <div className="bg-secondary-100 text-white px-2.5 py-1 text-100 font-normal rounded-full">
                    //       {t1?.timeOffModeType}
                    //       <span className="font-bold ms-2">
                    //         {t1.approvedTimeOff}d
                    //       </span>
                    //     </div>
                    //     <div className="mt-1 uppercase text-[9px] font-nromal text-gray-500 px-2.5">
                    //       {t1.title}
                    //     </div>
                    //   </div>
                    // ) : (
                    <div>
                      <div className="bg-green-100 text-white px-2.5 py-1 text-100 font-normal rounded-full">
                        {t1?.timeOffModeType}
                        <span className="font-bold ms-2">
                          {t1.approvedTimeOff}d
                        </span>
                      </div>
                      <div className="flex justify-start items-start gap-1 mt-1">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12Z"
                              fill="#2EB67D"
                            />
                            <path
                              d="M3.4502 6.00006L5.1482 7.69806L8.5502 4.30206"
                              fill="#2EB67D"
                            />
                            <path
                              d="M3.4502 6.00006L5.1482 7.69806L8.5502 4.30206"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="uppercase text-[9px] font-nromal text-gray-500">
                          Approved
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex justify-start items-center">
                  <div className="w-[5rem] text-center">
                    {t1?.timeOffType === "Leave" && (
                      <div className="text-400 text-primary-100 font-bold">
                        {convertMinutesToHoursAndMinutes(
                          t1?.approvedTimeOffInMin
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : ( */}
        {dailyList &&
          dailyList.map(
            (t: any, index: number) =>
              t.identifier !== "" ? (
                <div className={`ps-8 pe-6 ${openSections[index] ? "" : ""}`}>
                  <div className="border-b border-gray-400">
                    <div>
                      <div className="flex justify-start items-center gap-2 w-full">
                        <div
                          className="cursor-pointer mb-4"
                          onClick={() => toggleSectionAccordion(index)}
                        >
                          <svg
                            style={{
                              transform: openSections[index]
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.28 5.96668L8.9333 10.3133C8.41997 10.8267 7.57997 10.8267 7.06664 10.3133L2.71997 5.96667"
                              stroke="#292D32"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="relative w-full group flex justify-start items-center py-4 h-[4.875rem] bg-white rounded-tr-md after:absolute after:h-9 after:w-[0.1875rem] after:bg-green-100 after:top-1/2 after:-translate-y-1/2 after:-left-[3.5rem] after:rounded-e-md group">
                          <div className="flex items-center justify-start w-[calc(100%-18.75rem)] h-full">
                            <div>
                              <div className="text-400 font-bold text-gray-300 flex items-center me-2">
                                <span className="line-clamp-1">
                                  {t?.summary}
                                </span>

                                {timerRunningState(t.tasksList) ? (
                                  <span
                                    className="ms-2.5 cursor-pointer invisible group-hover:visible"
                                    onClick={() => {
                                      handleTimePlay(t, false, t.identifier);
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {t.timeSheetActionStatus !== 0 && isThisWeek && (
                                      <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g id="vuesax/bold/stop-circle">
                                          <g id="stop-circle">
                                            <path
                                              id="Vector"
                                              d="M8.47884 1.41663C4.56884 1.41663 1.39551 4.58996 1.39551 8.49996C1.39551 12.41 4.56884 15.5833 8.47884 15.5833C12.3888 15.5833 15.5622 12.41 15.5622 8.49996C15.5622 4.58996 12.3959 1.41663 8.47884 1.41663ZM11.4963 9.37121C11.4963 10.547 10.5472 11.4962 9.37134 11.4962H7.62884C6.45301 11.4962 5.50384 10.547 5.50384 9.37121V7.62871C5.50384 6.45288 6.45301 5.50371 7.62884 5.50371H9.37134C10.5472 5.50371 11.4963 6.45288 11.4963 7.62871V9.37121Z"
                                              fill="#F15845"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2.5 cursor-pointer invisible group-hover:visible"
                                    onClick={() => {
                                      handleTimePlay(t, true, t.identifier);
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {t.timeSheetActionStatus !== 0 && isThisWeek && (
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g id="vuesax/linear/play-circle">
                                          <g id="play-circle">
                                            <path
                                              id="Vector"
                                              d="M8.97754 16.5C13.1197 16.5 16.4775 13.1421 16.4775 9C16.4775 4.85786 13.1197 1.5 8.97754 1.5C4.8354 1.5 1.47754 4.85786 1.47754 9C1.47754 13.1421 4.8354 16.5 8.97754 16.5Z"
                                              fill="#2EB67D"
                                            />
                                            <path
                                              id="Vector_2"
                                              d="M6.55518 9.17247V7.91997C6.55518 6.35997 7.65768 5.72247 9.00768 6.50247L10.0952 7.13247L11.1827 7.76247C12.5327 8.54247 12.5327 9.81747 11.1827 10.5975L10.0952 11.2275L9.00768 11.8575C7.65768 12.6375 6.55518 12 6.55518 10.44V9.17247Z"
                                              fill="white"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    )}
                                  </span>
                                )}
                              </div>

                              <div className="text-200 font-normal text-gray-700 flex mt-1">
                                <span className="max-w-[70%] line-clamp-1">
                                  {" "}
                                  {t.tasksList[0]?.project?.key} -{" "}
                                  {t.tasksList[0]?.project?.name}
                                </span>
                                <span className="ms-2.5 ps-2.5 border border-y-0 border-e-0 border-s-gray-700 line-clamp-1">
                                  {" "}
                                  #{t.key}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-start items-center h-full">
                            <div className="text-400 font-bold text-gray-300 outline-0 h-full">
                              {t.tasksList.map((t1: any, index: number) => (
                                <div
                                  key={t1.date}
                                  className={`flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md cursor-pointer hover:border-secondary-100 focus-within:border-green-100 overflow-hidden ${(t1.isDisabled === true &&
                                    lastAction !== 1) ||
                                    // t.timeSheetActionStatus === 0 ||
                                    lastAction === 0 ||
                                    t.timeSheetActionStatus === 2
                                    ? "bg-gray-800 bg-opacity-20 cursor-auto hover:border-0 focus-within:border-0"
                                    : ""
                                    }`}
                                >
                                  {t1.isTimerActive === false && (
                                    <div
                                      key={`test${t1.date}`}
                                      id={`test${t1.timeSheetId}`}
                                      className={`text-400 font-normal text-gray-300 outline-0 focus-visible:outline-none test${t1.date}_${t1.timeSheetId}`}
                                      //contentEditable={t1.isDisabled === true || !t.isTaskOpen ? false : true}
                                      // contentEditable={
                                      //   t1.isDisabled === true ? false : true
                                      // }
                                      contentEditable={
                                        (t1.isDisabled === true &&
                                          lastAction !== 1) ||
                                          t.timeSheetActionStatus === 2
                                          ? false
                                          : // : t.timeSheetActionStatus !== 0
                                          lastAction !== 0
                                            ? true
                                            : false
                                      }
                                      onInput={
                                        t1?.project?.projectStatus === 4
                                          ? () => setShowNotificationLock(true)
                                          : t.isTaskOpen
                                            ? (e: any) => {
                                              handleInput(e);
                                              const estimate =
                                                getParentTimeEstimate(
                                                  t1.date,
                                                  t1.timeSheetId
                                                );
                                              setShowNotification(
                                                estimate === 0 ? true : false
                                              );
                                            }
                                            : t.isTaskOpen === false
                                              ? () => setShowNotification1(true)
                                              : undefined
                                      }
                                      // onFocus={
                                      //   t.isTaskOpen
                                      //     ? (e: any) => {
                                      //       onFocus(e);
                                      //       setShowNotification(false);
                                      //       setShowNotification1(false);
                                      //       //setShowNotificationLock(false)
                                      //     }
                                      //     : undefined
                                      // }
                                      onBlur={
                                        t.isTaskOpen
                                          ? (e: any) => {
                                            onBlur(
                                              e,
                                              t.identifier,
                                              t1.date,
                                              t1.timeSheetId
                                            );
                                            setShowNotification(false);
                                            setShowNotification1(false);
                                          }
                                          : undefined
                                      }
                                    >
                                      {t1.timeSpent > 0
                                        ? convertMinutesToHoursAndMinutes(
                                          t1.timeSpent
                                        )
                                        : "-"}
                                    </div>
                                  )}
                                  {t1.isTimerActive === true && (
                                    <Timer
                                      initialMinutes={t1.timerRunning}
                                      identifier={t1.identifier}
                                      date={t1.date}
                                      onClick={() =>
                                        handleTimePlay(t, false, t.identifier)
                                      }
                                      deactivateTimmer={deactivateTimmer}
                                      setShowNotification={setShowNotification}
                                      setShowNotification1={
                                        setShowNotification1
                                      }
                                      setShowNotificationLock={
                                        setShowNotificationLock
                                      }
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>

                      {hasPushOrNullTimesheet && (
                        <div className="ps-6 pe-6 pb-4">
                          {t.tasksList.map(
                            (t1: any, index: number) =>
                              t1.isDisabled === false && t1.isTimerActive === false && (
                                <div
                                  key={t1.timeSheetId}
                                  className="h-[2.813rem] bg-white"
                                >
                                  <div className="flex justify-start border-gray-400 h-full relative">
                                    <div className="flex items-center justify-start w-[calc(100%-17.15rem)] h-full">
                                      <input
                                        placeholder="+ Add a Note.."
                                        name="note"
                                        value={newNote[t1.timeSheetId] || ""}
                                        onChange={(e) => {
                                          const updatedNote = {
                                            ...newNote,
                                            [t1.timeSheetId]: e.target.value,
                                          };
                                          console.log(
                                            updatedNote,
                                            "updatedNote"
                                          );
                                          setNewNote(updatedNote);
                                        }}
                                        maxLength={150}
                                        className="h-full w-[80%] focus-visible:outline-none border border-gray-400 rounded-md placeholder:italic ps-2 text-400 font-normal placeholder:text-gray-700 overflow-x-auto text-opacity-80"
                                      />
                                    </div>
                                    <div className="flex justify-start items-center h-full">
                                      <div className="flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md cursor-pointer hover:border-secondary-100 focus-within:border-green-100 overflow-hidden">
                                        <div
                                          contentEditable={
                                            newNote[t1.timeSheetId] !== "" &&
                                              getParentTimeEstimate(
                                                t1.date,
                                                t1.timeSheetId
                                              ) !== 0
                                              ? true
                                              : false
                                          }
                                          className="text-400 font-normal text-gray-300 outline-0 focus-visible:outline-none"
                                          onInput={
                                            t1?.project?.projectStatus === 4
                                              ? () =>
                                                setShowNotificationLock(true)
                                              : t.isTaskOpen
                                                ? (e: any) => {
                                                  //handleInput(e);
                                                  const estimate =
                                                    getParentTimeEstimate(
                                                      t1.date,
                                                      t1.timeSheetId
                                                    );
                                                  setShowNotification(
                                                    estimate === 0
                                                      ? true
                                                      : false
                                                  );
                                                }
                                                : t.isTaskOpen === false
                                                  ? () => setShowNotification1(true)
                                                  : undefined
                                          }
                                          onBlur={
                                            t.isTaskOpen
                                              ? (e) => {
                                                handleNoteEdit2(
                                                  e,
                                                  t.identifier,
                                                  t1.date,
                                                  t1.timeSheetId
                                                );
                                                setShowNotification(false);
                                                setShowNotification1(false);
                                              }
                                              : undefined
                                          }
                                          onFocus={
                                            t1.timeSpent > 0
                                              ? (e: any) => {
                                                onFocus(e);
                                                setShowNotification(false);
                                                setShowNotification1(false);
                                                //setShowNotificationLock(false)
                                              }
                                              : undefined
                                          }
                                        >
                                          {newNote[t1.timeSheetId]
                                            ? convertMinutesToHoursAndMinutes(0)
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </div>
                    {openSections[index] && (
                      <div className="ps-6 pe-6 border-t border-gray-400 transition-all ease-in-out duration-200">
                        {notes.map((t2: any, index: number) => (
                          <div className="h-[4.7rem] bg-white group innerAccor first:mt-2 last:mb-2">
                            <div className="flex justify-start border-gray-400 h-full py-2 relative">
                              <div className="flex items-center justify-start w-[calc(100%-17.15rem)] h-full">
                                <div
                                  className={`text-400 ${t2?.notes === "No Note Added"
                                    ? "text-primary-100 font-semibold w-[80%] line-clamp-1 focus-visible:outline-none border border-gray-400 rounded-md h-full flex justify-start items-center ps-2 py-2 text-opacity-90 overflow-auto"
                                    : "text-gray-300 font-normal w-[80%] line-clamp-1 focus-visible:outline-none border border-gray-400 rounded-md h-full flex justify-start items-center ps-2 py-2 text-opacity-80 overflow-auto"
                                    }`}
                                  contentEditable={true}
                                  onBlur={(e) =>
                                    handleNoteEdit(e, t2.id, index)
                                  }
                                >
                                  {t2?.notes}{" "}
                                </div>
                              </div>
                              <div className="flex justify-start items-center h-full">
                                <div className="flex justify-center items-center w-[5rem] h-[46px] border border-gray-400 rounded-md cursor-default">
                                  <div className="text-400 font-normal text-gray-300 outline-0">
                                    <div>
                                      {convertMinutesToHoursAndMinutes(
                                        t2?.LogedTime
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* {(t.timeSheetActionStatus !== 0 && t.timeSheetActionStatus !== 2) && ( */}
                              {lastAction !== 0 &&
                                t.timeSheetActionStatus !== 2 && (
                                  <div className="absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover:inline-block">
                                    <button
                                      className="flex justify-end items-center text-error text-200 font-normal"
                                      onClick={() =>
                                        handleDelete(
                                          t2.id,
                                          t2?.notes,
                                          t2?.LogedTime,
                                          index
                                        )
                                      }
                                    >
                                      <span className="inline-block">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                            stroke="#E01E1E"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M7.0835 4.14175L7.26683 3.05008C7.40016 2.25841 7.50016 1.66675 8.9085 1.66675H11.0918C12.5002 1.66675 12.6085 2.29175 12.7335 3.05841L12.9168 4.14175"
                                            stroke="#E01E1E"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M15.7079 7.6167L15.1663 16.0084C15.0746 17.3167 14.9996 18.3334 12.6746 18.3334H7.32459C4.99959 18.3334 4.92459 17.3167 4.83293 16.0084L4.29126 7.6167"
                                            stroke="#E01E1E"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M8.6084 13.75H11.3834"
                                            stroke="#E01E1E"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M7.9165 10.4167H12.0832"
                                            stroke="#E01E1E"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      {/* <span className="inline-block ms-0.5 -mb-0.5">
                                  Clear Note
                                </span> */}
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : timeoffList?.length === 0 ? (
                <TimeTrackerEmpty />
              ) : null
            // )
          )}
      </div>

      {showNotification && (
        <Notification
          type="warning"
          message="Unable to Log Time: Task Lacks Required Estimates. Please Review Team Policies."
        />
      )}
      {showNotification1 && (
        <Notification
          type="warning"
          message="Task Closed: Time Logging Restricted by Administrator."
        />
      )}
      {showNotificationLock && (
        <Notification
          type="warning"
          message="Project Locked: Administrator Has Restricted Time Logging."
        />
      )}
      {addTask && (
        <AddTaskModal
          onClose={() => setAddTask(false)}
          userDetail={userDetail}
          getLists={getDailyList}
          setAddTask={setAddTask}
          startDate={startDaily}
        />
      )}
      {clearNoteConfirm && (
        <ClearNoteConfirmModal
          onClose={() => setClearNoteConfirm(false)}
          deleteItemId={deleteItemId}
          getNote={getNote}
          getTimeLoged={getTimeLoged}
          timeSheetIds={timeSheetIds}
          getDailyList={getDailyList}
        />
      )}
    </div>
  );
};

export default TimeTrackerDaily;
