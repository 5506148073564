import React, { useEffect, useRef, useState } from "react";
import { Dropdown, SelectOption } from "../../components/DropDown/DropDown";
import Typography from "../../components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  createAdminSettingAsync,
  getMinutesEnumListAsync,
  getOrganizationListAsync,
  getRestrictEnumListAsync,
  getScreenshotEnumListAsync,
  getSettingListAsync,
  getTimeOffApprovalAsync,
  updateSettingAsync,
} from "../../services/reducers/adminReducer";
import SaveChanges from "./SaveChanges";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../services/sharedService/constants";
import TextInput from "../../components/TextInput/TextInput";
import { Field, Form, Formik, FormikHelpers } from "formik";
import RadioButton from "../../components/Radio button/RadioButton";
import { SearchMember } from "../admin/projects/SearchMember";
import { getUsersAsync } from "../../services/reducers/appReducer";
import Icons from "../../components/Icons/Icon";
import { useDebounce } from "../../utils/hooks/useDebounce";
import ToggleButton from "../../components/ToogleButton/ToogleButton";
import CheckBoxInput from "../../components/CheckBoxInput";
import * as Yup from "yup";
import styled from "styled-components";

export const CustomRightToolTip = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
    z-index: 9;
    cursor: default;
    width: 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 18rem;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    color: #888;
    text-align: left;
    border-radius: 0.375rem;
    padding: 0.5rem 0.5rem;
    position: absolute;
    z-index: 1;
    top: -0.85rem;
    left: 150%;
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type SettingRequest = {
  // identityId: string;
  restrictSubmissionHour: number;
  //organizationId: string;
  minutesOfInactivity: number;
  screenShotModes: number;
  noOfScreenShots: number;
  timeOffApproval: number;
  teamMembers: any;
  maximumHour: number;
  minimumHour: number;
  privacy: number;
  removeSession: boolean;
  timeSheetApproval: number;
};

type Props = {
  list: any;
};

const validationSchema = Yup.object().shape({
  minimumHour: Yup.number()
    .required("Minimum working hours are required")
    .typeError("Minimum working hours must be a number"),
  maximumHour: Yup.number()
    // .required("Maximum working hours are required")
    // .typeError("Maximum working hours must be a number")
    .when("minimumHour", (minimumHour, schema) =>
      schema.moreThan(
        Yup.ref("minimumHour"),
        "Maximum working hours can not be less than minimum working hours"
      )
    ),
  restrictSubmissionHour: Yup.number().required(
    "Restrict submission hour is required"
  ),
  minutesOfInactivity: Yup.number().required(
    "Minutes of inactivity is required"
  ),
  // screenShotModes: Yup.number().required("Screenshot mode is required"),
  // noOfScreenShots: Yup.number().required("Number of screenshots is required"),
  noOfScreenShots: Yup.number().when(
    "screenShotModes",
    (screenShotModes, schema) => {
      if (screenShotModes) {
        return schema
          .min(1, "The number of screenshots should always be more than 0")
          .max(10, "The number of screenshots cannot exceed 10")
          .required("Required");
      } else {
        return schema.oneOf(
          [0],
          "Should be 0 when 'Do Not Take Screenshots' is selected"
        );
      }
    }
  ),
  privacy: Yup.number().required("Privacy selection is required"),
  timeOffApproval: Yup.number().required(
    "Time off approval selection is required"
  ),
});

const TeamSettings: React.FC<Props> = ({ list }) => {
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedMinutes, setSelectedMinutes] = useState<string>("");
  const [selectedScreenshots, setSelectedScreenshots] = useState<string>("");
  const [selectedTimeOff, setSelectedTimeOff] = useState<string>("");
  const [settingList, setSettingList] = useState<any>([]);
  const [userLists, setUserLists] = useState<any>(null);
  const [selectedMember, setSelectedMember] = useState<any>([]);
  const [searchString, setSearchString] = useState("");
  const [userList, setUserList] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [showAll, setShowAll] = useState(false);
  const [fetchUserListCustomRequest, setFetchUserListCustomRequest] =
    useState(true);

  const getOrgnisationList = useAppSelector(
    (state: any) => state.admin.getOrgnisationList
  );
  const getRestrictList = useAppSelector(
    (state: any) => state.admin.restrictList
  );
  const getScreenshotList = useAppSelector(
    (state: any) => state.admin.screenShotList
  );
  const getMinutesList = useAppSelector(
    (state: any) => state.admin.minutesList
  );
  const gettimeOffApprovalList = useAppSelector(
    (state: any) => state.admin.timeOffApprovalList
  );
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  const isFetching = useRef(false);

  useEffect(() => {
    if (isFetching.current) return;

    isFetching.current = true;
    dispatch(getSettingListAsync()).then((res) => {
      if (res.payload) {
        setSettingList(res.payload.records?.find((item: any) => item.id));
        const currentSetting = res.payload.records.find((item: any) => item.id);
        if (currentSetting) {
          setSelectedValue(currentSetting.restrictSubmissionHour);
          setSelectedMinutes(currentSetting.minutesOfInactivity);
          setSelectedScreenshots(currentSetting.screenShotModes);
          setSelectedTimeOff(currentSetting.timeOffApproval);
          isFetching.current = false;
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getOrganizationListAsync());

    if (getRestrictList.length === 0) {
      dispatch(getRestrictEnumListAsync());
    }
    if (getMinutesList.length === 0) {
      dispatch(getMinutesEnumListAsync());
    }
    if (getScreenshotList.length === 0) {
      dispatch(getScreenshotEnumListAsync());
    }
    if (gettimeOffApprovalList.length === 0) {
      dispatch(getTimeOffApprovalAsync());
    }
  }, []);

  const handleDropdownChange = (
    value: SelectOption | SelectOption[] | null
  ) => {
    if (value && !Array.isArray(value)) {
      setSelectedValue(value.value as string);
    }
  };
  const handleMinutesChange = (value: SelectOption | SelectOption[] | null) => {
    if (value && !Array.isArray(value)) {
      setSelectedMinutes(value.value as string);
    }
  };
  const handleScreenshotChange = (
    value: SelectOption | SelectOption[] | null
  ) => {
    if (value && !Array.isArray(value)) {
      setSelectedScreenshots(value.value as string);
    }
  };
  const handleTimeOffChange = (value: SelectOption | SelectOption[] | null) => {
    if (value && !Array.isArray(value)) {
      setSelectedTimeOff(value.value as string);
    }
  };

  const userSearch = () => {
    if (isFetching.current) return;

    isFetching.current = true;
    const request = {
      page: currentPage,
      pageSize: pageSize,
      searchString: searchString,
    };
    dispatch(getUsersAsync(request)).then((res) => {
      const activeUsers = res?.payload?.users?.filter(
        (user: any) => user.status === "Active"
      );
      setUserList(activeUsers);
      // setUserList(res?.payload?.users);
      isFetching.current = false;
    });
  };
  const debouncedUserSearch = useDebounce(userSearch, 1000);

  const search = () => {
    setIsTaskLoading(true);
    const request = {
      page: 1,
      pageSize: 2000,
    };
    dispatch(getUsersAsync(request)).then((res) => {
      const activeUsers = res?.payload?.users?.filter(
        (user: any) => user.status === "Active"
      );
      setIsTaskLoading(false);
      setUserLists(activeUsers);
      // setUserLists(res?.payload?.users);
    });
  };

  useEffect(() => {
    search();
    debouncedUserSearch();
  }, [searchString]);

  const toggleShowAllMembers = () => {
    setShowAll(!showAll);
  };

  const removeMember = (memberIdToRemove: string) => {
    setSelectedMember((prevMembers: any) =>
      prevMembers.filter(
        (member: any) => member.identityId !== memberIdToRemove
      )
    );
  };
  // console.log(selectedMember, "selectedMember")
  return (
    <Formik
      enableReinitialize
      initialValues={{
        restrictSubmissionHour: settingList?.id
          ? settingList?.restrictSubmissionHour
          : "",
        minutesOfInactivity: settingList?.id
          ? settingList?.minutesOfInactivity
          : "",
        screenShotModes: settingList?.id ? settingList?.screenShotModes : "",
        noOfScreenShots: settingList?.id ? settingList.noOfScreenShots : "",
        maximumHour: settingList?.id ? settingList.maximumHour : "",
        privacy: settingList?.id ? settingList.privacy : 1,
        minimumHour: settingList?.id ? settingList.minimumHour : "",
        timeOffApproval: settingList?.id ? settingList.timeOffApproval : "",
        teamMembers: [
          {
            memberId:
              settingList?.id && settingList.teamMembersList
                ? settingList.teamMembersList.map((t: any) => t?.name)
                : [],
          },
        ],
        removeSession: settingList?.id ? settingList.removeSession : false,
        timeSheetApproval: settingList?.id ? settingList.timeSheetApproval : 0,
      }}
      validationSchema={validationSchema}
      // onSubmit={(values) => {
      onSubmit={async (
        values: SettingRequest,
        { setSubmitting }: FormikHelpers<SettingRequest>
      ) => {
        let request: any = values;
        // const filterdata = settingList.find((item: any) => item.id);
        const test = settingList?.id;
        // const privacyValue = values.privacy === "0" ? 0 : 1;
        const members = values.privacy === 0 ? selectedMember : userLists;
        // const team = members.map((member: any) => ({
        //   memberId: member.identityId,
        // }));

        if (settingList.id) {
          const reqBody: any = {
            minimumHour: values.minimumHour,
            maximumHour: values.maximumHour,
            restrictSubmissionHour: parseInt(selectedValue),
            minutesOfInactivity: parseInt(selectedMinutes),
            screenShotModes: parseInt(selectedScreenshots),
            //noOfScreenShots: values.noOfScreenShots,
            noOfScreenShots:
              values.screenShotModes === 0 ? 1 : values.noOfScreenShots,
            timeOffApproval: parseInt(selectedTimeOff),
            privacy: values.privacy,
            teamMembers: members
              ? members.map((member: any) => ({
                  memberId: member.identityId,
                }))
              : [],
            removeSession: values.removeSession,
            organizationId:
              getOrgnisationList?.records?.length > 0
                ? getOrgnisationList?.records[0]?.id
                : "",
            timeSheetApproval: values.timeSheetApproval,
          };

          dispatch(updateSettingAsync({ id: test, reqBody }));
          toast.success(`Settings Updated Successfully!`, TOASTIFY_CONF);
        } else {
          request = {
            ...request,
            restrictSubmissionHour: parseInt(selectedValue),
            minutesOfInactivity: parseInt(selectedMinutes),
            screenShotModes: parseInt(selectedScreenshots),
            timeOffApproval: parseInt(selectedTimeOff),
            // privacy: privacyValue,
            teamMembers: members.map((member: any) => ({
              memberId: member.memberId,
            })),
            removeSession: values.removeSession,
            organizationId:
              getOrgnisationList?.records?.length > 0
                ? getOrgnisationList?.records[0]?.id
                : "",
            timeSheetApproval: values.timeSheetApproval,
          };
          dispatch(createAdminSettingAsync(request));
          toast.success(`Settings Updated Successfully!`, TOASTIFY_CONF);
        }
      }}
    >
      {({ errors, touched, setFieldValue, values, isSubmitting, isValid }) => {
        return (
          <Form>
            <div className="border border-gray-100 shadow-30 bg-white rounded-md relative h-[calc(100vh-11.25rem)] overflow-auto" id="settingTeamSetting">
              <div className="px-8 py-8 h-[calc(100%-5.75rem)] overflow-auto">
                <div className="grid grid-cols-2">
                  <div className="">
                    <Typography
                      variant="h3"
                      className="text-gray-300 font-medium"
                    >
                      Time Sheet Restrictions
                    </Typography>
                    <div className="w-[27.5rem] mt-[1.875rem]">
                      <div>
                        <div className="flex justify-start items-center gap-2 mb-2">
                          <label className="text-400 font-medium text-gray-300 block">
                            Restrict Submission After Hours
                            <span className="text-primary-100 ps-0.5">*</span>
                          </label>
                          <CustomRightToolTip>
                            <div className="tooltip">
                              <div className="w-full">
                                <Icons
                                  name="info-circle-2"
                                  variant="stroke"
                                  size={18}
                                  className="-mb-1"
                                />
                              </div>
                              <span className="tooltiptext">
                                User will not be able to make edits beyond this
                                duration
                              </span>
                            </div>
                          </CustomRightToolTip>
                        </div>
                        <Field
                          name="restrictSubmissionHour"
                          render={({ field }: any) => (
                            <>
                              <Dropdown
                                {...field}
                                onChange={handleDropdownChange}
                                placeholder="Select restrict submission"
                                options={getRestrictList.map((m: any) => {
                                  return { label: m.name, value: m.id };
                                })}
                                type="box"
                                // value={{
                                //   label: `${selectedValue} Hours`,
                                //   value: selectedValue,
                                // }}
                                value={{
                                  label:
                                    Number(selectedValue) === 0
                                      ? "Not Restricted"
                                      : `${selectedValue} Hours`,
                                  value: selectedValue,
                                }}
                                error={errors.restrictSubmissionHour}
                                //value={getRestrictList?.find((option: any) => option.id === values.restrictSubmissionHour)?.label}
                              />
                            </>
                          )}
                        />
                      </div>
                      <div className="mt-[1.875rem]">
                        <div className="flex justify-start items-center gap-2 mb-2">
                          <label className="text-400 font-medium text-gray-300 block">
                            Minimum Working Hours per day
                            <span className="text-primary-100 ps-0.5">*</span>
                          </label>
                          <CustomRightToolTip>
                            <div className="tooltip">
                              <div className="w-full">
                                <Icons
                                  name="info-circle-2"
                                  variant="stroke"
                                  size={18}
                                  className="-mb-1"
                                />
                              </div>
                              <span className="tooltiptext">
                                Entries below minimum working hours will be
                                marked as insufficient
                              </span>
                            </div>
                          </CustomRightToolTip>
                        </div>

                        <Field
                          name="minimumHour"
                          render={({ field }: any) => (
                            <>
                              <TextInput
                                {...field}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const value = event.target.value.replace(
                                    /\D/,
                                    ""
                                  );
                                  setFieldValue("minimumHour", value);
                                }}
                                placeholder="Minimum Working Hours"
                                type="number"
                                value={field.value}
                                error={errors.minimumHour}
                              />
                            </>
                          )}
                        />
                      </div>
                      <div className="mt-[1.875rem]">
                        <div className="flex justify-start items-center gap-2 mb-2">
                          <label className="text-400 font-medium text-gray-300 block">
                            Maximum Working Hours per day
                          </label>
                          <CustomRightToolTip>
                            <div className="tooltip">
                              <div className="w-full">
                                <Icons
                                  name="info-circle-2"
                                  variant="stroke"
                                  size={18}
                                  className="-mb-1"
                                />
                              </div>
                              <span className="tooltiptext">
                                Entries beyond maximum working hours will be
                                marked as overtime
                              </span>
                            </div>
                          </CustomRightToolTip>
                        </div>
                        <Field
                          name="maximumHour"
                          render={({ field }: any) => (
                            <>
                              <TextInput
                                {...field}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const value = event.target.value.replace(
                                    /\D/,
                                    ""
                                  );
                                  setFieldValue("maximumHour", value);
                                }}
                                placeholder="Maximum Working Hours"
                                type="number"
                                value={field.value}
                              />
                              {errors.maximumHour && touched.maximumHour && (
                                <div className="text-primary-100 text-200 font-normal mt-1">
                                  {errors.maximumHour}
                                </div>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-[3.125rem]">
                      <Typography
                        variant="h3"
                        className="text-gray-300 font-medium"
                      >
                        Timesheet Approval
                      </Typography>
                      <div className="flex justify-start items-center gap-6 mt-6">
                        <Field
                          type="radio"
                          name="timeSheetApproval"
                          value={0}
                          render={({ field }: any) => (
                            <RadioButton
                              label="Reporting Manager"
                              {...field}
                              onChange={() =>
                                setFieldValue("timeSheetApproval", 0)
                              }
                              selectedValue={values.timeSheetApproval}
                            />
                          )}
                        />
                        <Field
                          type="radio"
                          name="timeSheetApproval"
                          value={1}
                          render={({ field }: any) => (
                            <RadioButton
                              label="Auto Approve"
                              {...field}
                              onChange={() =>
                                setFieldValue("timeSheetApproval", 1)
                              }
                              selectedValue={values.timeSheetApproval}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-[3.125rem]">
                      <Typography
                        variant="h3"
                        className="text-gray-300 font-medium"
                      >
                        Timecard Settings
                      </Typography>
                      <div className="w-[27.5rem] mt-[1.875rem]">
                        <div>
                          <div className="flex justify-start items-center gap-2 mb-2">
                            <label className="text-400 font-medium text-gray-300 block">
                              Clock out after Minutes of inactivity
                              <span className="text-primary-100 ps-0.5">*</span>
                            </label>
                            <CustomRightToolTip>
                              <div className="tooltip">
                                <div className="w-full">
                                  <Icons
                                    name="info-circle-2"
                                    variant="stroke"
                                    size={18}
                                    className="-mb-1"
                                  />
                                </div>
                                <span className="tooltiptext">
                                  Users will have to clock-in again to resume
                                  logging work hours
                                </span>
                              </div>
                            </CustomRightToolTip>
                          </div>
                          <Field
                            name="minutesOfInactivity"
                            render={({ field }: any) => (
                              <>
                                <Dropdown
                                  {...field}
                                  onChange={handleMinutesChange}
                                  placeholder="Select minutes of inactivity"
                                  options={getMinutesList.map((m: any) => {
                                    return { label: m.name, value: m.id };
                                  })}
                                  type="box"
                                  // value={{
                                  //   label: `${values.selectedMinutes} Minutes`,
                                  //   value: values.selectedMinutes,
                                  // }}
                                  value={{
                                    label: `${selectedMinutes} Minutes`,
                                    value: selectedMinutes,
                                  }}
                                  error={errors.minutesOfInactivity}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <Typography
                        variant="h3"
                        className="text-gray-300 font-medium"
                      >
                        Screenshot Setting
                      </Typography>
                      <div className="mt-[1.875rem] w-[27.5rem]">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Screenshot Interval
                          <span className="text-primary-100 ps-0.5">*</span>
                        </label>

                        <Field
                          name="screenShotModes"
                          render={({ field }: any) => (
                            <>
                              <Dropdown
                                {...field}
                                onChange={handleScreenshotChange}
                                placeholder="Select Screenshots mode"
                                options={getScreenshotList.map((m: any) => {
                                  return {
                                    label: m.name,
                                    value: m.id,
                                  };
                                })}
                                type="box"
                                // value={{
                                //   label: values.screenShotModes === 0 ? "Per Hour" : "Per Day",
                                //   value: values.screenShotModes,
                                // }}
                                value={{
                                  label:
                                    parseInt(selectedScreenshots) === 0
                                      ? "Do Not Take Screenshots"
                                      : parseInt(selectedScreenshots) === 1
                                      ? "Per Hour"
                                      : "Per Day",
                                  value: selectedScreenshots,
                                }}
                                error={errors.screenShotModes}
                              />
                            </>
                          )}
                        />
                      </div>
                      {(parseInt(selectedScreenshots) === 1 ||
                        parseInt(selectedScreenshots) === 2) && (
                        <>
                          <div className="mt-8 w-[27.5rem]">
                            <label className="text-400 font-medium text-gray-300 block mb-2">
                              Number of Screenshots per interval
                              <span className="text-primary-100 ps-0.5">*</span>
                            </label>

                            <Field
                              name="noOfScreenShots"
                              render={({ field }: any) => (
                                <>
                                  <TextInput
                                    {...field}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const value = event.target.value.replace(
                                        /\D/,
                                        ""
                                      );
                                      setFieldValue("noOfScreenShots", value);
                                    }}
                                    placeholder="Number of Screenshots per interval"
                                    type="number"
                                    value={field.value}
                                    error={errors.noOfScreenShots}
                                  />
                                  {errors.noOfScreenShots &&
                                    touched.noOfScreenShots && (
                                      <div className="text-primary-100 text-200 font-normal mt-1">
                                        {errors.noOfScreenShots}
                                      </div>
                                    )}
                                </>
                              )}
                            />
                          </div>
                          <div className="mt-8">
                            <label className="text-400 font-medium text-gray-300 block mb-2.5">
                              Capture screenshots for
                              <span className="text-primary-100 ps-0.5">*</span>
                            </label>
                            <div className="flex justify-start items-center gap-4">
                              <Field
                                type="radio"
                                name="privacy"
                                value={0}
                                render={({ field }: any) => (
                                  <RadioButton
                                    label="Selected Users"
                                    {...field}
                                    onChange={() => setFieldValue("privacy", 0)}
                                    selectedValue={values.privacy}
                                  />
                                )}
                              />
                              <Field
                                type="radio"
                                name="privacy"
                                value={1}
                                render={({ field }: any) => (
                                  <RadioButton
                                    label="All Users"
                                    {...field}
                                    onChange={() => setFieldValue("privacy", 1)}
                                    selectedValue={values.privacy}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="mt-[1.875rem] w-[27.5rem]">
                            {values.privacy === 0 && (
                              <div className="">
                                <Field
                                  name="teamMembers"
                                  render={({ field }: any) => (
                                    <>
                                      <SearchMember
                                        userList={userList}
                                        setSelectedMember={setSelectedMember}
                                        selectedMember={selectedMember}
                                        searchString={searchString}
                                        setSearchString={setSearchString}
                                        initialSelectedMembers={
                                          settingList &&
                                          settingList.privacy === 1
                                            ? []
                                            : settingList &&
                                              settingList?.teamMembers?.map(
                                                (t: any) => ({
                                                  name: t?.name,
                                                  identityId: t?.identityId,
                                                })
                                              )
                                        }
                                        fetchUserListCustomRequest={
                                          fetchUserListCustomRequest
                                        }
                                        isTaskLoading={isTaskLoading}
                                      />
                                    </>
                                  )}
                                />
                                {/* <div className="inline-block">
                              {selectedMember &&
                                selectedMember.map((t: any) => (
                                  <div key={t.id} className="inline-block">
                                    <div className="flex items-center px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
                                      <span className="me-1.5">{t?.name}</span>
                                      <button
                                        onClick={() =>
                                          removeSelectedMember(t.id)
                                        }
                                      >
                                        <Icons
                                          name="cross"
                                          variant="stroke"
                                          size={10}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                            </div> */}
                                <div className="inline-block">
                                  {selectedMember.map(
                                    (t: any, index: number) => (
                                      <div
                                        key={t.id}
                                        className={`inline-block ${
                                          index >= 4 && !showAll ? "hidden" : ""
                                        }`}
                                      >
                                        <div className="flex items-center px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
                                          <span className="me-1.5">
                                            {t?.name}
                                            {/* {userLists &&
                                              userLists.find(
                                                (user: any) =>
                                                  user.identityId ===
                                                  t?.identityId
                                              )?.name} */}
                                          </span>
                                          <a
                                            onClick={() =>
                                              removeMember(t.identityId)
                                            }
                                          >
                                            <Icons
                                              name="cross"
                                              variant="stroke"
                                              size={10}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {selectedMember.length > 4 && (
                                    <div
                                      className="inline-block text-center cursor-pointer px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5"
                                      onClick={toggleShowAllMembers}
                                    >
                                      {showAll
                                        ? "Collapse view"
                                        : `+${
                                            selectedMember.length - 4
                                          } others`}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      <div className="mt-[1.875rem] -mb-1">
                        <Field
                          type="checkbox"
                          name="removeSession"
                          // value={values.removeSession ?? false}
                          render={({ field }: any) => (
                            <CheckBoxInput
                              name="removeSession"
                              label="Delete session time when screenshot is deleted"
                              value={values?.removeSession ?? false}
                              onChange={(
                                isChecked: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "removeSession",
                                  isChecked.target.checked
                                );
                                if (!isChecked.target.checked) {
                                  setFieldValue("expiryDate", null);
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-11">
                      <Typography
                        variant="h3"
                        className="text-gray-300 font-medium"
                      >
                        Time Off Configuration
                      </Typography>
                      <div className="mt-[1.875rem] w-[27.5rem]">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Approval required from
                          <span className="text-primary-100 ps-0.5">*</span>
                        </label>
                        <Field
                          name="timeOffApproval"
                          render={({ field }: any) => (
                            <>
                              <Dropdown
                                onChange={handleTimeOffChange}
                                options={gettimeOffApprovalList.map(
                                  (m: any) => {
                                    return {
                                      label: m.name,
                                      value: m.id,
                                    };
                                  }
                                )}
                                type="box"
                                isSearchable={false}
                                value={{
                                  label:
                                    parseInt(selectedTimeOff) === 0
                                      ? "Administrator"
                                      : parseInt(selectedTimeOff) === 1
                                      ? "Reporting Manager"
                                      : "Administrator And Reporting Manager",
                                  value: selectedTimeOff,
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SaveChanges isSubmitting={isSubmitting} isValid={isValid} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TeamSettings;
